var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-layout-content',[_c('div',{staticClass:"nav"},[_c('a-breadcrumb',[_c('a-breadcrumb-item',[_vm._v("配置管理")]),_c('a-breadcrumb-item',[_vm._v("车型管理")])],1)],1),_c('div',{staticClass:"content-container"},[_c('a-row',[_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.onAdd()}}},[_vm._v("新增")])],1),_c('div',{staticClass:"main-content"},[_c('a-table',{staticClass:"commodity-table",attrs:{"data-source":_vm.tblData,"columns":_vm.tblColumns,"pagination":_vm.pagination,"loading":_vm.loading,"row-key":function (record, index) {
            return index;
          }},on:{"change":_vm.onPage},scopedSlots:_vm._u([{key:"tag",fn:function(text, record){return _c('a-space',{},[_c('div',{staticClass:"tag"},[(record.shareFlag)?_c('div',{staticClass:"tag-item"},[_vm._v("共享业务")]):_vm._e(),(record.leaseFlag)?_c('div',{staticClass:"tag-item"},[_vm._v("租赁业务")]):_vm._e(),(record.leasePurchaseFlag)?_c('div',{staticClass:"tag-item"},[_vm._v(" 分期购车 ")]):_vm._e()])])}},{key:"action",fn:function(text, record){return _c('a-space',{},[_c('a',{on:{"click":function($event){return _vm.onEdit(record)}}},[_vm._v(" 编辑 ")]),_c('a-popconfirm',{attrs:{"title":"确认是否删除?","ok-text":"确定","cancel-text":"取消"},on:{"confirm":function($event){return _vm.onDelete(record)}}},[_c('a',{staticClass:"btn-operation"},[_vm._v("删除")])])],1)}}])})],1)],1),_c('a-modal',{attrs:{"title":"新增"},on:{"ok":_vm.onAddSubmit},model:{value:(_vm.addVisible),callback:function ($$v) {_vm.addVisible=$$v},expression:"addVisible"}},[_c('a-form',{staticClass:"advanced-search-form",attrs:{"form":_vm.addForm}},[_c('a-form-item',{attrs:{"label":"车辆型号"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            "productId",
            { rules: [{ required: true, message: "请输入车辆型号" }] }
          ]),expression:"[\n            `productId`,\n            { rules: [{ required: true, message: `请输入车辆型号` }] }\n          ]"}],attrs:{"options":_vm.productList,"placeholder":"请选择车辆型号"}})],1),_c('a-form-item',{attrs:{"label":"业务类型"}},[_c('a-checkbox-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'flag',
            { rules: [{ required: true, message: '请选择业务类型' }] }
          ]),expression:"[\n            'flag',\n            { rules: [{ required: true, message: '请选择业务类型' }] }\n          ]"}]},[_c('a-checkbox',{attrs:{"value":"shareFlag"}},[_vm._v("共享业务")]),_c('a-checkbox',{attrs:{"value":"leaseFlag"}},[_vm._v("租赁业务")]),_c('a-checkbox',{attrs:{"value":"leasePurchaseFlag"}},[_vm._v("分期购车")])],1)],1)],1)],1),_c('a-modal',{attrs:{"title":"编辑"},on:{"ok":_vm.onEditSubmit},model:{value:(_vm.editVisible),callback:function ($$v) {_vm.editVisible=$$v},expression:"editVisible"}},[_c('a-form',{staticClass:"advanced-search-form",attrs:{"form":_vm.editForm}},[_c('a-form-item',{attrs:{"label":"车辆型号"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(["productId"]),expression:"[`productId`]"}],attrs:{"disabled":"","options":_vm.productList,"placeholder":"请选择车辆型号"}})],1),_c('a-form-item',{attrs:{"label":"业务类型"}},[_c('a-checkbox-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'flag',
            { rules: [{ required: true, message: '请选择业务类型' }] }
          ]),expression:"[\n            'flag',\n            { rules: [{ required: true, message: '请选择业务类型' }] }\n          ]"}]},[_c('a-checkbox',{attrs:{"value":"shareFlag"}},[_vm._v("共享业务")]),_c('a-checkbox',{attrs:{"value":"leaseFlag"}},[_vm._v("租赁业务")]),_c('a-checkbox',{attrs:{"value":"leasePurchaseFlag"}},[_vm._v("分期购车")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import { apiClient } from "@/services/HttpService";

// 三方协议列表
export function fetchTripartiteAgreementList(params) {
  return apiClient.get(
    `/tripartite/agreements?contractCreateStartTime=${params.contractCreateStartTime}&contractCreateEndTime=${params.contractCreateEndTime}&contractSignStatus=${params.contractSignStatus}&contractType=${params.contractType}&pageNum=${params.pageNum}&pageSize=${params.pageSize}&serviceCenterCityAreaCode=${params.serviceCenterCityAreaCode}&serviceCenterCountyAreaCode=${params.serviceCenterCountyAreaCode}&serviceCenterName=${params.serviceCenterName}&serviceCenterProvinceAreaCode=${params.serviceCenterProvinceAreaCode}&warehouseName=${params.warehouseName}`
  );
}

// 查询签署渠道信息
export function fetchSignCorporation(params) {
  return apiClient.get(
    `/tripartite/corporation/bind?corporationName=${params.corporationName}&distributorType=${params.distributorType}`
  );
}

// 发起协议
export function initiateAgreement(params) {
  return apiClient.post("/tripartite", params);
}

// 加盟协议历史记录
export function fetchFranchiseAgreementHistory(corporationId) {
  return apiClient.get(
    `/contracts/franchise-agreement/histories?corporationId=${corporationId}`
  );
}

// 三方协议详情
export function fetchTripartiteAgreementDetail(contractNo) {
  return apiClient.get(`/tripartite/${contractNo}`);
}

// 三方协议历史记录
export function fetchTripartiteAgreementHistory(params) {
  return apiClient.get(
    `/tripartite/sign-history?serviceCenterId=${params.serviceCenterId}&warehouseId=${params.warehouseId}`
  );
}

// 修改三方协议
export function modifyTripartiteAgreement(contractNo, params) {
  return apiClient.patch(`/tripartite/${contractNo}`, params);
}

// 上传三方协议
export function uploadTripartiteAgreement(contractNo, params) {
  return apiClient.post(`/tripartite/${contractNo}/upload`, params);
}

// 协议取消
export function cancelAgreement(corporationId) {
  return apiClient.patch(
    `/contracts/franchise-agreement/${corporationId}/cancel`
  );
}

// 重新发起协议
export function reInitiateAgreement(params) {
  return apiClient.post(`/contracts/franchise-agreement/start`, params);
}

// 取消三方协议
export function cancelTripartiteAgreement(contractNo) {
  return apiClient.patch(`/tripartite/${contractNo}/cancel`);
}

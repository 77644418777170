var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-layout-content',[_c('div',{staticClass:"nav"},[_c('a-breadcrumb',[_c('a-breadcrumb-item',[_vm._v("审核管理")]),_c('a-breadcrumb-item',[_vm._v("活动开展审核")]),_c('a-breadcrumb-item',[_vm._v("试骑活动")])],1)],1),_c('div',{staticClass:"content-container"},[_c('common-search',{attrs:{"fields":_vm.fields},on:{"search":_vm.onSearch}}),_c('div',{staticClass:"main-content"},[_c('a-table',{attrs:{"data-source":_vm.tblData,"columns":_vm.tblColumns,"pagination":_vm.pagination,"loading":_vm.loading,"row-key":function (record, index) {
            return index;
          }},on:{"change":_vm.onPage},scopedSlots:_vm._u([{key:"businessVenuName",fn:function(text, record){return [_c('div',[_c('div',[_vm._v(_vm._s(record.dealerName))]),_c('div',{staticClass:"dealer-type"},[_vm._v(" "+_vm._s(record.dealerType)+_vm._s(record.storeType ? "(" + record.storeType + ")" : "")+" ")])])]}},{key:"activityStatusValue",fn:function(text){return [_c('span',{class:{
              yellow: text === '待审核部审核' || text === '待稽查部审核'
            }},[_vm._v(" "+_vm._s(text)+" ")])]}},{key:"action",fn:function(text, record){return _c('a-space',{},[(
              (record.activityStatus === 'UN_APPROVAL_APPROVAL_DEPT' &&
                _vm.isApprovalDept) ||
                (record.activityStatus === 'UN_APPROVAL_CHECK_DEPT' &&
                  _vm.isCheckDept)
            )?_c('a',{on:{"click":function($event){return _vm.onModify(record.activityId)}}},[_vm._v(" 审核 ")]):_c('a',{on:{"click":function($event){return _vm.onDetail(record.activityId)}}},[_vm._v(" 查看 ")])])}}])})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
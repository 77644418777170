var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-layout-content',[_c('div',{staticClass:"nav"},[_c('a-breadcrumb',[_c('a-breadcrumb-item',[_vm._v("配置管理")]),_c('a-breadcrumb-item',[_vm._v("平台费")])],1)],1),_c('div',{staticClass:"content-container"},[_c('a-row',[_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.onAdd()}}},[_vm._v("新增")])],1),_c('div',{staticClass:"main-content"},[_c('a-table',{staticClass:"commodity-table",attrs:{"data-source":_vm.tblData,"columns":_vm.tblColumns,"pagination":_vm.pagination,"loading":_vm.loading,"row-key":function (record, index) {
            return index;
          }},on:{"change":_vm.onPage},scopedSlots:_vm._u([{key:"action",fn:function(text, record){return _c('a-space',{},[_c('a',{on:{"click":function($event){return _vm.onEdit(record)}}},[_vm._v(" 编辑 ")]),_c('a-popconfirm',{attrs:{"title":"确认是否删除?","ok-text":"确定","cancel-text":"取消"},on:{"confirm":function($event){return _vm.onDelete(record)}}},[_c('a',{staticClass:"btn-operation"},[_vm._v("删除")])])],1)}}])})],1)],1),_c('a-modal',{attrs:{"title":"新增","width":"700px"},on:{"ok":_vm.onAddSubmit},model:{value:(_vm.addVisible),callback:function ($$v) {_vm.addVisible=$$v},expression:"addVisible"}},[_c('a-form',{staticClass:"advanced-search-form",attrs:{"form":_vm.addForm}},[_c('a-row',[_c('a-col',{attrs:{"span":11}},[_c('a-form-item',{attrs:{"label":"合作等级"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'levelName',
                { rules: [{ required: true, message: '请输入合作等级' }] }
              ]),expression:"[\n                'levelName',\n                { rules: [{ required: true, message: '请输入合作等级' }] }\n              ]"}],attrs:{"placeholder":"请填入合作等级"}})],1)],1),_c('a-col',{attrs:{"span":2}}),_c('a-col',{attrs:{"span":11}},[_c('a-form-item',{attrs:{"label":"平台费"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'platformRatio',
                {
                  rules: [
                    { required: true, message: '请输入平台费' },
                    {
                      validator: _vm.isNumCheck,
                      message: '请输入大于等于0且不大于100的最多两位小数的值'
                    }
                  ]
                }
              ]),expression:"[\n                'platformRatio',\n                {\n                  rules: [\n                    { required: true, message: '请输入平台费' },\n                    {\n                      validator: isNumCheck,\n                      message: '请输入大于等于0且不大于100的最多两位小数的值'\n                    }\n                  ]\n                }\n              ]"}],attrs:{"placeholder":"请填入","suffix":"%"}})],1)],1)],1)],1)],1),_c('a-modal',{attrs:{"title":"编辑","width":"700px"},on:{"ok":_vm.onEditSubmit},model:{value:(_vm.editVisible),callback:function ($$v) {_vm.editVisible=$$v},expression:"editVisible"}},[_c('a-form',{staticClass:"advanced-search-form",attrs:{"form":_vm.editForm}},[_c('a-row',[_c('a-col',{attrs:{"span":11}},[_c('a-form-item',{attrs:{"label":"合作等级"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'levelName',
                { rules: [{ required: true, message: '请输入合作等级' }] }
              ]),expression:"[\n                'levelName',\n                { rules: [{ required: true, message: '请输入合作等级' }] }\n              ]"}],attrs:{"placeholder":"请填入合作等级"}})],1)],1),_c('a-col',{attrs:{"span":2}}),_c('a-col',{attrs:{"span":11}},[_c('a-form-item',{attrs:{"label":"平台费"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'platformRatio',
                {
                  rules: [
                    { required: true, message: '请输入平台费' },
                    {
                      validator: _vm.isNumCheck,
                      message: '请输入大于等于0且不大于100的最多两位小数的值'
                    }
                  ]
                }
              ]),expression:"[\n                'platformRatio',\n                {\n                  rules: [\n                    { required: true, message: '请输入平台费' },\n                    {\n                      validator: isNumCheck,\n                      message: '请输入大于等于0且不大于100的最多两位小数的值'\n                    }\n                  ]\n                }\n              ]"}],attrs:{"placeholder":"请填入","suffix":"%"}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
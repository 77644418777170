var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-layout-content',[_c('div',{staticClass:"nav"},[_c('Breadcrumb')],1),_c('div',{staticClass:"content-container"},[_c('common-search',{attrs:{"fields":_vm.fields},on:{"search":_vm.search}}),_c('a-table',{attrs:{"data-source":_vm.tblData,"columns":_vm.tblColumns,"pagination":_vm.pagination,"loading":_vm.loading,"row-key":"applicationId"},on:{"change":_vm.onPage},scopedSlots:_vm._u([{key:"auditServiceCenterName",fn:function(text){return [_vm._v(" "+_vm._s(text ? text : "-")+" ")]}},{key:"auditOperationCenterName",fn:function(text){return [_vm._v(" "+_vm._s(text ? text : "-")+" ")]}},{key:"processStatus",fn:function(text){return [_vm._v(" "+_vm._s(text === "待运营总部审核" ? "待审核" : text)+" ")]}},{key:"contractStatus",fn:function(text){return [_vm._v(" "+_vm._s(text ? text : "-")+" ")]}},{key:"applicationTime",fn:function(text){return [_vm._v(" "+_vm._s(text ? _vm.dtFormat(text) : "-")+" ")]}},{key:"action",fn:function(text, record){return _c('span',{},[_c('a-space',[(
              record.processStatus === '未通过' ||
                record.processStatus === '已取消' ||
                (record.processStatus === '已通过' &&
                  (record.contractStatus === '待签署' ||
                    record.contractStatus === '已签署')) ||
                record.processStatus === '待运营中心审核' ||
                record.processStatus === '待服务中心审核'
            )?_c('a',{on:{"click":function($event){return _vm.onDetail(record)}}},[_vm._v(" 查看详情 ")]):_vm._e(),(
              record.processStatus === '已通过' &&
                record.contractStatus === '待签署'
            )?_c('a',{on:{"click":function($event){return _vm.onCancel(record)}}},[_vm._v(" 协议取消 ")]):_vm._e(),(record.processStatus === '待运营总部审核')?_c('a',{on:{"click":function($event){return _vm.onDetail(record)}}},[_vm._v(" 准入审核 ")]):_vm._e(),(
              record.contractStatus === '已取消' ||
                record.contractStatus === '已到期' ||
                record.contractStatus === '已截止签署'
            )?_c('a',{on:{"click":function($event){return _vm.onInitiateAgreement(record)}}},[_vm._v(" 协议发起 ")]):_vm._e()])],1)}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
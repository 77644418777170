<template>
  <a-layout-content>
    <div class="nav">
      <Breadcrumb>
        <BreadcrumbItem>新增</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <section class="info-container">
      <a-form :form="addReceiveForm" class="receive-form">
        <a-form-item label="渠道终端授信" class="special__item">
          <span></span>
        </a-form-item>
        <a-row>
          <a-col :span="8">
            <a-form-item label="机构名称">
              <a-select
                @change="getCorporation"
                show-search
                :filter-option="filterInstitutionOption"
                placeholder="请选择机构"
                v-decorator="[
                  'corporationId',
                  {
                    rules: [{ required: true, message: '请选择机构' }]
                  }
                ]"
                allow-clear
              >
                <a-select-option
                  :value="item.corporationId"
                  v-for="item in corporationList"
                  :key="item.corporationId"
                >
                  {{ item.corporationName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="联系人">
              <a-input
                disabled
                placeholder=""
                v-decorator="['contactName']"
              ></a-input>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="手机号">
              <a-input
                disabled
                placeholder=""
                v-decorator="['contactMobile']"
              ></a-input>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="授信车辆数">
              <a-input
                placeholder="请输入授信车辆数"
                v-decorator="[
                  'carLimit',
                  {
                    rules: [
                      { required: true, message: '请输入授信车辆数' },
                      { validator: isTimeCheck, message: '只能为整数' }
                    ]
                  }
                ]"
              ></a-input>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <a-form-item label="分润模式">
              <a-checkbox-group
                v-decorator="[
                  'shareConfigIdList',
                  { rules: [{ required: true, message: '请选择分润模式' }] }
                ]"
              >
                <div class="box-container">
                  <a-checkbox
                    :value="item.shareConfigId"
                    v-for="item in shareConfigIdList"
                    :key="item.shareConfigId"
                  >
                    {{ item.shareTypeName }}
                  </a-checkbox>
                </div>
              </a-checkbox-group>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="微信商户号">
              <a-input
                placeholder="请输入微信商户号"
                v-decorator="[
                  'wechatMchId',
                  {
                    rules: [{ required: true, message: '请输入微信商户号' }]
                  }
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <!--        <a-row>-->
        <!--          <a-col :span="8">-->
        <!--            <a-form-item label="分润的支付宝账号">-->
        <!--              <a-input-->
        <!--                placeholder="请输入支付宝账号"-->
        <!--                v-decorator="[-->
        <!--                  'aliUser',-->
        <!--                  {-->
        <!--                    rules: [{ required: true, message: '请输入支付宝账号' }]-->
        <!--                  }-->
        <!--                ]"-->
        <!--              ></a-input>-->
        <!--            </a-form-item>-->
        <!--          </a-col>-->
        <!--        </a-row>-->
        <!--        <a-row>-->
        <!--          <a-col :span="8">-->
        <!--            <a-form-item label="已使用车辆数">-->
        <!--              <a-input-->
        <!--                placeholder="请输入已使用车辆数"-->
        <!--                v-decorator="[-->
        <!--                  'carUse',-->
        <!--                  { rules: [{ required: true, message: '请输入已使用车辆数' },{ validator: isTimeCheck, message: '只能为整数' }] }]"-->
        <!--              ></a-input>-->
        <!--            </a-form-item>-->
        <!--          </a-col>-->
        <!--        </a-row>-->
        <!--        <a-row>-->
        <!--          <a-col :span="8">-->
        <!--            <a-form-item label="剩余车辆数">-->
        <!--              <a-input-->
        <!--                placeholder="请输入剩余车辆数"-->
        <!--                v-decorator="[-->
        <!--                  'carRemaining',-->
        <!--                  { rules: [{ required: true, message: '请输入剩余车辆数' },{ validator: isTimeCheck, message: '只能为整数' }] }]"-->
        <!--              ></a-input>-->
        <!--            </a-form-item>-->
        <!--          </a-col>-->
        <!--        </a-row>-->
        <div class="footer">
          <a-button type="primary" @click="onModify">保存</a-button>
          <a-button @click="goBack">取消</a-button>
        </div>
      </a-form>
    </section>
  </a-layout-content>
</template>

<script>
import {
  corporationCreditAdd,
  corporationDetail,
  leaseList,
  organizationInfoList
} from "@/services/ValueBag";
import Breadcrumb from "@/components/Breadcrumb.vue";
import BreadcrumbItem from "@/components/BreadcrumbItem.vue";

export default {
  components: { BreadcrumbItem, Breadcrumb },
  data() {
    return {
      addReceiveForm: this.$form.createForm(this),
      corporationList: [],
      shareConfigIdList: []
    };
  },
  mounted() {
    this.getCorporationList();
    leaseList({
      pageSize: 99,
      pageNum: 1
    }).then(resp => {
      this.shareConfigIdList = resp.data.data.records;
    });
  },
  methods: {
    isTimeCheck(rule, item) {
      let isCorrect = false;
      const reg = /^[+]{0,1}(\d+)$/;
      if (!reg.test(item)) {
        isCorrect = false;
      } else {
        isCorrect = true;
      }
      return isCorrect;
    },
    getCorporationList() {
      organizationInfoList("endConsumerMarket").then(resp => {
        this.corporationList = resp.data.data;
        this.addReceiveForm.setFieldsValue({
          corporationId: "",
          contactName: "",
          contactMobile: ""
        });
      });
    },
    // 返回上一层
    goBack() {
      this.$router.go(-1);
    },
    getCorporation(e) {
      let distributorId = "";
      this.corporationList.forEach(item => {
        if (e === item.corporationId) {
          distributorId = item.distributorId;
        }
      });
      corporationDetail(distributorId).then(resp => {
        this.addReceiveForm.setFieldsValue({
          contactName: resp.data.data.contactName
        });
        this.addReceiveForm.setFieldsValue({
          contactMobile: resp.data.data.contactMobile
        });
      });
    },
    onModify() {
      this.addReceiveForm.validateFields((err, values) => {
        if (!err) {
          let params = {
            corporationId: values.corporationId,
            carLimit: values.carLimit,
            carUse: 0,
            carRemaining: values.carLimit,
            aliUser: values.aliUser,
            shareConfigIdList: values.shareConfigIdList,
            wechatMchId: values.wechatMchId
          };
          corporationCreditAdd(params).then(resp => {
            if (resp.data.code === "SUCCESS") {
              this.$message.success("创建成功");
              this.$router.go(-1);
            }
          });
        }
      });
    },
    filterInstitutionOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    }
  }
};
</script>

<style scoped>
.info-container {
  box-sizing: border-box;
  padding: 25px 30px 25px 30px;
  margin: 10px 20px 0 20px;
  background-color: white;
}

/deep/ .ant-form-item {
  display: flex;
}

/deep/ .ant-form-item .ant-form-item-control-wrapper {
  flex: 1;
  overflow: auto;
}

/deep/ .ant-form-item .ant-form-item-label {
  text-align: left;
  min-width: 120px !important;
}

.special__item /deep/ .ant-form-item-label > label {
  color: #101010;
  font-size: 14px;
  font-weight: bold;
}

.footer {
  display: flex;
  align-items: center;
}

.footer button {
  margin-right: 8px;
}

.ant-form-item label {
  margin-top: 5px;
}

.box-container {
  display: flex;
  flex-direction: column;
}

/deep/ .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}
</style>

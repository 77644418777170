<template>
  <a-layout-content>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>配置管理</a-breadcrumb-item>
        <a-breadcrumb-item>租赁分润参数配置</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="content-container">
      <a-row>
        <a-button type="primary" @click="onAdd()">新增</a-button>
      </a-row>
      <div class="main-content">
        <a-table
          class="commodity-table"
          :data-source="tblData"
          :columns="tblColumns"
          :pagination="pagination"
          :loading="loading"
          :row-key="
            (record, index) => {
              return index;
            }
          "
          @change="onPage"
        >
          <a-space slot="action" slot-scope="text, record">
            <a @click="onEdit(record)">
              编辑
            </a>
            <a-popconfirm
              title="确认是否删除?"
              ok-text="确定"
              cancel-text="取消"
              @confirm="onDelete(record)"
            >
              <a class="btn-operation">删除</a>
            </a-popconfirm>
          </a-space>
        </a-table>
      </div>
    </div>
    <a-modal v-model="addVisible" title="新增" @ok="onAddSubmit" width="1000px">
      <a-form :form="addForm" class="advanced-search-form">
        <a-row>
          <a-col :span="11">
            <a-form-item label="分润模式">
              <a-input
                v-decorator="[
                  'shareTypeName',
                  { rules: [{ required: true, message: '请输入分润模式' }] }
                ]"
                placeholder="请填入分润模式"
                maxlength="30"
              />
            </a-form-item>
          </a-col>
          <a-col :span="2"></a-col>
          <a-col :span="11">
            <a-form-item label="产品名称">
              <a-select
                v-decorator="[
                  `productId`,
                  { rules: [{ required: true, message: '请选择产品名称' }] }
                ]"
                :options="productList"
                :placeholder="`请选择车辆型号`"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="11">
            <a-form-item label="经营人保证金">
              <a-input
                v-decorator="[
                  'deposit',
                  { rules: [{ required: true, message: '请输入经营人保证金' }] }
                ]"
                placeholder="请填入经营人保证金"
                suffix="元"
              />
            </a-form-item>
          </a-col>
          <a-col :span="2"></a-col>
          <a-col :span="11">
            <a-form-item label="经营人投资占比">
              <a-input
                v-decorator="[
                  'operationInvestRatio',
                  {
                    rules: [
                      { required: true, message: '请输入经营人投资占比' },
                      {
                        validator: isNumCheck,
                        message: '请输入大于等于0且不大于100的最多两位小数的值'
                      }
                    ]
                  }
                ]"
                placeholder="请填入"
                suffix="%"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="11">
            <a-form-item label="经营人分润比例">
              <a-input
                v-decorator="[
                  'operationShareRatio',
                  {
                    rules: [
                      { required: true, message: '请输入经营人分润比例' },
                      {
                        validator: isNumCheck,
                        message: '请输入大于等于0且不大于100的最多两位小数的值'
                      }
                    ]
                  }
                ]"
                placeholder="请填入"
                suffix="%"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
    <a-modal
      v-model="editVisible"
      title="编辑"
      @ok="onEditSubmit"
      width="1000px"
    >
      <a-form :form="editForm" class="advanced-search-form">
        <a-row>
          <a-col :span="11">
            <a-form-item label="分润模式">
              <a-input
                v-decorator="[
                  'shareTypeName',
                  { rules: [{ required: true, message: '请输入分润模式' }] }
                ]"
                placeholder="请填入分润模式"
                maxlength="30"
              />
            </a-form-item>
          </a-col>
          <a-col :span="2"></a-col>
          <a-col :span="11">
            <a-form-item label="产品名称">
              <a-select
                v-decorator="[
                  `productId`,
                  { rules: [{ required: true, message: '请选择产品名称' }] }
                ]"
                :options="productList"
                :placeholder="`请选择车辆型号`"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="11">
            <a-form-item label="经营人保证金">
              <a-input
                v-decorator="[
                  'deposit',
                  { rules: [{ required: true, message: '请输入经营人保证金' }] }
                ]"
                placeholder="请填入经营人保证金"
                suffix="元"
              />
            </a-form-item>
          </a-col>
          <a-col :span="2"></a-col>
          <a-col :span="11">
            <a-form-item label="经营人投资占比">
              <a-input
                v-decorator="[
                  'operationInvestRatio',
                  {
                    rules: [
                      { required: true, message: '请输入经营人投资占比' },
                      {
                        validator: isNumCheck,
                        message: '请输入大于等于0且不大于100的最多两位小数的值'
                      }
                    ]
                  }
                ]"
                placeholder="请填入"
                suffix="%"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="11">
            <a-form-item label="经营人分润比例">
              <a-input
                v-decorator="[
                  'operationShareRatio',
                  {
                    rules: [
                      { required: true, message: '请输入经营人分润比例' },
                      {
                        validator: isNumCheck,
                        message: '请输入大于等于0且不大于100的最多两位小数的值'
                      }
                    ]
                  }
                ]"
                placeholder="请填入"
                suffix="%"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </a-layout-content>
</template>

<script>
import {
  leaseEdit,
  leaseAdd,
  leaseList,
  leaseDelete,
  productConfig
} from "@/services/ValueBag";

const tblColumns = [
  {
    title: "分润模式",
    dataIndex: "shareTypeName",
    width: "10%"
  },
  {
    title: "产品名称",
    dataIndex: "productName",
    width: "10%"
  },
  {
    title: "经营人保证金（元）",
    dataIndex: "deposit",
    width: "10%"
  },
  {
    title: "经营人投资占比（%）",
    dataIndex: "operationInvestRatio",
    width: "15%"
  },
  {
    title: "投资人投资占比（%）",
    dataIndex: "investorInvestRatio",
    width: "15%"
  },
  {
    title: "经营人分润比例（%）",
    dataIndex: "operationShareRatio",
    width: "15%"
  },
  {
    title: "投资人分润比例（%）",
    dataIndex: "investorShareRatio",
    width: "15%"
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
    width: "10%"
  }
];
export default {
  name: "profitConfiguration",
  data() {
    return {
      tblColumns,
      tblData: [],
      pagination: {
        hideOnSinglePage: true
      },
      loading: false,
      addVisible: false,
      addForm: this.$form.createForm(this),
      editVisible: false,
      editForm: this.$form.createForm(this),
      shareConfigId: "",
      productList: []
    };
  },
  mounted() {
    productConfig({
      pageNum: 1,
      pageSize: 99
    }).then(resp => {
      resp.data.data.records.forEach(item => {
        this.productList.push({
          label: item.productName,
          value: item.productId
        });
      });
    });
    this.fetchList();
  },
  methods: {
    isNumCheck(rule, item) {
      let isCorrect = false;
      if (item >= 0 && item <= 100) {
        if (
          item.indexOf(".") == -1 ||
          item.slice(item.indexOf(".")).length <= 3
        ) {
          isCorrect = true;
        } else {
          isCorrect = false;
        }
      } else {
        isCorrect = false;
      }
      return isCorrect;
    },
    //新增
    onAdd() {
      this.addVisible = true;
    },
    //分页
    onPage(pagination) {
      this.pagination.current = pagination.current;
      this.fetchList();
    },
    // 获取列表信息
    fetchList() {
      const params = {
        pageNum: this.pagination.current ? this.pagination.current : 1,
        pageSize: this.pagination.pageSize ? this.pagination.pageSize : 10
      };
      this.loading = true;
      leaseList(params)
        .then(resp => {
          this.loading = false;
          if (resp.data.code === "SUCCESS") {
            this.tblData = resp.data.data.records ? resp.data.data.records : [];
          }
        })
        .catch(error => {
          if (error) {
            this.loading = false;
          }
        });
    },
    onEdit(item) {
      this.editVisible = true;
      this.shareConfigId = item.shareConfigId;
      this.$nextTick(() => {
        this.editForm.setFieldsValue({
          shareTypeName: item.shareTypeName,
          productId: item.productId,
          deposit: item.deposit,
          operationInvestRatio: item.operationInvestRatio.toString(),
          operationShareRatio: item.operationShareRatio.toString()
        });
      });
    },
    onDelete(item) {
      leaseDelete(item.shareConfigId).then(resp => {
        if (resp.data.code == "SUCCESS") {
          this.$message.success("删除成功");
          this.fetchList();
        } else {
          this.$message.error(resp.data.errMsg);
        }
      });
    },
    onAddSubmit() {
      this.addForm.validateFields((err, values) => {
        if (!err) {
          const params = {
            deposit: values.deposit,
            shareTypeName: values.shareTypeName,
            productId: values.productId,
            operationInvestRatio: values.operationInvestRatio,
            operationShareRatio: values.operationShareRatio,
            investorInvestRatio:
              (10000 - values.operationInvestRatio * 100) / 100,
            investorShareRatio: (10000 - values.operationShareRatio * 100) / 100
          };
          leaseAdd(params).then(resp => {
            if (resp.data.code === "SUCCESS") {
              this.$message.success("操作成功");
              this.pagination.current = 1;
              this.fetchList();
              this.addVisible = false;
              this.addForm.setFieldsValue({
                deposit: "",
                shareTypeName: "",
                operationInvestRatio: "",
                operationShareRatio: "",
                investorInvestRatio: "",
                investorShareRatio: ""
              });
            }
          });
        }
      });
    },
    onEditSubmit() {
      this.editForm.validateFields((err, values) => {
        if (!err) {
          const params = {
            deposit: values.deposit,
            shareTypeName: values.shareTypeName,
            productId: values.productId,
            operationInvestRatio: values.operationInvestRatio,
            operationShareRatio: values.operationShareRatio,
            investorInvestRatio:
              (10000 - values.operationInvestRatio * 100) / 100,
            investorShareRatio:
              (10000 - values.operationShareRatio * 100) / 100,
            shareConfigId: this.shareConfigId
          };
          leaseEdit(params).then(resp => {
            if (resp.data.code === "SUCCESS") {
              this.$message.success("操作成功");
              this.fetchList();
              this.editVisible = false;
            }
          });
        }
      });
    }
  }
};
</script>

<style scoped>
.commodity-table {
  margin-top: 10px;
}

.content-container {
  min-height: 1000px;
  background-color: white;
  margin: 30px;
}

.ant-form-item {
  display: flex;
}

/deep/ .ant-form-item .ant-form-item-label {
  width: 120px;
  text-align: right;
}

/deep/ .ant-form-item-control-wrapper {
  flex: 1;
}
</style>

<template>
  <a-layout-content>
    <div class="nav">
      <Breadcrumb>
        <BreadcrumbItem>详情</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <div class="card-container">
      <a-row>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title">基本信息</div>
          </div>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              活动类型:
            </div>
            <div class="card-item-title-content">
              {{ infoDetail.activityTypeValue }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              提交人（渠道类型）:
            </div>
            <div class="card-item-title-content">
              {{ infoDetail.distributorTypeValue }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              机构名称:
            </div>
            <div class="card-item-title-content">
              {{ infoDetail.corporationName }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              提交时间:
            </div>
            <div class="card-item-title-content">
              {{
                infoDetail.lastSubmitTime
                  ? dtFormat(infoDetail.lastSubmitTime)
                  : "--"
              }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              审核状态:
            </div>
            <div
              class="card-item-title-content"
              :class="{
                yellow:
                  infoDetail.activityStatusValue &&
                  infoDetail.activityStatusValue.includes('审核')
              }"
            >
              {{
                infoDetail.activityStatusValue
                  ? infoDetail.activityStatusValue
                  : "--"
              }}
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="card-container">
      <a-row>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title">活动开展</div>
          </div>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              活动标题：
            </div>
            <div class="card-item-content">
              {{ infoDetail.activityTitle ? infoDetail.activityTitle : "--" }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              {{ infoDetail.endTime ? "活动时间" : "活动开始时间" }}：
            </div>
            <div class="card-item-content">
              {{ infoDetail.startTime ? dtFormat(infoDetail.startTime) : "--" }}
              {{ infoDetail.endTime ? "~" + dtFormat(infoDetail.endTime) : "" }}
            </div>
          </div>
        </a-col>
        <a-col :span="8" v-if="infoDetail.distributorTypeValue !== '渠道终端'">
          <div class="card-item">
            <div class="card-item-title-content">
              工作人员数：
            </div>
            <div class="card-item-content">
              {{ infoDetail.workerCnt ? infoDetail.workerCnt + "人" : "--" }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              举办地点：
            </div>
            <div class="card-item-content">
              {{
                infoDetail.hostAreaName +
                  (infoDetail.hostDetailAddress
                    ? infoDetail.hostDetailAddress
                    : "")
              }}
            </div>
          </div>
        </a-col>
        <a-col :span="8" v-if="infoDetail.distributorTypeValue !== '渠道终端'">
          <div class="card-item">
            <div class="card-item-title-content">
              参与总人数：
            </div>
            <div class="card-item-content">
              {{
                infoDetail.joiningCount !== null
                  ? infoDetail.joiningCount
                  : "--"
              }}
            </div>
          </div>
        </a-col>
        <a-col :span="16" v-if="infoDetail.distributorTypeValue !== '渠道终端'">
          <div class="card-item">
            <div class="card-item-title-content">
              骑行总里程：
            </div>
            <div class="card-item-content">
              {{
                infoDetail.joiningMileage !== null
                  ? infoDetail.joiningMileage
                  : "--"
              }}
            </div>
          </div>
        </a-col>
        <a-col :span="22" v-if="infoDetail.distributorTypeValue !== '渠道终端'">
          <div class="card-item">
            <div class="card-item-title-content">
              物料信息：
            </div>
            <div class="card-item-content" style="flex: 1">
              <a-table
                style="width:100%"
                :data-source="infoDetail.supplyVoList"
                :columns="tblColumns"
                :pagination="false"
                :loading="false"
                :row-key="
                  (record, index) => {
                    return index;
                  }
                "
              />
            </div>
          </div>
        </a-col>
        <a-col :span="22" v-if="infoDetail.distributorTypeValue !== '渠道终端'">
          <div class="card-item">
            <div class="card-item-title-content">
              现场照片：
            </div>
            <div
              v-if="
                !infoDetail.activityPictures ||
                  infoDetail.activityPictures.length === 0
              "
            >
              暂无图片
            </div>
            <div v-if="!isOpen" class="card-item-content" style="flex: 1">
              <div
                v-for="(item, index) in infoDetail.activityPictures"
                :key="index"
                style="width: 32%;margin-right: 1%"
              >
                <div v-if="index <= 2" class="content__img" v-viewer>
                  <img
                    class=""
                    :src="imageBaseUrl + item.fileUrl"
                    :data-source="imageBaseUrl + item.fileUrl"
                    alt="现场照片"
                  />
                </div>
              </div>
            </div>
            <div v-else class="card-item-content" style="flex: 1">
              <div
                v-for="(item, index) in infoDetail.activityPictures"
                :key="index"
                style="width: 32%;margin-right: 1%"
              >
                <div class="content__img" v-viewer>
                  <img
                    :src="imageBaseUrl + item.fileUrl"
                    alt="现场照片"
                    :data-source="imageBaseUrl + item.fileUrl"
                  />
                </div>
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
      <div
        v-if="
          infoDetail.activityPictures && infoDetail.activityPictures.length > 3
        "
        class="openPhoto"
        @click="isOpen = !isOpen"
      >
        <span>{{ !isOpen ? "展开" : "收起" }}</span>
        <span v-if="!isOpen">
          <a-icon type="down" />
        </span>
        <span v-else>
          <a-icon type="up" />
        </span>
      </div>
    </div>
    <div class="card-container" style="padding-bottom: 20px">
      <a-row>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title">车辆信息</div>
          </div>
        </a-col>
      </a-row>
      <a-row>
        <div
          class="goods"
          v-if="
            !isOpenRide &&
              infoDetail.goodsVoList &&
              infoDetail.goodsVoList.length > 2
          "
        >
          <div
            v-for="(item, index) in lessGoodsVoList"
            :key="index"
            class="car__detail"
          >
            <div class="car__img" v-if="item.fileForm" v-viewer>
              <img
                :data-source="imageBaseUrl + item.fileForm.fileUrl"
                :src="imageBaseUrl + item.fileForm.fileUrl"
                alt=""
              />
            </div>
            <div class="car__info">
              <div class="info__title">
                {{ item.skuName }}
              </div>
              <div>
                <div class="info__word">商品编号:{{ item.skuCode }}</div>
                <div class="info__word">序列号:{{ item.goodsSn }}</div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="goods"
          v-if="
            isOpenRide ||
              (infoDetail.goodsVoList && infoDetail.goodsVoList.length <= 2)
          "
        >
          <div
            v-for="item in infoDetail.goodsVoList"
            :key="item.goodsId"
            class="car__detail"
          >
            <div class="car__img" v-if="item.fileForm" v-viewer>
              <img
                :data-source="imageBaseUrl + item.fileForm.fileUrl"
                :src="imageBaseUrl + item.fileForm.fileUrl"
                alt=""
              />
            </div>
            <div class="car__info">
              <div class="info__title">
                {{ item.skuName }}
              </div>
              <div>
                <div class="info__word">序列号:{{ item.skuCode }}</div>
                <div class="info__word">商品编号:{{ item.goodsSn }}</div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="infoDetail.goodsVoList && infoDetail.goodsVoList.length > 2"
          class="openPhoto"
          @click="isOpenRide = !isOpenRide"
        >
          <span>{{ !isOpenRide ? "展开" : "收起" }}</span>
          <span v-if="!isOpenRide">
            <a-icon type="down" />
          </span>
          <span v-else>
            <a-icon type="up" />
          </span>
        </div>
      </a-row>
    </div>
    <div class="card-container" style="padding-bottom: 20px">
      <a-row>
        <a-col :span="24">
          <div class="card-item">
            <div class="card-item-title">流程日志</div>
            <div class="card-item-img" @click="onShowProcessImg">
              点击查看流程图
            </div>
          </div>
        </a-col>
      </a-row>
      <div v-viewer class="images">
        <img
          :data-source="require('../../assets/imgs/process/end-zl.png')"
          src="../../assets/imgs/process/end-zl.png"
          alt="logo"
        />
      </div>
      <a-modal
        style="z-index: 99"
        :visible="previewVisible"
        @cancel="previewVisible = false"
        :footer="false"
      >
        <img
          src="../../../public/lease.png"
          alt="logo"
          style="width: 100%"
          :footer="null"
        />
      </a-modal>
      <a-table
        :data-source="flowList"
        :columns="logColumns"
        :pagination="false"
        :loading="false"
        :row-key="
          (record, index) => {
            return index;
          }
        "
      />
    </div>
    <div v-if="action === '审核'" class="card-container">
      <a-row>
        <a-col :span="24">
          <div class="card-item">
            <div class="card-item-title-content">
              审核意见：
            </div>
            <div class="card-item-content" style="flex: 1">
              <a-textarea v-model="reason"></a-textarea>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
    <div style="margin-bottom: 60px"></div>
    <div class="examine-container">
      <div class="info__message">
        <a-icon type="info-circle" />
        审核不通过，审核意见必填
      </div>
      <div class="button-container">
        <a-button
          v-if="
            storeRouterName !== 'ToDoTask' && storeRouterName !== 'DoneTask'
          "
          type="white"
          class="button-item"
          :disabled="nextDisabled"
          @click="onNext"
        >
          下一个
        </a-button>
        <a-button
          v-if="action === '审核'"
          type="white"
          class="button-item"
          @click="onRefuse"
        >
          不通过
        </a-button>
        <a-button
          v-if="action === '审核'"
          type="white"
          class="button-item"
          @click="onPass"
        >
          通过
        </a-button>
      </div>
    </div>
  </a-layout-content>
</template>

<script>
import moment from "moment";
import { imageBaseUrl } from "@/services/HttpService";
import {
  fetchActivityList,
  fetchExamineRideDetail,
  fetchExamineRideLog,
  passRideActivity,
  unpassRideActivity
} from "@/services/ActivityService";
import { dtFormat, tsFormat } from "@/components/DateUtils";
import Breadcrumb from "@/components/Breadcrumb";
import BreadcrumbItem from "@/components/BreadcrumbItem";

const tblColumns = [
  {
    title: "名称",
    dataIndex: "supplyName"
  },
  {
    title: "数量",
    dataIndex: "supplyNum"
  },
  {
    title: "价值",
    dataIndex: "supplyCost"
  }
];
const logColumns = [
  {
    title: "执行人",
    dataIndex: "createName"
  },
  {
    title: "所属渠道类型",
    dataIndex: "distributorTypeValue"
  },
  {
    title: "操作时间",
    dataIndex: "createTime"
  },
  {
    title: "执行动作",
    dataIndex: "activityStatusValue"
  },
  {
    title: "审核意见",
    dataIndex: "reason"
  }
];
export default {
  components: { Breadcrumb, BreadcrumbItem },
  data() {
    return {
      tsFormat,
      dtFormat,
      previewVisible: false,
      isOpen: false,
      tblColumns,
      logColumns,
      reason: "",
      lessGoodsVoList: [],
      isOpenRide: false,
      time: moment().format("YYYY-MM-DD"),
      action: this.$route.params.action,
      queryInfo: JSON.parse(sessionStorage.getItem("rideList")),
      activityId: this.$route.params.id,
      infoDetail: {},
      bikeStations: "",
      flowList: [],
      imageBaseUrl,
      imageUrl: "",
      totalList: [],
      nextDisabled: false,
      fromRouterName: "",
      storeRouterName: ""
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.fromRouterName = from.name;
      if (vm.fromRouterName) {
        sessionStorage.setItem("fromRouterName", vm.fromRouterName);
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    sessionStorage.removeItem("fromRouterName");
    next();
  },
  mounted() {
    if (sessionStorage.getItem("fromRouterName")) {
      this.storeRouterName = sessionStorage.getItem("fromRouterName");
    }
    this.onFetchDetail();
    if (this.storeRouterName !== "ToDoTask") {
      this.onloadList();
    }
    this.loadLogDetail();
  },
  methods: {
    // 加载节点详情
    loadLogDetail() {
      fetchExamineRideLog(this.activityId).then(resp => {
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          this.flowList = data.reverse();
          this.flowList.forEach(item => {
            if (item.createTime) {
              item.createTime = dtFormat(item.createTime);
              item.result = item.result ? item.result : "--";
            }
          });
        }
      });
    },
    canAudit(value) {
      let roles = JSON.parse(localStorage.getItem("roles"));
      let isApprovalDept = false;
      let isCheckDept = false;
      roles.forEach(item => {
        if (item === "OperationHeadquartersApprovalDept") {
          isApprovalDept = true;
        }
        if (item === "OperationHeadquartersCheckDept") {
          isCheckDept = true;
        }
      });
      if (
        (value === "待审核部审核" && isApprovalDept) ||
        (value === "待稽查部审核" && isCheckDept)
      ) {
        this.action = "审核";
      } else {
        this.action = "查看";
      }
    },
    onBack() {
      this.$router.go(-1);
    },
    onFetchDetail() {
      fetchExamineRideDetail(this.activityId).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.infoDetail = resp.data.data;
          if (
            this.infoDetail.goodsVoList &&
            this.infoDetail.goodsVoList.length > 2
          ) {
            this.lessGoodsVoList.push(
              this.infoDetail.goodsVoList[0],
              this.infoDetail.goodsVoList[1]
            );
          }
          this.canAudit(this.infoDetail.activityStatusValue);
          this.bikeStations = this.infoDetail.bikeStations[0];
        }
      });
    },
    // 通过活动
    onPass() {
      passRideActivity(this.activityId, this.reason).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.$message.warning("已通过");
          this.reason = "";
          this.onFetchDetail();
          this.loadLogDetail();
          this.$store.state.hasChange = "1";
        }
      });
    },
    // 查看流程图
    onShowProcessImg() {
      const viewer = this.$el.querySelector(".images").$viewer;
      viewer.show();
    },
    onRefuse() {
      if (!this.reason) {
        this.$message.success("请输入原因");
        return;
      }
      unpassRideActivity(this.activityId, this.reason).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.$message.success("已不通过");
          this.reason = "";
          this.onFetchDetail();
          this.loadLogDetail();
          this.$store.state.hasChange = "1";
        }
      });
    },
    // 获取列表信息
    onloadList() {
      fetchActivityList(this.queryInfo).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.totalList = resp.data.data.records;
        }
      });
    },
    async onNext() {
      if (this.totalList.length > 0) {
        const dealerIndex = this.totalList.findIndex(
          item => item.activityId === this.activityId
        );
        this.loadLogDetail();
        if (dealerIndex === this.totalList.length - 1) {
          this.queryInfo.pageNum += 1;
          sessionStorage.setItem("rideList", this.queryInfo);
          await this.onloadList();
          if (this.totalList.length !== 0) {
            this.infoDetail = this.totalList[0];
            this.activityId = this.totalList[0].activityId;
            this.reason = "";
            this.onFetchDetail();
          } else {
            this.$message.error("已经是最后一条啦");
            this.nextDisabled = true;
            this.reason = "";
            this.onFetchDetail();
          }
        } else {
          this.infoDetail = this.totalList[dealerIndex + 1];
          this.activityId = this.totalList[dealerIndex + 1].activityId;
          this.reason = "";
          this.onFetchDetail();
        }
      } else {
        this.$message.error("已经是最后一条啦");
        this.nextDisabled = true;
        this.reason = "";
        this.onFetchDetail();
      }
    }
  }
};
</script>

<style scoped>
.card-container {
  background-color: white;
  margin: 10px 20px;
  padding: 25px;
  padding-bottom: 0;
}
.card-item {
  display: flex;
  margin-bottom: 20px;
}
.card-item-title {
  font-weight: 600;
  margin-right: 10px;
}
.card-item-title-content {
  margin-right: 10px;
  min-width: 130px;
}
.examine-container {
  background-color: white;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 10px;
  z-index: 1;
  display: flex;
  justify-content: space-between;
}
.button-container {
  display: flex;
}
/deep/.ant-btn {
  border-radius: 5px;
}
.button-item {
  margin-right: 15px;
}
.card-item-content img {
  width: 100%;
  object-fit: contain;
}
.goods {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.car__detail {
  width: 50%;
  display: flex;
  align-items: center;
  height: 90px;
  margin-bottom: 8px;
}
.car__img {
  width: 30%;
  height: 90px;
  overflow: hidden;
  border-radius: 8px;
}
.car__img img {
  width: 100%;
  object-fit: cover;
}
.car__info {
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 20px;
}
.info__word {
  color: rgb(113, 113, 113);
}
.info__title {
  font-size: 16px;
  color: rgb(16, 16, 16);
}
.card-item-content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  word-break: break-all;
  margin-right: 8px;
}
.content__img {
  width: 100%;
  max-height: 150px;
  overflow: hidden;
  margin-bottom: 20px;
  margin-right: 1%;
}
.openPhoto {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(58, 110, 248);
  cursor: pointer;
}
.card-item-img {
  flex: 1;
  text-align: right;
  color: rgb(58, 110, 248) !important;
  cursor: pointer;
}
.images img {
  display: none;
}
.openPhoto span {
  margin: 0 4px 20px;
}
.info__message {
  margin-left: 210px;
  line-height: 32px;
  color: rgb(160, 160, 160);
}
.yellow {
  color: rgb(238, 132, 37);
}
.green {
  color: rgb(100, 198, 114);
}
.red {
  color: rgb(252, 88, 92);
}
.gray {
  color: rgb(151, 151, 151);
}
</style>

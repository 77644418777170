<template>
  <div>
    <div id="container">
      <!--      <div class="back" @click="goBack">-->
      <!--        <a-icon type="close" class="back__icon" />-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";
import { fetchExamineRideDetail } from "@/services/ActivityService";
const geolib = require("geolib");
window._AMapSecurityConfig = {
  securityJsCode: "8bd908ead940215404427b8f61e8e493"
};
let map = null;
let circle = null;
let circles = null;
let district = null;
// let circles = null;
let rectangle = null;
let marker = null;
let polygons = [];
export default {
  name: "CheckBigMap",
  data() {
    return {
      id: this.$route.params.id,
      fence: {},
      bikeStations: ""
    };
  },
  mounted() {
    this.loadDetail();
  },
  methods: {
    goBack() {
      // this.$router.go(-1);
      window.location.replace("about:blank");
      window.close();
    },
    // 加载活动详情
    loadDetail() {
      fetchExamineRideDetail(this.id).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.info = resp.data.data;
          this.bikeStations = this.info.bikeStations[0];
          this.fence = JSON.parse(this.info.fence);
          if (this.fence) {
            map = null;
            circle = null;
            circles = null;
            district = null;
            // let circles = null;
            rectangle = null;
            marker = null;
            polygons = [];
            district = null;
            this.initMap();
          }
        } else {
          this.$message.fail(resp.data.errorMsg);
        }
      });
    },
    initMap() {
      AMapLoader.load({
        key: "8ff794d16e2643c4d0ef597224524cb3", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [
          "AMap.AutoComplete",
          "AMap.PlaceSearch",
          "AMap.Scale",
          "AMap.OverView",
          "AMap.ToolBar",
          "AMap.event",
          "AMap.MapType",
          "AMap.PolyEditor",
          "AMap.Geocoder",
          "AMap.AutoComplete",
          "AMap.GeometryUtil",
          "AMap.Rectangle",
          "AMap.CircleEditor",
          "AMap.Driving",
          "AMap.service",
          "AMap.Bounds",
          "AMap.DistrictSearch",
          "AMap.Geolocation",
          "AMap.Marker",
          "AMap.RectangleEditor",
          "AMap.LngLat",
          "AMap.Polygon",
          "AMap.Circle"
        ] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then(AMap => {
        map = new AMap.Map("container", {
          // 设置地图容器id
          enableHighAccuracy: true,
          viewMode: "2D", // 是否为3D地图模式
          zoom: 16, // 初始化地图级别
          subdomains: [],
          center: [this.bikeStations.longitude, this.bikeStations.latitude]
        });
        circle = new AMap.Circle({
          center: [this.bikeStations.longitude, this.bikeStations.latitude], // 初始化地图中心点位置
          radius: this.info.bikeStations[0].radius, // 半径
          strokeColor: "#1CA339",
          strokeOpacity: 0,
          fillOpacity: 0.4,
          strokeStyle: "solid",
          // 线样式还支持 'dashed'
          fillColor: "#1CA339",
          zIndex: 50
        });
        map.add(circle);
        marker = new AMap.Marker({
          draggable: false,
          position: new AMap.LngLat(
            this.bikeStations.longitude,
            this.bikeStations.latitude
          )
        });
        map.add(marker);
        if (this.fence) {
          if (this.fence.chooseType === "rectangle") {
            console.log(44);
            if (this.fence.RealNowCenter.length > 0) {
              this.getDistances(
                this.fence.RealNowCenter[0],
                this.fence.RealNowCenter[1]
              );
            }
          } else if (this.fence.chooseType === "round") {
            console.log(this.fence.realNowCirclesCenter);
            if (this.fence.realNowCirclesCenter.length > 0) {
              this.changeRound(
                this.fence.realNowCirclesCenter[1],
                this.fence.realNowCirclesCenter[0]
              );
            }
          } else {
            const opts = {
              subdistrict: 0, // 返回下一级行政区
              extensions: "all", // 返回行政区边界坐标组等具体信息
              level: "district" // 查询行政级别为 市
            };
            district = new window.AMap.DistrictSearch(opts);
            for (var i = 0, l = polygons.length; i < l; i++) {
              polygons[i].setMap(null);
            }
            const ChooseCode = this.fence.realChooseCode;
            district.search(ChooseCode, (status, result) => {
              if (status === "complete") {
                this.getData(result.districtList[0]);
              }
            });
          }
        }
      });
    },
    getData(data) {
      polygons = [];
      map.remove(polygons);
      const bounds = data.boundaries;
      if (bounds) {
        let i = 0,
          l = bounds.length;
        for (; i < l; i++) {
          const polygon = new window.AMap.Polygon({
            strokeWeight: 1,
            strokeColor: "#0091ea",
            fillColor: "#80d8ff",
            fillOpacity: 0.2,
            path: bounds[i]
          });
          polygons.push(polygon);
          map.add(polygons);
        }
        map.setFitView();
      }
    },
    getDistances(lat, lng) {
      const locations = {
        west: geolib.computeDestinationPoint(
          { latitude: lat, longitude: lng },
          ~~this.fence.realRectangleWidth / 2,
          270
        ),
        east: geolib.computeDestinationPoint(
          { latitude: lat, longitude: lng },
          ~~this.fence.realRectangleWidth / 2,
          90
        )
      };
      this.getRealRectangle(locations.east, locations.west);
    },
    getRealRectangle(east, west) {
      let eastNorth = geolib.computeDestinationPoint(
        { latitude: east.latitude, longitude: east.longitude },
        ~~this.fence.realRectangleHeight / 2,
        0
      );
      let westSouth = geolib.computeDestinationPoint(
        { latitude: west.latitude, longitude: west.longitude },
        ~~this.fence.realRectangleHeight / 2,
        180
      );
      eastNorth = new window.AMap.LngLat(
        eastNorth.longitude,
        eastNorth.latitude
      );
      westSouth = new window.AMap.LngLat(
        westSouth.longitude,
        westSouth.latitude
      );
      var bounds = new window.AMap.Bounds(westSouth, eastNorth);
      rectangle = new window.AMap.Rectangle({
        bounds: bounds,
        strokeColor: "#E6AEF7",
        strokeOpacity: 0.5,
        strokeDasharray: [30, 10],
        // strokeStyle还支持 solid
        strokeStyle: "solid",
        fillColor: "#4372F6",
        fillOpacity: 0.23,
        cursor: "pointer",
        zIndex: 50
      });
      map.add(rectangle);
      // 缩放地图到合适的视野级别
      map.setFitView([rectangle]);
    },
    changeRound(lat, lng) {
      console.log(lat, lng);
      let max = "";
      let min = "";
      if (~~lat > ~~lng) {
        max = lat;
        min = lng;
      } else {
        max = lng;
        min = lat;
      }
      circles = new window.AMap.Circle({
        center: [max, min], // 初始化地图中心点位置
        radius: this.fence.realNowAroundScope, // 半径
        strokeColor: "#E6AEF7",
        strokeOpacity: 0.5,
        strokeStyle: "solid",
        strokeDasharray: [30, 10],
        // 线样式还支持 'dashed'
        fillColor: "#4372F6",
        fillOpacity: 0.23,
        zIndex: 50
      });
      map.add(circles);
      map.setFitView([circles]);
    }
  }
};
</script>

<style scoped>
#container {
  width: 100%;
  height: calc(100vh - 66px);
  position: relative;
}
.back {
  position: absolute;
  top: 0;
  z-index: 20000;
  right: 0;
}
.back__icon {
  padding: 15px;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
}
</style>

<template>
  <a-layout-content>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>配置管理</a-breadcrumb-item>
        <a-breadcrumb-item>平台费</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="content-container">
      <a-row>
        <a-button type="primary" @click="onAdd()">新增</a-button>
      </a-row>
      <div class="main-content">
        <a-table
          class="commodity-table"
          :data-source="tblData"
          :columns="tblColumns"
          :pagination="pagination"
          :loading="loading"
          :row-key="
            (record, index) => {
              return index;
            }
          "
          @change="onPage"
        >
          <a-space slot="action" slot-scope="text, record">
            <a @click="onEdit(record)">
              编辑
            </a>
            <a-popconfirm
              title="确认是否删除?"
              ok-text="确定"
              cancel-text="取消"
              @confirm="onDelete(record)"
            >
              <a class="btn-operation">删除</a>
            </a-popconfirm>
          </a-space>
        </a-table>
      </div>
    </div>
    <a-modal v-model="addVisible" title="新增" @ok="onAddSubmit" width="700px">
      <a-form :form="addForm" class="advanced-search-form">
        <a-row>
          <a-col :span="11">
            <a-form-item label="合作等级">
              <a-input
                v-decorator="[
                  'levelName',
                  { rules: [{ required: true, message: '请输入合作等级' }] }
                ]"
                placeholder="请填入合作等级"
              />
            </a-form-item>
          </a-col>
          <a-col :span="2"></a-col>
          <a-col :span="11">
            <a-form-item label="平台费">
              <a-input
                v-decorator="[
                  'platformRatio',
                  {
                    rules: [
                      { required: true, message: '请输入平台费' },
                      {
                        validator: isNumCheck,
                        message: '请输入大于等于0且不大于100的最多两位小数的值'
                      }
                    ]
                  }
                ]"
                placeholder="请填入"
                suffix="%"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
    <a-modal
      v-model="editVisible"
      title="编辑"
      @ok="onEditSubmit"
      width="700px"
    >
      <a-form :form="editForm" class="advanced-search-form">
        <a-row>
          <a-col :span="11">
            <a-form-item label="合作等级">
              <a-input
                v-decorator="[
                  'levelName',
                  { rules: [{ required: true, message: '请输入合作等级' }] }
                ]"
                placeholder="请填入合作等级"
              />
            </a-form-item>
          </a-col>
          <a-col :span="2"></a-col>
          <a-col :span="11">
            <a-form-item label="平台费">
              <a-input
                v-decorator="[
                  'platformRatio',
                  {
                    rules: [
                      { required: true, message: '请输入平台费' },
                      {
                        validator: isNumCheck,
                        message: '请输入大于等于0且不大于100的最多两位小数的值'
                      }
                    ]
                  }
                ]"
                placeholder="请填入"
                suffix="%"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </a-layout-content>
</template>

<script>
import {
  platformAdd,
  platformEdit,
  platformList,
  platformDelete
} from "@/services/ValueBag";

const tblColumns = [
  {
    title: "合作等级",
    dataIndex: "levelName",
    width: "40%"
  },
  {
    title: "平台费（%）",
    dataIndex: "platformRatio",
    width: "40%"
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
    width: "20%"
  }
];
export default {
  name: "platformFee",
  data() {
    return {
      tblColumns,
      tblData: [],
      pagination: {
        hideOnSinglePage: true
      },
      loading: false,
      addVisible: false,
      addForm: this.$form.createForm(this),
      editVisible: false,
      editForm: this.$form.createForm(this),
      configId: ""
    };
  },
  mounted() {
    this.fetchList();
  },
  methods: {
    isNumCheck(rule, item) {
      let isCorrect = false;
      if (item >= 0 && item <= 100) {
        if (
          item.indexOf(".") == -1 ||
          item.slice(item.indexOf(".")).length <= 3
        ) {
          isCorrect = true;
        } else {
          isCorrect = false;
        }
      } else {
        isCorrect = false;
      }
      return isCorrect;
    },
    //新增
    onAdd() {
      this.addVisible = true;
    },
    //分页
    onPage(pagination) {
      this.pagination.current = pagination.current;
      this.fetchList();
    },
    fetchList() {
      const params = {
        pageNum: this.pagination.current ? this.pagination.current : 1,
        pageSize: this.pagination.pageSize ? this.pagination.pageSize : 10
      };
      this.loading = true;
      platformList(params)
        .then(resp => {
          this.loading = false;
          if (resp.data.code === "SUCCESS") {
            this.tblData = resp.data.data.records ? resp.data.data.records : [];
          }
        })
        .catch(error => {
          if (error) {
            this.loading = false;
          }
        });
    },
    onEdit(item) {
      this.editVisible = true;
      this.configId = item.configId;
      this.$nextTick(() => {
        this.editForm.setFieldsValue({
          levelName: item.levelName,
          platformRatio: item.platformRatio
        });
      });
    },
    onDelete(item) {
      platformDelete(item.configId).then(resp => {
        if (resp.data.code == "SUCCESS") {
          this.$message.success("删除成功");
          this.fetchList();
        } else {
          this.$message.error(resp.data.errorMsg);
        }
      });
    },
    onAddSubmit() {
      this.addForm.validateFields((err, values) => {
        if (!err) {
          const params = {
            levelName: values.levelName,
            platformRatio: values.platformRatio
          };
          platformAdd(params).then(resp => {
            if (resp.data.code === "SUCCESS") {
              this.$message.success("操作成功");
              this.pagination.current = 1;
              this.fetchList();
              this.addVisible = false;
              this.addForm.setFieldsValue({
                levelName: "",
                platformRatio: ""
              });
            }
          });
        }
      });
    },
    onEditSubmit() {
      this.editForm.validateFields((err, values) => {
        if (!err) {
          const params = {
            configId: this.configId,
            levelName: values.levelName,
            platformRatio: values.platformRatio
          };
          platformEdit(params).then(resp => {
            if (resp.data.code === "SUCCESS") {
              this.$message.success("操作成功");
              this.fetchList();
              this.editVisible = false;
            }
          });
        }
      });
    }
  }
};
</script>

<style scoped>
.commodity-table {
  margin-top: 10px;
}

.content-container {
  min-height: 1000px;
  background-color: white;
  margin: 30px;
}

.tag {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.tag-item {
  padding: 2px 5px;
  background: #fdf7f3;
  color: #e89353;
  margin-right: 5px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.ant-form-item {
  display: flex;
}

/deep/ .ant-form-item .ant-form-item-label {
  width: 120px;
  text-align: right;
}

/deep/ .ant-form-item-control-wrapper {
  flex: 1;
}
</style>

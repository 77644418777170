<template>
  <a-layout-content>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>配置管理</a-breadcrumb-item>
        <a-breadcrumb-item>车型管理</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="content-container">
      <a-row>
        <a-button type="primary" @click="onAdd()">新增</a-button>
      </a-row>
      <div class="main-content">
        <a-table
          class="commodity-table"
          :data-source="tblData"
          :columns="tblColumns"
          :pagination="pagination"
          :loading="loading"
          :row-key="
            (record, index) => {
              return index;
            }
          "
          @change="onPage"
        >
          <a-space slot="tag" slot-scope="text, record">
            <div class="tag">
              <div class="tag-item" v-if="record.shareFlag">共享业务</div>
              <div class="tag-item" v-if="record.leaseFlag">租赁业务</div>
              <div class="tag-item" v-if="record.leasePurchaseFlag">
                分期购车
              </div>
            </div>
          </a-space>
          <a-space slot="action" slot-scope="text, record">
            <a @click="onEdit(record)">
              编辑
            </a>
            <a-popconfirm
              title="确认是否删除?"
              ok-text="确定"
              cancel-text="取消"
              @confirm="onDelete(record)"
            >
              <a class="btn-operation">删除</a>
            </a-popconfirm>
          </a-space>
        </a-table>
      </div>
    </div>
    <a-modal v-model="addVisible" title="新增" @ok="onAddSubmit">
      <a-form :form="addForm" class="advanced-search-form">
        <a-form-item label="车辆型号">
          <a-select
            v-decorator="[
              `productId`,
              { rules: [{ required: true, message: `请输入车辆型号` }] }
            ]"
            :options="productList"
            placeholder="请选择车辆型号"
          />
        </a-form-item>
        <a-form-item label="业务类型">
          <a-checkbox-group
            v-decorator="[
              'flag',
              { rules: [{ required: true, message: '请选择业务类型' }] }
            ]"
          >
            <a-checkbox value="shareFlag">共享业务</a-checkbox>
            <a-checkbox value="leaseFlag">租赁业务</a-checkbox>
            <a-checkbox value="leasePurchaseFlag">分期购车</a-checkbox>
          </a-checkbox-group>
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal v-model="editVisible" title="编辑" @ok="onEditSubmit">
      <a-form :form="editForm" class="advanced-search-form">
        <a-form-item label="车辆型号">
          <a-select
            disabled
            v-decorator="[`productId`]"
            :options="productList"
            :placeholder="`请选择车辆型号`"
          />
        </a-form-item>
        <a-form-item label="业务类型">
          <a-checkbox-group
            v-decorator="[
              'flag',
              { rules: [{ required: true, message: '请选择业务类型' }] }
            ]"
          >
            <a-checkbox value="shareFlag">共享业务</a-checkbox>
            <a-checkbox value="leaseFlag">租赁业务</a-checkbox>
            <a-checkbox value="leasePurchaseFlag">分期购车</a-checkbox>
          </a-checkbox-group>
        </a-form-item>
      </a-form>
    </a-modal>
  </a-layout-content>
</template>

<script>
import {
  fetchVehicleProductsAndSkuTree,
  productConfig,
  productConfigDelete,
  productConfigSet
} from "@/services/ValueBag";

const tblColumns = [
  {
    title: "车型名称",
    dataIndex: "productName",
    width: "20%"
  },
  {
    title: "业务类型",
    key: "tag",
    scopedSlots: { customRender: "tag" },
    width: "600%"
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
    width: "20%"
  }
];
export default {
  name: "vehicleModel",
  data() {
    return {
      tblColumns,
      tblData: [],
      pagination: {
        hideOnSinglePage: true
      },
      loading: false,
      addVisible: false,
      addForm: this.$form.createForm(this),
      productList: [],
      money: 0,
      editVisible: false,
      editForm: this.$form.createForm(this)
    };
  },
  mounted() {
    this.fetchList();
    this.onLoadProductTree();
  },
  methods: {
    // 加载产品
    onLoadProductTree() {
      fetchVehicleProductsAndSkuTree().then(resp => {
        if (resp.data.code === "SUCCESS") {
          const productList = [];
          resp.data.data.forEach(item => {
            if (item.skuList && item.skuList.length > 0) {
              productList.push({
                label: item.productName,
                value: item.productId
              });
            }
          });
          this.productList = productList;
        }
      });
    },
    //新增
    onAdd() {
      this.addVisible = true;
    },
    //分页
    onPage(pagination) {
      this.pagination.current = pagination.current;
      this.fetchList();
    },
    // 获取列表信息
    fetchList() {
      const params = {
        pageNum: this.pagination.current ? this.pagination.current : 1,
        pageSize: this.pagination.pageSize ? this.pagination.pageSize : 10
      };
      this.loading = true;
      productConfig(params)
        .then(resp => {
          this.loading = false;
          if (resp.data.code === "SUCCESS") {
            this.tblData = resp.data.data.records ? resp.data.data.records : [];
          }
        })
        .catch(error => {
          if (error) {
            this.loading = false;
          }
        });
    },
    onEdit(item) {
      this.editVisible = true;
      let flag = [];
      if (item.shareFlag) {
        flag.push("shareFlag");
      }
      if (item.leaseFlag) {
        flag.push("leaseFlag");
      }
      if (item.leasePurchaseFlag) {
        flag.push("leasePurchaseFlag");
      }
      this.$nextTick(() => {
        this.editForm.setFieldsValue({
          productId: item.productId,
          flag: flag
        });
      });
    },
    onDelete(item) {
      productConfigDelete(item.productId).then(() => {
        this.$message.success("删除成功");
        this.fetchList();
      });
    },
    onAddSubmit() {
      this.addForm.validateFields((err, values) => {
        if (!err) {
          let params = {
            productId: values.productId,
            shareFlag: values.flag.indexOf("shareFlag") != -1,
            leasePurchaseFlag: values.flag.indexOf("leasePurchaseFlag") != -1,
            leaseFlag: values.flag.indexOf("leaseFlag") != -1
          };
          productConfigSet(params).then(() => {
            this.$message.success("操作成功");
            this.pagination.current = 1;
            this.fetchList();
            this.addVisible = false;
            this.addForm.setFieldsValue({
              productId: "",
              depreciationMonth: "",
              flag: []
            });
          });
        }
      });
    },
    onEditSubmit() {
      this.editForm.validateFields((err, values) => {
        if (!err) {
          let params = {
            productId: values.productId,
            shareFlag: values.flag.indexOf("shareFlag") != -1,
            leasePurchaseFlag: values.flag.indexOf("leasePurchaseFlag") != -1,
            leaseFlag: values.flag.indexOf("leaseFlag") != -1
          };
          productConfigSet(params).then(() => {
            this.$message.success("操作成功");
            this.fetchList();
            this.editVisible = false;
          });
        }
      });
    }
  }
};
</script>

<style scoped>
.commodity-table {
  margin-top: 10px;
}

.content-container {
  min-height: 1000px;
  background-color: white;
  margin: 30px;
}

.tag {
  display: flex;
  align-items: center;
}

.tag-item {
  padding: 2px 5px;
  background: #fdf7f3;
  color: #e89353;
  margin-right: 5px;
}

.ant-form-item {
  display: flex;
}

/deep/ .ant-form-item-control-wrapper {
  flex: 1;
}

.money {
  color: #999;
}
</style>

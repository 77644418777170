<template>
  <a-layout-content>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>配置管理</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="content-container">
      <common-search :fields="fields" @search="onSearch"></common-search>
      <a-button type="primary" class="add__btn" @click="onAdd">
        新增
      </a-button>
      <div class="main-content">
        <a-table
          class="commodity-table"
          :data-source="tblData"
          :columns="tblColumns"
          :pagination="pagination"
          :loading="loading"
          :row-key="
            (record, index) => {
              return index;
            }
          "
          @change="onPage"
        >
          <template slot="car" slot-scope="text, record">
            <span>{{ record.carRemaining }} / {{ record.carLimit }}</span>
          </template>
          <template slot="modifiedTime" slot-scope="text, record">
            {{ record.modifiedTime ? tsFormat(record.modifiedTime) : "-" }}
          </template>
          <a-space slot="action" slot-scope="text, record">
            <a @click="onEdit(record)">
              编辑
            </a>
          </a-space>
        </a-table>
      </div>
    </div>
  </a-layout-content>
</template>

<script>
import { corporationCredit, organizationInfoList } from "@/services/ValueBag";
import { tsFormat } from "../../components/DateUtils";

const tblColumns = [
  {
    title: "机构名称",
    dataIndex: "corporationName",
    width: "15%"
  },
  {
    title: "机构类型",
    dataIndex: "distributorTypeValue",
    width: "15%"
  },
  {
    title: "租赁用车授权（剩余/总数）",
    scopedSlots: { customRender: "car" },
    width: "20%"
  },
  {
    title: "微信商户号",
    dataIndex: "wechatMchId",
    width: "20%"
  },
  {
    title: "最后修改时间",
    scopedSlots: { customRender: "modifiedTime" },
    width: "15%"
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
    width: "15%"
  }
];
export default {
  data() {
    return {
      fields: [
        {
          label: "机构类型",
          key: "distributorTypeValue",
          component: "j-select",
          options: [
            {
              label: "渠道终端",
              value: "endConsumerMarket"
            }
          ]
        },
        {
          label: "机构名称",
          key: "corporationName",
          component: "j-select-search",
          options: [],
          getOptions: () => {
            return this.getCorporationList();
          }
        }
      ],
      form: {},
      tblData: [],
      tblColumns,
      pagination: {
        hideOnSinglePage: true
      },
      loading: false
    };
  },
  activated() {
    this.fetchList();
  },
  mounted() {
    this.fetchList();
  },
  methods: {
    tsFormat,
    // 搜索
    onSearch(form) {
      this.form = form;
      this.pagination.current = 1;
      this.fetchList();
    },
    //分页
    onPage(pagination) {
      this.pagination.current = pagination.current;
      this.fetchList();
    },
    // 获取列表信息
    fetchList() {
      let params = {
        pageNum: this.pagination.current ? this.pagination.current : 1,
        pageSize: this.pagination.pageSize ? this.pagination.pageSize : 10,
        corporationName: this.form.corporationName || "",
        distributorTypeValue: this.form.distributorTypeValue || ""
      };
      this.loading = true;
      corporationCredit(params)
        .then(resp => {
          this.loading = false;
          if (resp.data.code === "SUCCESS") {
            this.tblData = resp.data.data.records ? resp.data.data.records : [];
          }
        })
        .catch(error => {
          if (error) {
            this.loading = false;
          }
        });
    },
    //获取机构下拉列表
    async getCorporationList() {
      const corporationList = [];
      const resp = await organizationInfoList("endConsumerMarket");
      resp.data.data.forEach(item => {
        corporationList.push({
          label: item.corporationName,
          value: item.corporationName
        });
      });
      return corporationList;
    },
    //新增
    onAdd() {
      this.$router.push({
        name: "creditAdd"
      });
    },
    //编辑
    onEdit(record) {
      this.$router.push({
        name: "creditEdit",
        params: {
          item: JSON.stringify(record)
        }
      });
    }
  }
};
</script>

<style scoped>
.commodity-table {
  margin-top: 10px;
}

.content-container {
  min-height: 1000px;
  background-color: white;
  margin: 30px;
}

/deep/ .advanced-search-form .ant-form-item {
  display: flex;
}

/deep/ .ant-form-item .ant-form-item-control-wrapper {
  flex: 1;
}

.search__btn {
  padding-left: 31px;
}

.add__btn {
  margin-left: 8px;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-layout-content',[_c('div',{staticClass:"nav"},[_c('Breadcrumb',[_c('BreadcrumbItem',[_vm._v("编辑")])],1)],1),_c('section',{staticClass:"info-container"},[_c('a-form',{staticClass:"receive-form",attrs:{"form":_vm.addReceiveForm}},[_c('a-form-item',{staticClass:"special__item",attrs:{"label":"渠道终端授信"}},[_c('span')]),_c('a-row',[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"机构名称"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['corporationId']),expression:"['corporationId']"}],attrs:{"disabled":"","placeholder":"请选择机构","allow-clear":""}},_vm._l((_vm.corporationList),function(item){return _c('a-select-option',{key:item.corporationId,attrs:{"value":item.corporationId}},[_vm._v(" "+_vm._s(item.corporationName)+" ")])}),1)],1)],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"联系人"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['contactName']),expression:"['contactName']"}],attrs:{"disabled":"","placeholder":""}})],1)],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"手机号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['contactMobile']),expression:"['contactMobile']"}],attrs:{"disabled":"","placeholder":""}})],1)],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"授信车辆数"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'carLimit',
                {
                  rules: [
                    { required: true, message: '请输入授信车辆数' },
                    { validator: _vm.isTimeCheck, message: '只能为整数' }
                  ]
                }
              ]),expression:"[\n                'carLimit',\n                {\n                  rules: [\n                    { required: true, message: '请输入授信车辆数' },\n                    { validator: isTimeCheck, message: '只能为整数' }\n                  ]\n                }\n              ]"}],attrs:{"placeholder":"请输入授信车辆数"}})],1)],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"已使用车辆数"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'carUse',
                {
                  rules: [
                    { required: true, message: '请输入已使用车辆数' },
                    { validator: _vm.isTimeCheck, message: '只能为整数' }
                  ]
                }
              ]),expression:"[\n                'carUse',\n                {\n                  rules: [\n                    { required: true, message: '请输入已使用车辆数' },\n                    { validator: isTimeCheck, message: '只能为整数' }\n                  ]\n                }\n              ]"}],attrs:{"placeholder":"请输入已使用车辆数","disabled":""}})],1)],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"剩余车辆数"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'carRemaining',
                {
                  rules: [
                    { required: true, message: '请输入剩余车辆数' },
                    { validator: _vm.isTimeCheck, message: '只能为整数' }
                  ]
                }
              ]),expression:"[\n                'carRemaining',\n                {\n                  rules: [\n                    { required: true, message: '请输入剩余车辆数' },\n                    { validator: isTimeCheck, message: '只能为整数' }\n                  ]\n                }\n              ]"}],attrs:{"placeholder":"请输入剩余车辆数","disabled":""}})],1)],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"分润模式"}},[_c('a-checkbox-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'shareConfigIdList',
                { rules: [{ required: true, message: '请选择分润模式' }] }
              ]),expression:"[\n                'shareConfigIdList',\n                { rules: [{ required: true, message: '请选择分润模式' }] }\n              ]"}]},[_c('div',{staticClass:"box-container"},_vm._l((_vm.shareConfigIdList),function(item){return _c('a-checkbox',{key:item.shareConfigId,attrs:{"value":item.shareConfigId}},[_vm._v(" "+_vm._s(item.shareTypeName)+" ")])}),1)])],1)],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"微信商户号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'wechatMchId',
                {
                  rules: [{ required: true, message: '请输入微信商户号' }]
                }
              ]),expression:"[\n                'wechatMchId',\n                {\n                  rules: [{ required: true, message: '请输入微信商户号' }]\n                }\n              ]"}],attrs:{"placeholder":"请输入微信商户号","disabled":""}})],1)],1)],1),_c('div',{staticClass:"footer"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.onModify}},[_vm._v("保存")]),_c('a-button',{on:{"click":_vm.goBack}},[_vm._v("取消")])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <a-layout-content>
    <div class="nav">
      <Breadcrumb>
        <BreadcrumbItem>
          {{ infoDetail.distributorTypeName }}准入审核
        </BreadcrumbItem>
      </Breadcrumb>
    </div>
    <div
      v-if="
        infoDetail.processStatus === '待运营总部审核' ||
          infoDetail.processStatus === '未通过'
      "
      class="card-container"
    >
      <a-row>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              申请时间:
            </div>
            <div class="card-item-content">
              {{
                infoDetail.applicationTime
                  ? dtFormat(infoDetail.applicationTime)
                  : "-"
              }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              准入审核:
            </div>
            <div class="card-item-content">
              <span
                v-if="
                  infoDetail.processStatus === '待服务中心审核' ||
                    infoDetail.processStatus === '待运营中心审核' ||
                    infoDetail.processStatus === '待运营总部审核'
                "
                class="status--orange"
              >
                {{
                  infoDetail.processStatus === "待运营总部审核"
                    ? "待审核"
                    : infoDetail.processStatus
                }}
              </span>
              <span
                v-if="infoDetail.processStatus === '未通过'"
                class="status--red"
              >
                {{ infoDetail.processStatus }}
              </span>
            </div>
          </div>
        </a-col>
        <a-col
          v-if="
            infoDetail.distributorTypeName === '服务中心' ||
              infoDetail.distributorTypeName === '货栈'
          "
          :span="8"
        >
          <div class="card-item">
            <div class="card-item-title-content">
              所在运营中心:
            </div>
            <div class="card-item-content">
              {{ infoDetail.auditOperationCenterName || "-" }}
            </div>
          </div>
        </a-col>
      </a-row>
      <a-row v-if="infoDetail.distributorTypeName === '渠道终端'">
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              所在服务中心:
            </div>
            <div class="card-item-content">
              {{
                infoDetail.auditServiceCenterName
                  ? infoDetail.auditServiceCenterName
                  : "-"
              }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              所在运营中心:
            </div>
            <div class="card-item-content">
              {{
                infoDetail.auditOperationCenterName
                  ? infoDetail.auditOperationCenterName
                  : "-"
              }}
            </div>
          </div>
        </a-col>
      </a-row>
      <a-row v-if="infoDetail.processStatus === '未通过'">
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              开通业务：
            </div>
            <div class="card-item-content">
              {{
                infoDetail.flag && infoDetail.flag.length > 0
                  ? infoDetail.flag.join()
                  : "-"
              }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              合作等级：
            </div>
            <div class="card-item-content">
              {{ infoDetail.levelName ? infoDetail.levelName : "-" }}
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
    <div v-if="infoDetail.processStatus === '已通过'" class="card-container">
      <a-row>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              申请时间:
            </div>
            <div class="card-item-content">
              {{
                infoDetail.applicationTime
                  ? dtFormat(infoDetail.applicationTime)
                  : "-"
              }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              准入审核:
            </div>
            <div class="card-item-content status--green">
              {{ infoDetail.processStatus }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              审核人员:
            </div>
            <div class="card-item-content">
              {{ infoDetail.auditRealName }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              审核时间:
            </div>
            <div class="card-item-content">
              {{ tsFormat(infoDetail.auditTime) }}
            </div>
          </div>
        </a-col>
        <a-col v-if="infoDetail.distributorTypeName === '渠道终端'" :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              所在服务中心:
            </div>
            <div class="card-item-content">
              {{
                infoDetail.auditServiceCenterName
                  ? infoDetail.auditServiceCenterName
                  : "-"
              }}
            </div>
          </div>
        </a-col>
        <a-col
          v-if="
            infoDetail.distributorTypeName === '渠道终端' ||
              infoDetail.distributorTypeName === '服务中心'
          "
          :span="8"
        >
          <div class="card-item">
            <div class="card-item-title-content">
              所在运营中心:
            </div>
            <div class="card-item-content">
              {{
                infoDetail.auditOperationCenterName
                  ? infoDetail.auditOperationCenterName
                  : "-"
              }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              签署状态:
            </div>
            <div class="card-item-content">
              {{ infoDetail.contractStatus ? infoDetail.contractStatus : "-" }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              合作起止时间:
            </div>
            <div v-if="type === 'detail'" class="card-item-content">
              {{ dtFormat(infoDetail.startTime) }} -
              {{ dtFormat(infoDetail.endTime) }}
            </div>
            <a-range-picker
              v-if="type === 'modify'"
              v-model="time"
              class="picker"
              :disabled-date="disabledDate"
            />
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              加盟协议
              <span v-if="infoDetail.contractStatus === '待签署'">
                (签署中)
              </span>
              :
            </div>
            <div v-if="type === 'detail'" class="card-item-content">
              <span v-if="!infoDetail.franchiseAgreementFile">-</span>
              <a
                v-else
                :href="imageBaseUrl + infoDetail.franchiseAgreementFile.fileUrl"
                target="_blank"
                download
              >
                {{ infoDetail.franchiseAgreementFile.fileName }}
              </a>
            </div>
            <a-upload
              v-if="type === 'modify'"
              class="upload"
              name="file"
              accept=".doc,.docx"
              :multiple="false"
              :action="importUrl"
              :headers="headers"
              :file-list="defaultFileList"
              :before-upload="file => beforeUploadFile(file, 'defaultFileList')"
              @change="handleChangeFiles"
            >
              <a-button v-if="defaultFileList.length < 1">
                <a-icon type="upload" />
                上传文件
              </a-button>
            </a-upload>
          </div>
        </a-col>
        <a-col v-if="infoDetail.distributorTypeName === '货栈'" :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              资金服务年化:
            </div>
            <div class="card-item-content">{{ infoDetail.serviceCharge }}%</div>
          </div>
        </a-col>
        <a-col v-if="infoDetail.distributorTypeName === '运营中心'" :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              销售总量要求:
            </div>
            <div class="card-item-content">
              {{ infoDetail.salesVolumeTotal }}台
              <a style="margin-left: 6px" @click="onCheckMonth">查看</a>
            </div>
          </div>
        </a-col>
        <a-col v-if="infoDetail.distributorTypeName === '运营中心'" :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              销售完成率要求:
            </div>
            <div class="card-item-content">
              {{ infoDetail.salesCompletionRate }}%
            </div>
          </div>
        </a-col>
        <a-col v-if="infoDetail.distributorTypeName === '运营中心'" :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              任务完成率要求:
            </div>
            <div class="card-item-content">
              {{ infoDetail.taskCompletionRate }}%
            </div>
          </div>
        </a-col>
        <a-col
          v-if="
            infoDetail.distributorTypeName === '运营中心' ||
              infoDetail.distributorTypeName === '服务中心'
          "
          :span="8"
        >
          <div class="card-item">
            <div class="card-item-title-content">
              管辖区域:
            </div>
            <div class="card-item-content">
              <div>
                {{ transferList(infoDetail.scopeOfJurisdiction) }}
              </div>
            </div>
          </div>
        </a-col>
        <a-col v-if="infoDetail.distributorTypeName === '服务中心'" :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              绑定货栈:
            </div>
            <div>
              {{ transferList(infoDetail.bindWarehouses) }}
            </div>
          </div>
        </a-col>
        <a-col v-if="type === 'modify'" :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              加盟协议记录:
            </div>
            <div class="card-item-content">
              <a @click="onCheckSignatureComments">查看</a>
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              开通业务：
            </div>
            <div class="card-item-content">
              {{
                infoDetail.flag && infoDetail.flag.length > 0
                  ? infoDetail.flag.join()
                  : "-"
              }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              合作等级：
            </div>
            <div class="card-item-content">
              {{ infoDetail.levelName ? infoDetail.levelName : "-" }}
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="card-container">
      <a-row>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title">基本信息</div>
          </div>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              机构名称：
            </div>
            <div class="card-item-content">
              {{ infoDetail.corporationName }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              统一社会信用代码：
            </div>
            <div class="card-item-content">
              {{ infoDetail.corporationCode }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              经营地址：
            </div>
            <div class="card-item-content">
              <a-tooltip>
                <template slot="title">
                  {{ registeredAddress }}
                </template>
                {{ registeredAddress }}
              </a-tooltip>
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              联系人姓名：
            </div>
            <div class="card-item-content">
              {{ infoDetail.contactName }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              联系电话：
            </div>
            <div class="card-item-content">
              {{ infoDetail.contactMobile }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              联系地址：
            </div>
            <div class="card-item-content">
              <a-tooltip>
                <template slot="title">
                  {{ contactAddress }}
                </template>
                {{ contactAddress }}
              </a-tooltip>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="card-container">
      <a-row>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title">认证信息</div>
          </div>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              法人姓名：
            </div>
            <div class="card-item-content">
              {{ infoDetail.legalRepresentativeName }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              法人性别：
            </div>
            <div class="card-item-content">
              {{ infoDetail.legalRepresentativeGender }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              法人身份证号：
            </div>
            <div class="card-item-content">
              {{ infoDetail.legalRepresentativeIdNo }}
            </div>
          </div>
        </a-col>
      </a-row>
      <a-row v-if="infoDetail.authType === '法人'">
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              法人人脸认证：
            </div>
            <div class="card-item-content">
              {{ infoDetail.legalRepresentativeAuthFlag ? "已认证" : "未认证" }}
            </div>
          </div>
        </a-col>
      </a-row>
      <a-row v-if="infoDetail.authType === '经营人'">
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              经营人姓名：
            </div>
            <div class="card-item-content">
              {{ infoDetail.executorName }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              经营人性别：
            </div>
            <div class="card-item-content">
              {{ infoDetail.executorGender }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              经营人身份证号：
            </div>
            <div class="card-item-content">
              {{ infoDetail.executorIdNo }}
            </div>
          </div>
        </a-col>
      </a-row>
      <a-row v-if="infoDetail.authType === '经营人'">
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              经营人人脸认证：
            </div>
            <div class="card-item-content">
              {{ infoDetail.executorAuthFlag ? "已认证" : "未认证" }}
            </div>
          </div>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="16">
          <div class="card-item">
            <div class="card-item-title-content">
              营业执照：
            </div>
            <div class="card-item-content">
              <div
                v-if="infoDetail.businessLicenseUrl"
                v-viewer
                class="img__container"
              >
                <img
                  :data-source="imageBaseUrl + infoDetail.businessLicenseUrl"
                  :src="imageBaseUrl + infoDetail.businessLicenseUrl"
                  alt="营业执照"
                />
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="card-container">
      <a-row>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title">开票信息</div>
          </div>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              单位名称：
            </div>
            <div class="card-item-content">
              {{ infoDetail.corporationName }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              纳税人识别号：
            </div>
            <div class="card-item-content">
              {{ infoDetail.corporationCode }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              注册地址：
            </div>
            <div class="card-item-content">
              {{ infoDetail.invoiceAreaName }}
              {{ infoDetail.invoiceDetailAddress }}
            </div>
          </div>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              注册电话：
            </div>
            <div class="card-item-content">
              {{ infoDetail.invoiceTel }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              开户银行名称：
            </div>
            <div class="card-item-content">
              {{ infoDetail.invoiceBankName || "-" }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              开户银行账号：
            </div>
            <div class="card-item-content">
              {{ infoDetail.invoiceBankAccount || "-" }}
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="card-container">
      <a-row>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title">收款银行信息</div>
          </div>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              提现账户类型：
            </div>
            <div class="card-item-content">
              {{
                infoDetail && infoDetail.collectionCardType
                  ? infoDetail.collectionCardType === "DC"
                    ? "对私法人账户"
                    : "对公账户"
                  : "-"
              }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              收款银行账号：
            </div>
            <div class="card-item-content">
              {{ infoDetail.collectionAccountNumber || "-" }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              收款银行名称：
            </div>
            <div>
              {{ infoDetail.collectionBankName || "-" }}
            </div>
          </div>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              收款行所在省市：
            </div>
            <div class="card-item-content">
              <div
                v-if="
                  !infoDetail.collectionProvinceName &&
                    !infoDetail.collectionCityName
                "
              >
                -
              </div>
              <div v-else>
                {{ infoDetail.collectionProvinceName
                }}{{ infoDetail.collectionCityName }}
              </div>
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              收款行支行：
            </div>
            <div class="card-item-content">
              {{ infoDetail.collectionBranchName || "-" }}
            </div>
          </div>
        </a-col>
      </a-row>
      <a-row v-if="infoDetail.collectionCardType === 'DC'">
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              银行卡正面照片：
            </div>
            <div class="card-item-content">
              <div
                v-viewer
                v-if="infoDetail && infoDetail.collectionFrontFile"
                class="preview__image"
              >
                <img
                  :data-source="imageBaseUrl + infoDetail.collectionFrontFile"
                  :src="imageBaseUrl + infoDetail.collectionFrontFile"
                  alt="银行卡正面照片"
                />
              </div>
              <div v-else>暂无照片</div>
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              银行卡反面照片：
            </div>
            <div class="card-item-content">
              <div
                v-viewer
                v-if="infoDetail && infoDetail.collectionBackFile"
                class="preview__image"
              >
                <img
                  :data-source="imageBaseUrl + infoDetail.collectionBackFile"
                  :src="imageBaseUrl + infoDetail.collectionBackFile"
                  alt="银行卡反面照片"
                />
              </div>
              <div v-else>暂无照片</div>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="card-container table">
      <a-row>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title">流转信息</div>
          </div>
        </a-col>
        <a-col :span="12"></a-col>
        <a-col :span="4" class="process__img">
          <a @click="onShowProcessImg">
            点击查看流程图
          </a>
          <div v-viewer class="images">
            <img
              v-if="infoDetail.distributorTypeName === '渠道终端'"
              src="../../assets/imgs/process/endConsumerMarket.png"
              :data-source="
                require('../../assets/imgs/process/endConsumerMarket.png')
              "
              alt="流程图"
            />
            <img
              v-if="infoDetail.distributorTypeName === '货栈'"
              src="../../assets/imgs/process/warehouse.png"
              :data-source="require('../../assets/imgs/process/warehouse.png')"
              alt="流程图"
            />
            <img
              v-if="infoDetail.distributorTypeName === '服务中心'"
              src="../../assets/imgs/process/serviceCenter.png"
              :data-source="
                require('../../assets/imgs/process/serviceCenter.png')
              "
              alt="流程图"
            />
            <img
              v-if="infoDetail.distributorTypeName === '运营中心'"
              src="../../assets/imgs/process/operationCenter.png"
              :data-source="
                require('../../assets/imgs/process/operationCenter.png')
              "
              alt="流程图"
            />
          </div>
        </a-col>
      </a-row>
      <a-table
        class="table"
        :columns="tblColumns"
        :data-source="tblData"
        row-key="index"
        :pagination="false"
      >
        <template slot="createTime" slot-scope="text">
          {{ text ? dtFormat(text) : "-" }}
        </template>
        <template slot="memo" slot-scope="text">
          {{ text ? text : "-" }}
        </template>
      </a-table>
    </div>
    <div
      v-if="
        (infoDetail.distributorTypeName === '渠道终端' ||
          infoDetail.distributorTypeName === '货栈') &&
          infoDetail.processStatus === '待运营总部审核'
      "
      class="last"
    ></div>
    <div
      v-if="
        (infoDetail.distributorTypeName === '运营中心' ||
          infoDetail.distributorTypeName === '服务中心') &&
          infoDetail.processStatus === '待运营总部审核'
      "
      class="block"
    ></div>
    <div v-if="type === 'modify'" class="last"></div>
    <div
      v-if="infoDetail.processStatus === '待运营总部审核' && type !== 'modify'"
      class="examine-container"
    >
      <div class="form__container">
        <a-form class="advanced-search-form">
          <a-row>
            <a-col :span="8">
              <a-form-item label="加盟协议">
                <a-upload
                  name="file"
                  accept=".doc,.docx"
                  :multiple="false"
                  :action="importUrl"
                  :headers="headers"
                  :file-list="fileList"
                  :before-upload="file => beforeUploadFile(file, 'fileList')"
                  @change="handleChangeFile"
                >
                  <a-button v-if="fileList.length < 1">
                    <a-icon type="upload" />
                    上传文件
                  </a-button>
                </a-upload>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="合作起止时间">
                <a-range-picker v-model="time" :disabled-date="disabledDate" />
              </a-form-item>
            </a-col>
            <a-col v-if="infoDetail.distributorTypeName === '货栈'" :span="8">
              <a-form-item label="资金服务年化" class="form__item">
                <a-input-number v-model="serviceCharge" :min="0" :max="100" />
                %
              </a-form-item>
            </a-col>
            <a-col
              v-if="infoDetail.distributorTypeName === '运营中心'"
              :span="8"
            >
              <a-form-item label="销售总量要求" class="form__item">
                {{ saleNum }} 台
                <a style="margin-left: 6px" @click="onSetSale">去配置</a>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row v-if="infoDetail.distributorTypeName === '服务中心'">
            <a-col :span="8">
              <a-form-item label="绑定货栈">
                <a-select v-model="bindWarehouses" mode="multiple">
                  <a-select-option
                    v-for="item in wareHouseList"
                    :key="item.corporationId"
                  >
                    {{ item.corporationName }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="管辖区域">
                <a-tree-select
                  v-model="scopeOfJurisdiction"
                  show-search
                  style="width: 100%"
                  :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                  placeholder="请选择"
                  allow-clear
                  :tree-data="areaTreeData"
                  multiple
                  :replace-fields="areaTreeFields"
                  tree-checkable
                  :show-checked-strategy="SHOW_PARENT"
                  tree-node-filter-prop="title"
                ></a-tree-select>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row v-if="infoDetail.distributorTypeName === '运营中心'">
            <a-col :span="8">
              <a-form-item label="销售完成率要求">
                <a-input-number
                  v-model="salesCompletionRate"
                  :min="0"
                  :max="100"
                />
                %
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="管辖区域">
                <a-tree-select
                  v-model="scopeOfJurisdiction"
                  style="width: 100%"
                  :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                  placeholder="请选择"
                  allow-clear
                  :tree-data="areaTreeData"
                  multiple
                  :replace-fields="areaTreeFields"
                  tree-checkable
                  :show-checked-strategy="SHOW_PARENT"
                  tree-node-filter-prop="title"
                ></a-tree-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="任务完成率要求" class="form__item">
                <a-input-number
                  v-model="taskCompletionRate"
                  :min="0"
                  :max="100"
                />
                %
              </a-form-item>
            </a-col>
          </a-row>
          <a-row v-if="infoDetail.distributorTypeName === '渠道终端'">
            <a-col :span="8">
              <a-form-item label="开通业务">
                <a-checkbox-group v-model="flag">
                  <a-checkbox value="sharedBusinessFlag">共享</a-checkbox>
                  <a-checkbox value="leasingBusinessFlag">租赁</a-checkbox>
                  <a-checkbox value="carLeaseBusinessFlag">分期购车</a-checkbox>
                  <a-checkbox value="sellBusinessFlag">
                    批量买卖业务
                  </a-checkbox>
                </a-checkbox-group>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="合作等级">
                <a-select
                  v-model="configId"
                  :options="platList"
                  placeholder="请选择合作等级"
                  allow-clear
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="16">
              <a-form-item label="审核意见">
                <a-textarea v-model="memo" :auto-size="{ minRows: 1 }" />
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <div class="button-container">
        <div v-if="infoDetail.distributorTypeName === '渠道终端'" class="tip">
          <a-icon type="info-circle" class="tip__icon" />
          审核通过时，加盟协议、合作起止时间必填；审核不通过，审核意见必填
        </div>
        <div v-if="infoDetail.distributorTypeName === '服务中心'" class="tip">
          <a-icon type="info-circle" class="tip__icon" />
          审核通过时，加盟协议、合作起止时间、管辖区域、绑定货栈信息必填；审核不通过，审核意见必填
        </div>
        <div v-if="infoDetail.distributorTypeName === '货栈'" class="tip">
          <a-icon type="info-circle" class="tip__icon" />
          审核通过时，加盟协议、合作起止时间、资金服务年化信息必填；审核不通过，审核意见必填
        </div>
        <div v-if="infoDetail.distributorTypeName === '运营中心'" class="tip">
          <a-icon type="info-circle" class="tip__icon" />
          审核通过时，加盟协议、合作起止时间、管辖区域、销售总量要求、销售完成率要求、任务完成率要求信息必填；审核不通过，审核意见必填
        </div>
        <div class="operation">
          <a-button
            type="white"
            :loading="loading"
            class="button-item"
            @click="onReject"
          >
            不通过
          </a-button>
          <a-button
            :loading="loading"
            type="primary"
            class="button-item"
            @click="onPass"
          >
            通过
          </a-button>
        </div>
      </div>
    </div>
    <div v-if="type === 'modify'" class="examine-container">
      <div class="operation--modify">
        <a-button type="white" class="button-item" @click="onBack">
          取消
        </a-button>
        <a-button
          type="primary"
          class="button-item"
          @click="onInitiate"
          :loading="initiateLoading"
        >
          提交
        </a-button>
      </div>
    </div>

    <a-modal
      :visible="monthVisible"
      title="销售量要求"
      :footer="null"
      @cancel="monthVisible = false"
    >
      <a-table
        :columns="monthColumns"
        :data-source="monthData"
        :pagination="false"
      ></a-table>
    </a-modal>
    <a-modal
      :visible="saleModal"
      title="销售量要求"
      @ok="onStatisticsSale"
      @cancel="onCancelModal"
    >
      <a-button class="btn--add" type="primary" @click="onAdd">增加</a-button>
      <a-table
        :columns="saleColumns"
        :data-source="saleData"
        :pagination="false"
      >
        <template slot="salesMonth" slot-scope="text, record">
          <a-select
            v-model="record.salesMonth"
            style="width: 100px"
            @change="onChangeMonth"
          >
            <a-select-option
              v-for="item in monthList"
              :key="item.key"
              :disabled="item.disabled"
            >
              {{ item.value }}
            </a-select-option>
          </a-select>
        </template>
        <template slot="salesVolume" slot-scope="text, record">
          <a-input-number
            v-model="record.salesVolume"
            :min="1"
            :max="99999"
            :parser="value => (/^\d+$/.test(value) ? value : 1)"
          />
          台
        </template>
        <template slot="action" slot-scope="text, record, index">
          <a class="delete" @click="onDelete(record, index)">删除</a>
        </template>
      </a-table>
    </a-modal>

    <!--  加盟协议记录弹窗  -->
    <a-modal
      :visible="commentVisible"
      title="加盟协议记录"
      :footer="null"
      width="50%"
      @cancel="commentVisible = false"
    >
      <a-collapse default-active-key="0">
        <a-collapse-panel
          v-for="(item, index) in historyList"
          :key="index"
          :header="
            `${item.contractName}（${dtFormat(item.startTime)} - ${dtFormat(
              item.endTime
            )}）`
          "
        >
          <div slot="extra">提交：{{ tsFormat(item.createTime) }}</div>
          <div class="item__container">
            <div class="item__name">{{ item.headquarterName }}（甲方）</div>
            <div class="item__status bottom">
              <div class="status--sign">
                签署状态：{{ item.headquarterSignStatus }}
              </div>
            </div>
            <div class="item__name">{{ item.distributorName }}（乙方）</div>
            <div class="item__status">
              <div class="status--sign">
                签署状态：{{ item.distributorSignStatus }}
              </div>
            </div>
            <div v-if="item.distributorApprovalResult" class="item__comment">
              签署意见：{{ item.distributorApprovalResult }}
            </div>
          </div>
        </a-collapse-panel>
      </a-collapse>
    </a-modal>
  </a-layout-content>
</template>

<script>
import {
  corporationExamine,
  fetchCorporationApplyInfo,
  fetchOperationManageArea
} from "@/services/ExamineService";
import { baseURL, imageBaseUrl } from "@/services/HttpService";
import BreadcrumbItem from "@/components/BreadcrumbItem";
import Breadcrumb from "@/components/Breadcrumb";
import { dtFormat, tsFormat } from "@/components/DateUtils";
import { buildHeaders } from "@/components/utils";
import moment from "moment";
import {
  fetchSpecifyTypeCorporation,
  loadArea
} from "@/services/DealerService";

import { TreeSelect } from "ant-design-vue";
import {
  fetchFranchiseAgreementHistory,
  reInitiateAgreement
} from "@/services/AgreementService";
import { platformList } from "@/services/ValueBag";
import { upload } from "@/services/UploadService";

const SHOW_PARENT = TreeSelect.SHOW_PARENT;

const tblColumns = [
  {
    title: "执行人",
    dataIndex: "approvalRealName",
    width: 160
  },
  {
    title: "机构名称",
    dataIndex: "corporationName",
    width: 160
  },
  {
    title: "渠道类型",
    dataIndex: "distributorType",
    width: 160
  },
  {
    title: "操作时间",
    dataIndex: "createTime",
    scopedSlots: { customRender: "createTime" },
    width: 160
  },
  {
    title: "执行动作",
    dataIndex: "approvalResult",
    width: 160
  },
  {
    title: "审核意见",
    dataIndex: "memo",
    scopedSlots: { customRender: "memo" },
    width: 240
  }
];
const saleColumns = [
  {
    title: "月份",
    dataIndex: "salesMonth",
    scopedSlots: { customRender: "salesMonth" },
    width: "40%"
  },
  {
    title: "销售数量",
    dataIndex: "salesVolume",
    scopedSlots: { customRender: "salesVolume" },
    width: "40%"
  },
  {
    title: "操作",
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
    width: "20%"
  }
];
const monthColumns = [
  {
    title: "月份",
    dataIndex: "salesMonth",
    scopedSlots: { customRender: "salesMonth" },
    width: "50%"
  },
  {
    title: "销售数量",
    dataIndex: "salesVolume",
    scopedSlots: { customRender: "salesVolume" },
    width: "50%"
  }
];

export default {
  components: { Breadcrumb, BreadcrumbItem },
  data() {
    return {
      applicationId: this.$route.params.applicationId,
      infoDetail: {},
      contactAddress: "",
      registeredAddress: "",
      dtFormat,
      tsFormat,
      imageBaseUrl,
      tblColumns,
      tblData: [],
      importUrl: `${baseURL}/upload-word/cooperation_agreement`,
      headers: buildHeaders(),
      fileList: [],
      defaultFileList: [],
      time: [],
      memo: "",
      serviceCharge: null,
      saleNum: 0,
      salesCompletionRate: null,
      taskCompletionRate: null,
      scopeOfJurisdiction: [],
      areaTreeData: [],
      areaTreeFields: {
        children: "subAreas",
        title: "areaName",
        key: "areaCode",
        value: "areaCode"
      },
      saleModal: false,
      saleColumns,
      saleData: [],
      monthList: [
        {
          key: 1,
          value: "1月",
          disabled: false
        },
        {
          key: 2,
          value: "2月",
          disabled: false
        },
        {
          key: 3,
          value: "3月",
          disabled: false
        },
        {
          key: 4,
          value: "4月",
          disabled: false
        },
        {
          key: 5,
          value: "5月",
          disabled: false
        },
        {
          key: 6,
          value: "6月",
          disabled: false
        },
        {
          key: 7,
          value: "7月",
          disabled: false
        },
        {
          key: 8,
          value: "8月",
          disabled: false
        },
        {
          key: 9,
          value: "9月",
          disabled: false
        },
        {
          key: 10,
          value: "10月",
          disabled: false
        },
        {
          key: 11,
          value: "11月",
          disabled: false
        },
        {
          key: 12,
          value: "12月",
          disabled: false
        }
      ],
      salesVolumes: [],
      wareHouseList: [],
      bindWarehouses: [],
      SHOW_PARENT,
      monthVisible: false,
      monthColumns,
      monthData: [],

      totalList: [],
      queryInfo: JSON.parse(sessionStorage.getItem("corporationQueryList")),
      nextDisabled: false,
      type: this.$route.params.type,
      commentVisible: false,
      historyList: [],
      initiateLoading: false,
      platList: [],
      flag: [],
      configId: undefined,
      loading: false
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.loadDetail();
      platformList({ pageSize: 999, pageNum: 1 }).then(resp => {
        resp.data.data.records.forEach(item => {
          this.platList.push({
            label: item.levelName,
            value: item.configId
          });
        });
      });
    });
  },
  methods: {
    // 返回
    onBack() {
      this.$router.go(-1);
    },
    // 加载详情
    loadDetail() {
      fetchCorporationApplyInfo(this.applicationId).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.infoDetail = resp.data.data;
          let flag = [];
          if (this.infoDetail.sharedBusinessFlag) {
            flag.push("共享");
          }
          if (this.infoDetail.leasingBusinessFlag) {
            flag.push("租赁");
          }
          if (this.infoDetail.carLeaseBusinessFlag) {
            flag.push("分期购车");
          }
          if (this.infoDetail.sellBusinessFlag) {
            flag.push("批量买卖业务");
          }
          this.infoDetail.flag = flag;
          if (
            this.infoDetail.registeredProvinceName ===
            this.infoDetail.registeredCityName
          ) {
            this.registeredAddress = `${this.infoDetail.registeredCityName} ${this.infoDetail.registeredCountyName} ${this.infoDetail.registeredDetailAddress}`;
          } else {
            this.registeredAddress = `${this.infoDetail.registeredProvinceName} ${this.infoDetail.registeredCityName} ${this.infoDetail.registeredCountyName} ${this.infoDetail.registeredDetailAddress}`;
          }
          if (
            this.infoDetail.contactProvinceName ===
            this.infoDetail.contactCityName
          ) {
            this.contactAddress = `${this.infoDetail.contactCityName} ${this.infoDetail.contactCountyName} ${this.infoDetail.contactDetailAddress}`;
          } else {
            this.contactAddress = `${this.infoDetail.contactProvinceName} ${this.infoDetail.contactCityName} ${this.infoDetail.contactCountyName} ${this.infoDetail.contactDetailAddress}`;
          }
          if (
            this.infoDetail.flowRecords &&
            this.infoDetail.flowRecords.length > 0
          ) {
            this.infoDetail.flowRecords.forEach((item, index) => {
              item.index = index;
            });
          }
          this.tblData = this.infoDetail.flowRecords;
          if (this.infoDetail.distributorTypeName === "运营中心") {
            this.loadAllAreaData();
          } else if (this.infoDetail.distributorTypeName === "服务中心") {
            if (this.infoDetail.auditOperationCenterId) {
              this.loadOperationAreaData();
            } else {
              this.loadAllAreaData();
            }
            this.loadWareHouse();
          }
        }
      });
    },
    // 获取货栈
    loadWareHouse() {
      fetchSpecifyTypeCorporation("warehouse").then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.wareHouseList = resp.data.data;
        }
      });
    },
    // 查看流程图
    onShowProcessImg() {
      const viewer = this.$el.querySelector(".images").$viewer;
      viewer.show();
    },
    // 上传文件
    handleChangeFile({ fileList }) {
      this.fileList = fileList.filter(item => item.url);
    },
    // 上传文件
    handleChangeFiles({ fileList }) {
      this.defaultFileList = fileList.filter(item => item.url);
    },
    beforeUploadFile(file, fileListName) {
      const size = file.size;
      const fileName = JSON.stringify(file.name);
      if (fileName.length > 100) {
        this.$message.error("文件名称的长度需在100个字以内");
      } else {
        if (size > 20 * 1024 * 1024) {
          this.$message.error("文件大小超过20MB");
        } else {
          this.uploadFile("leasing_frequently_questions", file, fileListName);
        }
      }
      return false;
    },
    async uploadFile(type, file, fileListName) {
      const { data = {} } = await upload(type, file, "pdf");
      if (data.code === "FAILED") {
        this.$message.error(data.errorMsg);
      } else {
        let list = [];
        list.push({
          uid: list.length,
          name: file.name,
          fileName: file.name,
          url: imageBaseUrl + data.data,
          fileUrl: data.data,
          response: data
        });
        this[fileListName] = [...list];
      }
    },
    // 切换月份
    onChangeMonth() {
      let list = [];
      this.saleData.forEach(item => {
        if (item.salesMonth) {
          list.push(item.salesMonth);
        }
      });
      this.monthList.forEach(item => {
        item.disabled = false;
      });
      if (list.length > 0) {
        list.forEach(ele => {
          const index = this.monthList.findIndex(e => e.key === ele);
          this.monthList[index].disabled = true;
        });
      }
    },
    // 通过
    onPass() {
      if (this.flag.length > 0 && !this.configId) {
        this.$message.info("请选择合作等级");
        return;
      }
      if (this.fileList.length === 0) {
        this.$message.info("请上传加盟协议");
        return;
      }
      if (this.time.length === 0) {
        this.$message.info("请选择合作起止时间");
        return;
      }
      const cooperationAgreement = {
        fileUrl: this.fileList[0].fileUrl,
        fileName: this.fileList[0].fileName
      };
      const params = {
        adoptFlag: true,
        applicationId: this.applicationId,
        startTime: moment(this.time[0]).add(8, "h"),
        endTime: moment(this.time[1]).add(8, "h"),
        memo: this.memo,
        cooperationAgreement: cooperationAgreement,
        configId: this.configId,
        sharedBusinessFlag: this.flag.indexOf("sharedBusinessFlag") != -1,
        leasingBusinessFlag: this.flag.indexOf("leasingBusinessFlag") != -1,
        carLeaseBusinessFlag: this.flag.indexOf("carLeaseBusinessFlag") != -1,
        sellBusinessFlag: this.flag.indexOf("sellBusinessFlag") != -1
      };
      if (this.infoDetail.distributorTypeName === "货栈") {
        if (!this.serviceCharge) {
          this.$message.info("请输入资金服务年化");
          return;
        }
        params.serviceCharge = this.serviceCharge;
      } else if (this.infoDetail.distributorTypeName === "运营中心") {
        if (this.saleNum === 0) {
          this.$message.info("请配置销售总量");
          return;
        }
        if (!this.salesCompletionRate) {
          this.$message.info("请输入销售完成率");
          return;
        }
        if (this.scopeOfJurisdiction.length === 0) {
          this.$message.info("请选择管辖区域");
          return;
        }
        if (!this.taskCompletionRate) {
          this.$message.info("请输入任务完成率");
          return;
        }
        params.salesVolumes = this.salesVolumes;
        params.salesCompletionRate = this.salesCompletionRate;
        params.scopeOfJurisdiction = this.scopeOfJurisdiction;
        params.taskCompletionRate = this.taskCompletionRate;
      } else if (this.infoDetail.distributorTypeName === "服务中心") {
        if (this.scopeOfJurisdiction.length === 0) {
          this.$message.info("请选择管辖区域");
          return;
        }
        params.scopeOfJurisdiction = this.scopeOfJurisdiction;
        params.bindWarehouses = this.bindWarehouses;
      }
      this.loading = true;
      corporationExamine(params).then(resp => {
        this.loading = false;
        if (resp.data.code === "SUCCESS") {
          this.$message.success("提交成功");
          this.loadDetail();
        }
      });
    },
    // 加载所有管辖区域
    loadAllAreaData() {
      loadArea().then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.areaTreeData = resp.data.data;
        }
      });
    },
    // 加载运行中心管辖区域
    loadOperationAreaData() {
      fetchOperationManageArea(this.infoDetail.auditOperationCenterId).then(
        resp => {
          if (resp.data.code === "SUCCESS") {
            this.areaTreeData = resp.data.data;
          }
        }
      );
    },
    // 不通过
    onReject() {
      if (!this.memo) {
        this.$message.info("请输入审核意见");
        return;
      }
      const params = {
        adoptFlag: false,
        applicationId: this.applicationId,
        memo: this.memo,
        configId: this.configId,
        sharedBusinessFlag: this.flag.indexOf("sharedBusinessFlag") != -1,
        leasingBusinessFlag: this.flag.indexOf("leasingBusinessFlag") != -1,
        carLeaseBusinessFlag: this.flag.indexOf("carLeaseBusinessFlag") != -1
      };
      this.loading = true;
      corporationExamine(params).then(resp => {
        this.loading = false;
        if (resp.data.code === "SUCCESS") {
          this.$message.success("提交成功");
          this.loadDetail();
        }
      });
    },
    // 设置销售要求
    onSetSale() {
      let list = [];
      this.saleData.forEach(item => {
        if (item.salesMonth) {
          list.push(item.salesMonth);
        }
      });
      this.monthList.forEach(item => {
        item.disabled = false;
      });
      if (list.length > 0) {
        list.forEach(ele => {
          const index = this.monthList.findIndex(e => e.key === ele);
          this.monthList[index].disabled = true;
        });
      }
      this.saleModal = true;
    },
    // 统计销售
    onStatisticsSale() {
      if (this.saleData.length > 0) {
        let isComplete = true;
        for (let i = 0; i < this.saleData.length; i = i + 1) {
          if (!this.saleData[i].salesMonth || !this.saleData[i].salesVolume) {
            this.$message.info("请填写完整");
            isComplete = false;
            break;
          }
        }
        if (!isComplete) {
          return;
        }
        this.saleNum = 0;
        this.salesVolumes = [];
        this.saleData.forEach(item => {
          this.saleNum = this.saleNum + item.salesVolume;
          const params = {
            salesMonth: item.salesMonth,
            salesVolume: item.salesVolume
          };
          this.salesVolumes.push(params);
        });
      }
      this.saleModal = false;
    },
    // 关闭modal
    onCancelModal() {
      this.saleModal = false;
      if (this.salesVolumes.length === 0) {
        this.saleData = [];
      }
    },
    // 时间禁用
    disabledDate(current) {
      return current < moment();
    },
    // 新增
    onAdd() {
      const params = {
        salesMonth: null,
        salesVolume: null
      };
      this.saleData.push(params);
    },
    // 删除
    onDelete(record, index) {
      if (record.salesMonth) {
        const ind = this.monthList.findIndex(
          item => item.key === record.salesMonth
        );
        this.monthList[ind].disabled = false;
      }
      this.saleData.splice(index, 1);
    },
    // 管辖区域转化
    transferList(list) {
      let str = "";
      if (list && list.length > 0) {
        list.forEach(item => {
          if (str) {
            str = `${str}、${item}`;
          } else {
            str = item;
          }
        });
      } else {
        str = "-";
      }
      return str;
    },
    // 查看月份
    onCheckMonth() {
      this.monthVisible = true;
      this.monthData = this.infoDetail.salesVolumes;
    },
    // 发起协议
    onInitiate() {
      if (this.defaultFileList.length === 0) {
        this.$message.info("请上传加盟协议");
        return;
      }
      if (this.time.length === 0) {
        this.$message.info("请选择合作起止时间");
        return;
      }
      const file = {
        fileUrl: this.defaultFileList[0].fileUrl
          ? this.defaultFileList[0].fileUrl
          : this.defaultFileList[0].response.data,
        fileName: this.defaultFileList[0].fileName
          ? this.defaultFileList[0].fileName
          : this.defaultFileList[0].name
      };
      const params = {
        file: file,
        corporationId: this.applicationId,
        startTime: moment(this.time[0]).add(8, "h"),
        endTime: moment(this.time[1]).add(8, "h")
      };
      this.initiateLoading = true;
      reInitiateAgreement(params).then(resp => {
        this.initiateLoading = false;
        if (resp.data.code === "SUCCESS") {
          this.$message.success("提交成功");
          this.$router.push({ name: "ExamineList" });
        }
      });
    },
    // 查看加盟协议记录
    onCheckSignatureComments() {
      this.loadFranchiseAgreementHistory();
      this.commentVisible = true;
    },
    // 加载加盟协议历史
    loadFranchiseAgreementHistory() {
      fetchFranchiseAgreementHistory(this.applicationId).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.historyList = resp.data.data;
        }
      });
    }
  }
};
</script>

<style scoped>
.card-container {
  background-color: white;
  margin: 10px 20px;
  padding: 25px 25px 0 25px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.19);
}

.card-item {
  display: flex;
  margin-bottom: 20px;
  margin-right: 20px;
}

.card-item-title {
  font-weight: 600;
  margin-right: 10px;
}

.card-item-title-content {
  margin-right: 10px;
  min-width: 126px;
  color: rgba(102, 102, 102, 0.8);
}

.examine-container {
  background-color: white;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 10px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.19);
}

.button-container {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding-left: 220px;
}

/deep/ .ant-btn {
  border-radius: 5px;
}

.button-item {
  margin-right: 20px;
}

.card-item-content img {
  width: 100%;
  object-fit: contain;
}

.card-item-content {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.status--orange {
  color: #f08509;
}

.status--green {
  color: #60c76f;
}

.status--red {
  color: #ff5757;
}

.img__container {
  width: 360px;
}

.img__container img {
  width: 360px;
  object-fit: contain;
}

.last {
  margin-bottom: 150px;
}

.block {
  margin-bottom: 220px;
}

.process__img {
  display: flex;
  justify-content: flex-end;
}

.images img {
  display: none;
}

/deep/ .advanced-search-form .ant-form-item {
  display: flex;
}

/deep/ .ant-form-item .ant-form-item-control-wrapper {
  flex: 1;
}

/deep/ .ant-form-item .ant-form-item-label {
  text-align: right;
  min-width: 112px;
}

/deep/ .ant-form-item {
  margin-bottom: 5px;
  margin-right: 20px;
}

.form__container {
  box-sizing: border-box;
  padding-left: 220px;
}

.tip {
  display: flex;
  color: #999999;
  font-size: 12px;
  align-items: center;
}

.tip__icon {
  margin-right: 6px;
}

.btn--add {
  margin-bottom: 10px;
}

/deep/ .form__item .ant-form-item-label {
  text-align: right;
  min-width: 240px !important;
}

.table {
  margin-bottom: 200px;
}

/deep/ .ant-col-8 {
  min-height: 52px;
}

.operation--modify {
  display: flex;
  justify-content: flex-end;
}

.picker {
  margin-right: 20px;
}

.upload /deep/ .ant-upload-list-item {
  margin-top: 0;
}

/deep/ .ant-upload-list-item-name {
  width: 200px;
}

.item__container {
  box-sizing: border-box;
  padding: 12px 16px;
}

.item__status {
  display: flex;
}

.bottom {
  margin-bottom: 20px;
}

.item__name {
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
}

.item__comment {
  margin-top: 10px;
}

/deep/ .ant-calendar-picker-input.ant-input {
  padding: 4px !important;
}

/deep/ .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
  margin-right: 8px;
}

.preview__image {
  width: 120px;
}

.preview__image img {
  width: 120px;
  height: 120px;
  object-fit: cover;
}
</style>

import { apiClient } from "./HttpService";

// 取审核列表
export function fetchExamineList(params) {
  return apiClient.get(
    `/dealer-applications/to-examine?dealerName=${params.dealerName}&dealerType=${params.dealerType}&legalPerName=${params.legalPerName}&orgCode=${params.orgCode}&pageNum=${params.pageNum}&pageSize=${params.pageSize}&personName=${params.personName}&personTel=${params.personTel}&toAudit=${params.toAudit}`
  );
}

// 取审核列表详情
export function fetchExamineDetail(dealerId) {
  return apiClient.get(`/dealer-applications/${dealerId}`);
}

// 审核
export function judgeExamine(dealerId, result) {
  return apiClient.patch(`/dealer-applications/${dealerId}?result=${result}`);
}

// 渠道商准入申请列表
export function corporationApplyList(params) {
  return apiClient.get(
    `/admission-applications/approves?areaCode=${params.areaCode}&applicationStartTime=${params.applicationStartTime}&applicationEndTime=${params.applicationEndTime}&auditOperationCenterId=${params.auditOperationCenterId}&auditServiceCenterId=${params.auditServiceCenterId}&corporationName=${params.corporationName}&distributorType=${params.distributorType}&processStatus=${params.processStatus}&contractStatus=${params.contractStatus}&pageNum=${params.pageNum}&pageSize=${params.pageSize}`
  );
}

// 审核详情
export function fetchCorporationApplyInfo(applicationId) {
  return apiClient.get(`/admission-applications/${applicationId}`);
}

// 准入审核
export function corporationExamine(params) {
  return apiClient.put("/admission-applications/approving", params);
}

// 取运营中心管辖的行政区
export function fetchOperationManageArea(operatingCenterId) {
  return apiClient.get(
    `/corporation/jurisdiction?operatingCenterId=${operatingCenterId}`
  );
}

<template>
  <a-layout-content>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>配置管理</a-breadcrumb-item>
        <a-breadcrumb-item>租车订单分期配置</a-breadcrumb-item>
        <a-breadcrumb-item>{{ installmentConfigId ? "编辑" : "新增" }}</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <section class="info-container">
      <a-form :form="addReceiveForm" class="receive-form">
        <a-row>
          <a-col :span="8">
            <a-form-item label="标题">
              <a-input
                placeholder="请输入标题"
                v-decorator="[
                  'title',
                  { rules: [{ required: true, message: '请输入标题' }] }
                ]"
              ></a-input>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="支持车型">
              <a-tree-select
                v-decorator="[
                  'insertSkuIds',
                  {
                    rules: [{ required: true, message: '请选择支持车型' }]
                  }
                ]"
                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                :tree-data="productList"
                multiple
                :replace-fields="productTreeFields"
                tree-node-filter-prop="title"
                :allow-clear="true"
                tree-checkable
                :show-checked-strategy="SHOW_PARENT"
              ></a-tree-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="生效日期">
              <a-range-picker
                valueFormat="YYYY-MM-DD"
                v-decorator="[
                  'time',
                  {
                    rules: [{ required: true, message: '请选择生效日期' }]
                  }
                ]"
                allow-clear
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="每单限制">
              <a-select v-decorator="['restrictCreditType',{ rules: [{ required: true, message: '请选择限制' }] }]"
                        allow-clear>
                <a-select-option value="RestrictFixed">
                  不大于指定金额
                </a-select-option>
                <a-select-option value="RestrictRatio">
                  不大于指定比例
                </a-select-option>
                <a-select-option value="RestrictFixedRatio">
                  按比例不大于指定金额
                </a-select-option>
                <a-select-option value="RestrictNo">
                  不限制
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="1"
                 v-show="addReceiveForm.getFieldsValue().restrictCreditType!='RestrictNo'&&addReceiveForm.getFieldsValue().restrictCreditType">
            <span class="_zw"></span>
          </a-col>
          <a-col :span="4"
                 v-if="addReceiveForm.getFieldsValue().restrictCreditType=='RestrictRatio'||addReceiveForm.getFieldsValue().restrictCreditType=='RestrictFixedRatio'">
            <a-form-item label="">
              <a-input
                placeholder="请输入比例"
                suffix="%"
                v-decorator="[
                  'restrictCreditRatio',
                  { rules: [{ required: true, message: '请输入比例' }] }
                ]"
              ></a-input>
            </a-form-item>
          </a-col>
          <a-col :span="1"
                 v-show="addReceiveForm.getFieldsValue().restrictCreditType=='RestrictFixedRatio'">
            <span class="_zw"></span>
          </a-col>
          <a-col :span="4"
                 v-if="addReceiveForm.getFieldsValue().restrictCreditType=='RestrictFixed'||addReceiveForm.getFieldsValue().restrictCreditType=='RestrictFixedRatio'">
            <a-form-item label="">
              <a-input
                placeholder="请输入金额"
                suffix="元"
                v-decorator="[
                  'restrictCreditMaxValue',
                  { rules: [{ required: true, message: '请输入金额' }] }
                ]"
              ></a-input>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="管理费">
              <a-select v-decorator="['managementFeeType',{ rules: [{ required: true, message: '请选择管理费' }] }]"
                        allow-clear>
                <a-select-option value="ManagementFeeTypeFixed">
                  固定金额
                </a-select-option>
                <a-select-option value="ManagementFeeTypeRatio">
                  固定比例
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="1"
                 v-if="addReceiveForm.getFieldsValue().managementFeeType">
            <span class="_zw"></span>
          </a-col>
          <a-col :span="4" v-if="addReceiveForm.getFieldsValue().managementFeeType=='ManagementFeeTypeFixed'">
            <a-form-item label="">
              <a-input
                placeholder="请输入金额"
                suffix="元"
                v-decorator="[
                          'managementFeeValue',
                          { rules: [{ required: true, message: '请输入金额' }] }
                        ]"
              ></a-input>
            </a-form-item>
          </a-col>
          <a-col :span="4"
                 v-if="addReceiveForm.getFieldsValue().managementFeeType=='ManagementFeeTypeRatio'">
            <a-form-item label="">
              <a-input
                placeholder="请输入比例"
                suffix="%"
                v-decorator="[
                          'managementFeeRatio',
                          { rules: [{ required: true, message: '请输入比例' }] }
                        ]"
              ></a-input>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="设置期数">
              <a-checkbox-group
                v-decorator="[
                  'repaymentPeriods',
                  { rules: [{ required: true, message: '请设置期数' }] }
                ]"
              >
                <a-checkbox value="3">3期</a-checkbox>
                <a-checkbox value="6">6期</a-checkbox>
                <a-checkbox value="9">9期</a-checkbox>
                <a-checkbox value="12">12期</a-checkbox>
              </a-checkbox-group>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="还款方式">
              <a-select v-decorator="['repaymentType',{ rules: [{ required: true, message: '请选择还款方式' }] }]" allow-clear>
                <a-select-option value="RepaymentEPI">
                  等额本息
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="年化利率">
              <a-input
                placeholder="请输入年化利率"
                suffix="%"
                v-decorator="[
                  'apr',
                  { rules: [{ required: true, message: '请输入年化利率' }] }
                ]"
              ></a-input>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="逾期每天增加">
              <a-select v-decorator="['overdueAddType',{ rules: [{ required: true, message: '请选择逾期每天增加' }] }]"
                        allow-clear>
                <a-select-option value="OverdueAddFixed">
                  固定金额
                </a-select-option>
                <a-select-option value="OverdueAddRatio">
                  本期应还比例
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="1" v-show="addReceiveForm.getFieldsValue().overdueAddType">
            <span class="_zw"></span>
          </a-col>
          <a-col :span="4" v-if="addReceiveForm.getFieldsValue().overdueAddType=='OverdueAddFixed'">
            <a-form-item label="">
              <a-input
                placeholder="请输入金额"
                suffix="元"
                v-decorator="[
                  'overdueAddValue',
                  { rules: [{ required: true, message: '请输入金额' }] }
                ]"
              ></a-input>
            </a-form-item>
          </a-col>
          <a-col :span="4" v-if="addReceiveForm.getFieldsValue().overdueAddType=='OverdueAddRatio'">
            <a-form-item label="">
              <a-input
                placeholder="请输入比例"
                suffix="%"
                v-decorator="[
                  'overdueAddRatio',
                  { rules: [{ required: true, message: '请输入比例' }] }
                ]"
              ></a-input>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="提前结清违约金条件">
              <a-select v-decorator="['prepaymentPenaltyType',{ rules: [{ required: true, message: '请选择提前结清违约金条件' }] }]"
                        allow-clear>
                <a-select-option value="PrepaymentPenaltyNo">
                  不收取
                </a-select-option>
                <a-select-option value="PrepaymentPenaltyReturn">
                  已还期数小于
                </a-select-option>
                <a-select-option value="PrepaymentPenaltySurplus">
                  剩余期数大于
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="1"
                 v-show="addReceiveForm.getFieldsValue().prepaymentPenaltyType!='PrepaymentPenaltyNo'&&addReceiveForm.getFieldsValue().prepaymentPenaltyType">
            <span class="_zw"></span>
          </a-col>
          <a-col :span="4"
                 v-if="addReceiveForm.getFieldsValue().prepaymentPenaltyType!='PrepaymentPenaltyNo'&&addReceiveForm.getFieldsValue().prepaymentPenaltyType">
            <a-form-item label="">
              <a-input
                placeholder="请输入期数"
                suffix="期"
                v-decorator="[
                  'prepaymentPenaltyValue',
                  { rules: [{ required: true, message: '请输入期数' }] }
                ]"
              ></a-input>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row
          v-if="addReceiveForm.getFieldsValue().prepaymentPenaltyType!='PrepaymentPenaltyNo'&&addReceiveForm.getFieldsValue().prepaymentPenaltyType">
          <a-col :span="8">
            <a-form-item label="提前结清违约金设定">
              <a-select
                v-decorator="['prepaymentPenaltyCalType',{ rules: [{ required: true, message: '请选择提前结清违约金设定' }] }]"
                allow-clear>
                <a-select-option value="PrepaymentPenaltyCalFixed">
                  固定金额
                </a-select-option>
                <a-select-option value="PrepaymentPenaltyCalRatio">
                  本期应还比例
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="1"
                 v-show="addReceiveForm.getFieldsValue().prepaymentPenaltyCalType">
            <span class="_zw"></span>
          </a-col>
          <a-col :span="4" v-if="addReceiveForm.getFieldsValue().prepaymentPenaltyCalType=='PrepaymentPenaltyCalFixed'">
            <a-form-item label="">
              <a-input
                placeholder="请输入金额"
                suffix="元"
                v-decorator="[
                  'prepaymentPenaltyCalValue',
                  { rules: [{ required: true, message: '请输入金额' }] }
                ]"
              ></a-input>
            </a-form-item>
          </a-col>
          <a-col :span="4" v-if="addReceiveForm.getFieldsValue().prepaymentPenaltyCalType=='PrepaymentPenaltyCalRatio'">
            <a-form-item label="">
              <a-input
                placeholder="请输入比例"
                suffix="%"
                v-decorator="[
                  'prepaymentPenaltyCalRatio',
                  { rules: [{ required: true, message: '请输入比例' }] }
                ]"
              ></a-input>
            </a-form-item>
          </a-col>
        </a-row>
        <div class="footer">
          <a-button type="primary" @click="onModify">保存</a-button>
          <a-button @click="goBack">取消</a-button>
        </div>
      </a-form>
    </section>
  </a-layout-content>
</template>

<script>
import { TreeSelect } from "ant-design-vue";
import {
  fetchProductsAndSkuTree,
  installmentConfigAdd,
  installmentConfigDetail,
  installmentConfigEdit
} from "@/services/ValueBag";
import moment from "moment";
import { tsFormat } from "@/components/DateUtils";

const productTreeFields = {
  children: "skuList",
  title: "name",
  key: "id",
  value: "id"
};
const SHOW_PARENT = TreeSelect.SHOW_PARENT;
export default {
  data() {
    return {
      installmentConfigId: this.$route.query.installmentConfigId,
      productList: [],
      productTreeFields,
      SHOW_PARENT,
      addReceiveForm: this.$form.createForm(this),
      skuIdList: []
    };
  },
  mounted() {
    if (this.installmentConfigId) {
      this.getDetail();
    }
    this.onLoadProductTree();
  },
  methods: {
    // 加载产品树
    onLoadProductTree() {
      fetchProductsAndSkuTree().then(resp => {
        if (resp.data.code === "SUCCESS") {
          const productList = [];
          resp.data.data.forEach(item => {
            if (item.skuList && item.skuList.length > 0) {
              productList.push(item);
            }
          });
          this.productList = productList;
          if (this.productList.length > 0) {
            this.productList.forEach(item => {
              item.id = item.productId;
              item.name = `${item.productName} ${item.productCode}`;
              if (item.skuList && item.skuList.length > 0) {
                item.skuList.forEach(items => {
                  items.id = items.skuId;
                  let skuName = "";
                  if (items.keyPropsMap) {
                    for (const [key, value] of Object.entries(
                      items.keyPropsMap
                    )) {
                      if (key) {
                        skuName += `${value} `;
                      }
                    }
                  }
                  items.name = `${items.skuCode} ${skuName}`;
                });
              }
            });
          }
        }
      });
    },
    // 返回上一层
    goBack() {
      this.$router.go(-1);
    },
    getDetail() {
      installmentConfigDetail(this.installmentConfigId).then(resp => {
        let insertSkuIds = [];
        const _resData = resp.data.data;
        _resData.skuList.forEach(item => {
          insertSkuIds.push(item.skuId);
          this.skuIdList.push(item.skuId);
        });
        this.addReceiveForm.setFieldsValue({
          insertSkuIds: insertSkuIds,
          title: _resData.title,
          time: [tsFormat(_resData.startTime), tsFormat(_resData.endTime)],
          restrictCreditType: _resData.restrictCreditType,
          repaymentPeriods: _resData.repaymentPeriods.split(","),
          apr: _resData.apr,
          repaymentType: _resData.repaymentType,
          overdueAddType: _resData.overdueAddType,
          managementFeeType: _resData.managementFeeType,
          prepaymentPenaltyType: _resData.prepaymentPenaltyType
        });
        this.$nextTick(() => {
          this.addReceiveForm.setFieldsValue({
            restrictCreditRatio: _resData.restrictCreditRatio,
            restrictCreditMaxValue: _resData.restrictCreditMaxValue,
            overdueAddRatio: _resData.overdueAddRatio,
            overdueAddValue: _resData.overdueAddValue,
            prepaymentPenaltyValue: _resData.prepaymentPenaltyValue,
            prepaymentPenaltyCalType: _resData.prepaymentPenaltyCalType,
            managementFeeValue: _resData.managementFeeValue,
            managementFeeRatio: _resData.managementFeeRatio
          });
          this.$nextTick(() => {
            this.addReceiveForm.setFieldsValue({
              prepaymentPenaltyCalValue: _resData.prepaymentPenaltyCalValue,
              prepaymentPenaltyCalRatio: _resData.prepaymentPenaltyCalRatio
            });
          });
        });
      });
    },
    onModify() {
      this.addReceiveForm.validateFields((err, values) => {
        if (!err) {
          let params = {
            title: values.title,
            startTime: moment(values.time[0]),
            endTime: moment(values.time[1]),
            restrictCreditType: values.restrictCreditType,
            restrictCreditRatio: values.restrictCreditRatio,
            restrictCreditMaxValue: values.restrictCreditMaxValue,
            repaymentPeriods: values.repaymentPeriods.join(","),
            apr: values.apr,
            repaymentType: values.repaymentType,
            overdueAddType: values.overdueAddType,
            overdueAddRatio: values.overdueAddRatio,
            overdueAddValue: values.overdueAddValue,
            prepaymentPenaltyType: values.prepaymentPenaltyType,
            prepaymentPenaltyValue: values.prepaymentPenaltyValue,
            prepaymentPenaltyCalType: values.prepaymentPenaltyCalType,
            prepaymentPenaltyCalValue: values.prepaymentPenaltyCalValue,
            prepaymentPenaltyCalRatio: values.prepaymentPenaltyCalRatio,
            managementFeeType: values.managementFeeType,
            managementFeeValue: values.managementFeeValue,
            managementFeeRatio: values.managementFeeRatio
          };
          if (this.installmentConfigId) {
            params.installmentConfigId = this.installmentConfigId;
            let removeSkuIds = [];
            let insertSkuIds = [];
            let _insertSkuIds = [];
            values.insertSkuIds.forEach(item => {
              let isCheck = false;
              this.productList.forEach(items => {
                if (item == items.id) {
                  isCheck = true;
                  items.skuList.forEach(m => {
                    _insertSkuIds.push(m.id);
                  });
                }
              });
              if (!isCheck) {
                _insertSkuIds.push(item);
              }
            });
            _insertSkuIds.forEach(item => {
              if (this.skuIdList.indexOf(item) == -1) {
                insertSkuIds.push(item);
              }
            });
            this.skuIdList.forEach(item => {
              if (_insertSkuIds.indexOf(item) == -1) {
                removeSkuIds.push(item);
              }
            });
            params.removeSkuIds = removeSkuIds.join(",");
            params.insertSkuIds = insertSkuIds.join(",");
            installmentConfigEdit(params).then(resp => {
              if (resp.data.code === "SUCCESS") {
                this.$message.success("配置成功");
                this.$router.go(-1);
              }
            });
          } else {
            let insertSkuIds = [];
            values.insertSkuIds.forEach(item => {
              let isCheck = false;
              this.productList.forEach(items => {
                if (item == items.id) {
                  isCheck = true;
                  items.skuList.forEach(m => {
                    insertSkuIds.push(m.id);
                  });
                }
              });
              if (!isCheck) {
                insertSkuIds.push(item);
              }
            });
            params.insertSkuIds = insertSkuIds.join(",");
            installmentConfigAdd(params).then(resp => {
              if (resp.data.code === "SUCCESS") {
                this.$message.success("创建成功");
                this.$router.go(-1);
              }
            });
          }

        }
      });
    }
  }
};
</script>

<style scoped>
.info-container {
  box-sizing: border-box;
  padding: 25px 30px 25px 30px;
  margin: 10px 20px 0 20px;
  background-color: white;
}

/deep/ .ant-form-item {
  display: flex;
}

/deep/ .ant-form-item .ant-form-item-label {
  text-align: left;
  min-width: 150px !important;
}

._zw {
  display: block;
  background: #ccc;
  width: 20px;
  height: 2px;
  margin: 19px auto;
}

.footer button {
  margin-right: 8px;
}
</style>

<template>
  <a-layout-content>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>配置管理</a-breadcrumb-item>
        <a-breadcrumb-item>租车订单分期配置</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="content-container">
      <a-row>
        <a-button type="primary" @click="onAdd()">新增</a-button>
      </a-row>
      <div class="main-content">
        <a-table
          class="commodity-table"
          :data-source="tblData"
          :columns="tblColumns"
          :pagination="pagination"
          :loading="loading"
          :row-key="
            (record, index) => {
              return index;
            }
          "
          @change="onPage"
        >
          <template slot="startTime" slot-scope="text">
            <div class="exile">
              {{ toDate(text) }}
            </div>
          </template>
          <template slot="endTime" slot-scope="text">
            <div class="exile">
              {{ toDate(text) }}
            </div>
          </template>
          <a-space slot="action" slot-scope="text, record">
            <a
              @click="onEdit(record)"
            >
              配置
            </a>
          </a-space>
        </a-table>
      </div>
    </div>
  </a-layout-content>
</template>

<script>
import {  installmentConfig } from "@/services/ValueBag";
import { tsFormat } from "@/components/DateUtils";
const tblColumns = [
  {
    title: "标题",
    dataIndex: "title",
    width: "40%"
  },
  {
    title: "开始时间",
    dataIndex: "startTime",
    scopedSlots:{
      customRender:"startTime"
    },
    width: "25%"
  },
  {
    title: "结束时间",
    dataIndex: "endTime",
    scopedSlots:{
      customRender:"endTime"
    },
    width: "25%"
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
    width: "10%"
  }
];
export default {
  data(){
    return{
      tblData: [],
      tblColumns,
      pagination: {
        hideOnSinglePage: true
      },
      loading: false,
    }
  },
  mounted() {
    this.fetchList();
  },
  methods:{
    toDate(time){
      return tsFormat(time)
    },
    //新增
    onAdd(){
      this.$router.push({
        name: "installment"
      });
    },
    //分页
    onPage(pagination) {
      this.pagination.current = pagination.current;
      this.fetchList();
    },
    // 获取列表信息
    fetchList() {
      const params={
        pageNum: this.pagination.current ? this.pagination.current : 1,
        pageSize: this.pagination.pageSize ? this.pagination.pageSize : 10,
      }
      this.loading = true;
      installmentConfig(params).then(resp=>{
        this.loading = false;
        if (resp.data.code === "SUCCESS") {
          this.tblData=resp.data.data.records?resp.data.data.records:[]
        }
      })
        .catch(error => {
          if (error) {
            this.loading = false;
          }
        });
    },
    onEdit(item){
      this.$router.push({
        name: "installment",
        query:{
          installmentConfigId:item.installmentConfigId
        }
      });
    }
  }
};
</script>

<style scoped>
.commodity-table {
  margin-top: 10px;
}
.content-container {
  min-height: 1000px;
  background-color: white;
  margin: 30px;
}
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-layout-content',[_c('div',{staticClass:"nav"},[_c('a-breadcrumb',[_c('a-breadcrumb-item',[_vm._v("配置管理")]),_c('a-breadcrumb-item',[_vm._v("租车订单分期配置")]),_c('a-breadcrumb-item',[_vm._v(_vm._s(_vm.installmentConfigId ? "编辑" : "新增"))])],1)],1),_c('section',{staticClass:"info-container"},[_c('a-form',{staticClass:"receive-form",attrs:{"form":_vm.addReceiveForm}},[_c('a-row',[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"标题"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'title',
                { rules: [{ required: true, message: '请输入标题' }] }
              ]),expression:"[\n                'title',\n                { rules: [{ required: true, message: '请输入标题' }] }\n              ]"}],attrs:{"placeholder":"请输入标题"}})],1)],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"支持车型"}},[_c('a-tree-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'insertSkuIds',
                {
                  rules: [{ required: true, message: '请选择支持车型' }]
                }
              ]),expression:"[\n                'insertSkuIds',\n                {\n                  rules: [{ required: true, message: '请选择支持车型' }]\n                }\n              ]"}],attrs:{"dropdown-style":{ maxHeight: '400px', overflow: 'auto' },"tree-data":_vm.productList,"multiple":"","replace-fields":_vm.productTreeFields,"tree-node-filter-prop":"title","allow-clear":true,"tree-checkable":"","show-checked-strategy":_vm.SHOW_PARENT}})],1)],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"生效日期"}},[_c('a-range-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'time',
                {
                  rules: [{ required: true, message: '请选择生效日期' }]
                }
              ]),expression:"[\n                'time',\n                {\n                  rules: [{ required: true, message: '请选择生效日期' }]\n                }\n              ]"}],attrs:{"valueFormat":"YYYY-MM-DD","allow-clear":""}})],1)],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"每单限制"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['restrictCreditType',{ rules: [{ required: true, message: '请选择限制' }] }]),expression:"['restrictCreditType',{ rules: [{ required: true, message: '请选择限制' }] }]"}],attrs:{"allow-clear":""}},[_c('a-select-option',{attrs:{"value":"RestrictFixed"}},[_vm._v(" 不大于指定金额 ")]),_c('a-select-option',{attrs:{"value":"RestrictRatio"}},[_vm._v(" 不大于指定比例 ")]),_c('a-select-option',{attrs:{"value":"RestrictFixedRatio"}},[_vm._v(" 按比例不大于指定金额 ")]),_c('a-select-option',{attrs:{"value":"RestrictNo"}},[_vm._v(" 不限制 ")])],1)],1)],1),_c('a-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.addReceiveForm.getFieldsValue().restrictCreditType!='RestrictNo'&&_vm.addReceiveForm.getFieldsValue().restrictCreditType),expression:"addReceiveForm.getFieldsValue().restrictCreditType!='RestrictNo'&&addReceiveForm.getFieldsValue().restrictCreditType"}],attrs:{"span":1}},[_c('span',{staticClass:"_zw"})]),(_vm.addReceiveForm.getFieldsValue().restrictCreditType=='RestrictRatio'||_vm.addReceiveForm.getFieldsValue().restrictCreditType=='RestrictFixedRatio')?_c('a-col',{attrs:{"span":4}},[_c('a-form-item',{attrs:{"label":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'restrictCreditRatio',
                { rules: [{ required: true, message: '请输入比例' }] }
              ]),expression:"[\n                'restrictCreditRatio',\n                { rules: [{ required: true, message: '请输入比例' }] }\n              ]"}],attrs:{"placeholder":"请输入比例","suffix":"%"}})],1)],1):_vm._e(),_c('a-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.addReceiveForm.getFieldsValue().restrictCreditType=='RestrictFixedRatio'),expression:"addReceiveForm.getFieldsValue().restrictCreditType=='RestrictFixedRatio'"}],attrs:{"span":1}},[_c('span',{staticClass:"_zw"})]),(_vm.addReceiveForm.getFieldsValue().restrictCreditType=='RestrictFixed'||_vm.addReceiveForm.getFieldsValue().restrictCreditType=='RestrictFixedRatio')?_c('a-col',{attrs:{"span":4}},[_c('a-form-item',{attrs:{"label":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'restrictCreditMaxValue',
                { rules: [{ required: true, message: '请输入金额' }] }
              ]),expression:"[\n                'restrictCreditMaxValue',\n                { rules: [{ required: true, message: '请输入金额' }] }\n              ]"}],attrs:{"placeholder":"请输入金额","suffix":"元"}})],1)],1):_vm._e()],1),_c('a-row',[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"管理费"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['managementFeeType',{ rules: [{ required: true, message: '请选择管理费' }] }]),expression:"['managementFeeType',{ rules: [{ required: true, message: '请选择管理费' }] }]"}],attrs:{"allow-clear":""}},[_c('a-select-option',{attrs:{"value":"ManagementFeeTypeFixed"}},[_vm._v(" 固定金额 ")]),_c('a-select-option',{attrs:{"value":"ManagementFeeTypeRatio"}},[_vm._v(" 固定比例 ")])],1)],1)],1),(_vm.addReceiveForm.getFieldsValue().managementFeeType)?_c('a-col',{attrs:{"span":1}},[_c('span',{staticClass:"_zw"})]):_vm._e(),(_vm.addReceiveForm.getFieldsValue().managementFeeType=='ManagementFeeTypeFixed')?_c('a-col',{attrs:{"span":4}},[_c('a-form-item',{attrs:{"label":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'managementFeeValue',
                        { rules: [{ required: true, message: '请输入金额' }] }
                      ]),expression:"[\n                        'managementFeeValue',\n                        { rules: [{ required: true, message: '请输入金额' }] }\n                      ]"}],attrs:{"placeholder":"请输入金额","suffix":"元"}})],1)],1):_vm._e(),(_vm.addReceiveForm.getFieldsValue().managementFeeType=='ManagementFeeTypeRatio')?_c('a-col',{attrs:{"span":4}},[_c('a-form-item',{attrs:{"label":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'managementFeeRatio',
                        { rules: [{ required: true, message: '请输入比例' }] }
                      ]),expression:"[\n                        'managementFeeRatio',\n                        { rules: [{ required: true, message: '请输入比例' }] }\n                      ]"}],attrs:{"placeholder":"请输入比例","suffix":"%"}})],1)],1):_vm._e()],1),_c('a-row',[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"设置期数"}},[_c('a-checkbox-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'repaymentPeriods',
                { rules: [{ required: true, message: '请设置期数' }] }
              ]),expression:"[\n                'repaymentPeriods',\n                { rules: [{ required: true, message: '请设置期数' }] }\n              ]"}]},[_c('a-checkbox',{attrs:{"value":"3"}},[_vm._v("3期")]),_c('a-checkbox',{attrs:{"value":"6"}},[_vm._v("6期")]),_c('a-checkbox',{attrs:{"value":"9"}},[_vm._v("9期")]),_c('a-checkbox',{attrs:{"value":"12"}},[_vm._v("12期")])],1)],1)],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"还款方式"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['repaymentType',{ rules: [{ required: true, message: '请选择还款方式' }] }]),expression:"['repaymentType',{ rules: [{ required: true, message: '请选择还款方式' }] }]"}],attrs:{"allow-clear":""}},[_c('a-select-option',{attrs:{"value":"RepaymentEPI"}},[_vm._v(" 等额本息 ")])],1)],1)],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"年化利率"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'apr',
                { rules: [{ required: true, message: '请输入年化利率' }] }
              ]),expression:"[\n                'apr',\n                { rules: [{ required: true, message: '请输入年化利率' }] }\n              ]"}],attrs:{"placeholder":"请输入年化利率","suffix":"%"}})],1)],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"逾期每天增加"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['overdueAddType',{ rules: [{ required: true, message: '请选择逾期每天增加' }] }]),expression:"['overdueAddType',{ rules: [{ required: true, message: '请选择逾期每天增加' }] }]"}],attrs:{"allow-clear":""}},[_c('a-select-option',{attrs:{"value":"OverdueAddFixed"}},[_vm._v(" 固定金额 ")]),_c('a-select-option',{attrs:{"value":"OverdueAddRatio"}},[_vm._v(" 本期应还比例 ")])],1)],1)],1),_c('a-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.addReceiveForm.getFieldsValue().overdueAddType),expression:"addReceiveForm.getFieldsValue().overdueAddType"}],attrs:{"span":1}},[_c('span',{staticClass:"_zw"})]),(_vm.addReceiveForm.getFieldsValue().overdueAddType=='OverdueAddFixed')?_c('a-col',{attrs:{"span":4}},[_c('a-form-item',{attrs:{"label":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'overdueAddValue',
                { rules: [{ required: true, message: '请输入金额' }] }
              ]),expression:"[\n                'overdueAddValue',\n                { rules: [{ required: true, message: '请输入金额' }] }\n              ]"}],attrs:{"placeholder":"请输入金额","suffix":"元"}})],1)],1):_vm._e(),(_vm.addReceiveForm.getFieldsValue().overdueAddType=='OverdueAddRatio')?_c('a-col',{attrs:{"span":4}},[_c('a-form-item',{attrs:{"label":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'overdueAddRatio',
                { rules: [{ required: true, message: '请输入比例' }] }
              ]),expression:"[\n                'overdueAddRatio',\n                { rules: [{ required: true, message: '请输入比例' }] }\n              ]"}],attrs:{"placeholder":"请输入比例","suffix":"%"}})],1)],1):_vm._e()],1),_c('a-row',[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"提前结清违约金条件"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['prepaymentPenaltyType',{ rules: [{ required: true, message: '请选择提前结清违约金条件' }] }]),expression:"['prepaymentPenaltyType',{ rules: [{ required: true, message: '请选择提前结清违约金条件' }] }]"}],attrs:{"allow-clear":""}},[_c('a-select-option',{attrs:{"value":"PrepaymentPenaltyNo"}},[_vm._v(" 不收取 ")]),_c('a-select-option',{attrs:{"value":"PrepaymentPenaltyReturn"}},[_vm._v(" 已还期数小于 ")]),_c('a-select-option',{attrs:{"value":"PrepaymentPenaltySurplus"}},[_vm._v(" 剩余期数大于 ")])],1)],1)],1),_c('a-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.addReceiveForm.getFieldsValue().prepaymentPenaltyType!='PrepaymentPenaltyNo'&&_vm.addReceiveForm.getFieldsValue().prepaymentPenaltyType),expression:"addReceiveForm.getFieldsValue().prepaymentPenaltyType!='PrepaymentPenaltyNo'&&addReceiveForm.getFieldsValue().prepaymentPenaltyType"}],attrs:{"span":1}},[_c('span',{staticClass:"_zw"})]),(_vm.addReceiveForm.getFieldsValue().prepaymentPenaltyType!='PrepaymentPenaltyNo'&&_vm.addReceiveForm.getFieldsValue().prepaymentPenaltyType)?_c('a-col',{attrs:{"span":4}},[_c('a-form-item',{attrs:{"label":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'prepaymentPenaltyValue',
                { rules: [{ required: true, message: '请输入期数' }] }
              ]),expression:"[\n                'prepaymentPenaltyValue',\n                { rules: [{ required: true, message: '请输入期数' }] }\n              ]"}],attrs:{"placeholder":"请输入期数","suffix":"期"}})],1)],1):_vm._e()],1),(_vm.addReceiveForm.getFieldsValue().prepaymentPenaltyType!='PrepaymentPenaltyNo'&&_vm.addReceiveForm.getFieldsValue().prepaymentPenaltyType)?_c('a-row',[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"提前结清违约金设定"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['prepaymentPenaltyCalType',{ rules: [{ required: true, message: '请选择提前结清违约金设定' }] }]),expression:"['prepaymentPenaltyCalType',{ rules: [{ required: true, message: '请选择提前结清违约金设定' }] }]"}],attrs:{"allow-clear":""}},[_c('a-select-option',{attrs:{"value":"PrepaymentPenaltyCalFixed"}},[_vm._v(" 固定金额 ")]),_c('a-select-option',{attrs:{"value":"PrepaymentPenaltyCalRatio"}},[_vm._v(" 本期应还比例 ")])],1)],1)],1),_c('a-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.addReceiveForm.getFieldsValue().prepaymentPenaltyCalType),expression:"addReceiveForm.getFieldsValue().prepaymentPenaltyCalType"}],attrs:{"span":1}},[_c('span',{staticClass:"_zw"})]),(_vm.addReceiveForm.getFieldsValue().prepaymentPenaltyCalType=='PrepaymentPenaltyCalFixed')?_c('a-col',{attrs:{"span":4}},[_c('a-form-item',{attrs:{"label":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'prepaymentPenaltyCalValue',
                { rules: [{ required: true, message: '请输入金额' }] }
              ]),expression:"[\n                'prepaymentPenaltyCalValue',\n                { rules: [{ required: true, message: '请输入金额' }] }\n              ]"}],attrs:{"placeholder":"请输入金额","suffix":"元"}})],1)],1):_vm._e(),(_vm.addReceiveForm.getFieldsValue().prepaymentPenaltyCalType=='PrepaymentPenaltyCalRatio')?_c('a-col',{attrs:{"span":4}},[_c('a-form-item',{attrs:{"label":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'prepaymentPenaltyCalRatio',
                { rules: [{ required: true, message: '请输入比例' }] }
              ]),expression:"[\n                'prepaymentPenaltyCalRatio',\n                { rules: [{ required: true, message: '请输入比例' }] }\n              ]"}],attrs:{"placeholder":"请输入比例","suffix":"%"}})],1)],1):_vm._e()],1):_vm._e(),_c('div',{staticClass:"footer"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.onModify}},[_vm._v("保存")]),_c('a-button',{on:{"click":_vm.goBack}},[_vm._v("取消")])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
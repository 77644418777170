<template>
  <a-layout-content>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>审核管理</a-breadcrumb-item>
        <a-breadcrumb-item
          style="cursor: pointer"
          v-if="storeRouterName === 'ToDoTask'"
        >
          <a @click="onBack">待办任务</a>
        </a-breadcrumb-item>
        <a-breadcrumb-item style="cursor: pointer" v-else>
          <a @click="onBack">价值包申报审核</a>
        </a-breadcrumb-item>
        <a-breadcrumb-item>审核</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="card-container" style="padding-bottom: 0">
      <a-row>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title">基本信息</div>
          </div>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              申请时间:
            </div>
            <div class="card-item-title-contents">
              {{ dtFormat(infoDetail.createTime) }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              审核状态:
            </div>
            <div class="card-item-title-contents">
              <span
                :class="{
                  yellow: infoDetail.status === '待审核',
                  green: infoDetail.status === '已通过',
                  red: infoDetail.status === '未通过',
                  gray: infoDetail.status === '已作废'
                }"
              >
                {{ infoDetail.status }}
              </span>
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              运营中心:
            </div>
            <div class="card-item-title-contents">
              {{ infoDetail.corporationName }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              生效时间:
            </div>
            <div class="card-item-title-contents">
              {{
                infoDetail.startTime
                  ? dtFormat(infoDetail.startTime) +
                    "至" +
                    dtFormat(infoDetail.endTime)
                  : "--"
              }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              优先级:
            </div>
            <div class="card-item-title-contents">
              {{ infoDetail.priority ? infoDetail.priority : "--" }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              作用地区:
            </div>
            <div class="card-item-title-contents">
              {{ infoDetail.areaName ? infoDetail.areaName : "--" }}
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="card-container">
      <a-row>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title">作用产品/商品</div>
          </div>
        </a-col>
      </a-row>
      <a-table
        :data-source="infoDetail.midList"
        :columns="tblColumns"
        :pagination="pagination"
        :loading="false"
        row-key="id"
      >
        <template slot="keyProps" slot-scope="text, record">
          <div v-for="(item, index) in record.keyProps" :key="index">
            {{ index + ":" + item }}
          </div>
        </template>
      </a-table>
    </div>
    <div class="card-container" style="padding-bottom: 0">
      <a-row>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title">价值包配置</div>
          </div>
        </a-col>
      </a-row>
      <a-row>
        <div v-for="item in valueList" :key="item.policyId">
          <a-col :span="8">
            <div class="card-item">
              <div class="card-item-title-content">
                {{ item.policyTypeValue }}：
              </div>
              <div class="card-item-title-contents">
                {{ item.appendFixedValue }}元/每台
              </div>
            </div>
          </a-col>
        </div>
      </a-row>
    </div>
    <a-modal
      style="z-index: 99"
      :visible="previewVisible"
      @cancel="previewVisible = false"
      :footer="false"
    >
      <img
        src="../../../public/reportLog.png"
        alt="logo"
        style="width: 100%"
        :footer="null"
      />
    </a-modal>
    <div class="card-container">
      <a-row>
        <a-col :span="24">
          <div class="card-item">
            <div class="card-item-title">流转信息</div>
            <div class="card-item-img" @click="onShowProcessImg">
              点击查看流程图
            </div>
          </div>
        </a-col>
      </a-row>
      <div v-viewer class="images">
        <img
          :data-source="require('../../../public/reportLog.png')"
          src="../../../public/reportLog.png"
          alt="logo"
        />
      </div>
      <a-table
        :data-source="flowList"
        :columns="logColumns"
        :pagination="false"
        :loading="false"
        row-key="logId"
      />
    </div>
    <div v-if="infoDetail.status === '待审核'" class="card-container">
      <a-row>
        <a-col :span="24">
          <div class="card-item">
            <div class="card-item-title-content">
              审核意见：
            </div>
            <div class="card-item-content" style="flex: 1">
              <a-textarea v-model="reason"></a-textarea>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
    <div style="margin-bottom: 60px"></div>
    <div class="examine-container">
      <div class="button-container">
        <a-button
          v-if="fromRouterName !== 'ToDoTask' && fromRouterName !== 'DoneTask'"
          type="white"
          class="button-item"
          :disabled="nextDisabled"
          @click="onNext"
        >
          下一个
        </a-button>
        <a-button
          v-if="infoDetail.status === '待审核'"
          type="white"
          class="button-item"
          @click="onRefuse"
        >
          不通过
        </a-button>
        <a-button
          v-if="infoDetail.status === '待审核'"
          type="white"
          class="button-item"
          @click="onPass"
        >
          通过
        </a-button>
      </div>
    </div>
  </a-layout-content>
</template>

<script>
import moment from "moment";
import { imageBaseUrl } from "@/services/HttpService";
import { dtFormat, tsFormat } from "@/components/DateUtils";
import {
  fetchPolicyDetail,
  fetchStrategyApplyList,
  fetchStrategyOfPolicyList,
  fetchValueBagLog,
  passValueReport,
  unpassValueReport
} from "@/services/ValueBag";

const tblColumns = [
  {
    title: "产品名称",
    dataIndex: "productName"
  },
  {
    title: "产品编号",
    dataIndex: "productCode"
  },
  {
    title: "商品编号",
    dataIndex: "skuCode"
  },
  {
    title: "商品规格",
    dataIndex: "keyProps",
    scopedSlots: { customRender: "keyProps" }
  }
];
const logColumns = [
  {
    title: "执行人",
    dataIndex: "createName"
  },
  {
    title: "机构名称",
    dataIndex: "corporationName"
  },
  {
    title: "渠道类型",
    dataIndex: "distributorTypeValue"
  },
  {
    title: "操作时间",
    dataIndex: "createTime"
  },
  {
    title: "执行动作",
    dataIndex: "strategyStatusValue"
  },
  {
    title: "审核意见",
    dataIndex: "reason"
  }
];
export default {
  data() {
    return {
      tsFormat,
      dtFormat,
      tblColumns,
      reason: "",
      logColumns,
      pagination: {
        hideOnSinglePage: true
      },
      previewVisible: false,
      valueList: [],
      flowList: [],
      time: moment().format("YYYY-MM-DD"),
      action: this.$route.params.action,
      queryInfo: JSON.parse(sessionStorage.getItem("valueList")),
      strategyId: this.$route.params.id,
      infoDetail: {},
      imageBaseUrl,
      imageUrl: "",
      totalList: [],
      nextDisabled: false,
      fromRouterName: "",
      storeRouterName: ""
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.fromRouterName = from.name;
      if (vm.fromRouterName) {
        sessionStorage.setItem("fromRouterName", vm.fromRouterName);
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    sessionStorage.removeItem("fromRouterName");
    next();
  },
  mounted() {
    if (sessionStorage.getItem("fromRouterName")) {
      this.storeRouterName = sessionStorage.getItem("fromRouterName");
    }
    this.onFetchDetail();
    if (this.storeRouterName !== "ToDoTask") {
      this.onloadList();
    }
    this.loadLogDetail();
  },
  methods: {
    // 查看流程图
    onShowProcessImg() {
      const viewer = this.$el.querySelector(".images").$viewer;
      viewer.show();
    },
    onBack() {
      this.$router.go(-1);
    },
    // 加载节点详情
    loadLogDetail() {
      fetchValueBagLog(this.strategyId).then(resp => {
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          this.flowList = data.reverse();
          this.flowList.forEach(item => {
            if (!item.createName) {
              item.createName = "--";
            }
            if (item.createTime) {
              item.createTime = dtFormat(item.createTime);
              item.result = item.result ? item.result : "--";
            }
          });
        }
      });
    },
    onFetchDetail() {
      fetchPolicyDetail(this.strategyId).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.infoDetail = resp.data.data;
          const areaList = [];
          if (this.infoDetail.areaList && this.infoDetail.areaList.length > 0) {
            this.infoDetail.areaList.forEach(ite => {
              areaList.push(ite.areaName);
            });
          }
          this.infoDetail.areaName = areaList.join(",");
          if (this.infoDetail.status === "UN_APPROVAL") {
            this.infoDetail.status = "待审核";
          }
          if (this.infoDetail.status === "PASSED") {
            this.infoDetail.status = "已通过";
          }
          if (this.infoDetail.status === "UN_PASSED") {
            this.infoDetail.status = "未通过";
          }
          if (this.infoDetail.status === "INVALIDED") {
            this.infoDetail.status = "已作废";
          }
          this.fetchValueList();
        }
      });
    },
    // 获取列表信息
    fetchValueList() {
      const params = {
        strategyId: this.strategyId,
        pageNum: 1,
        pageSize: 30
      };
      fetchStrategyOfPolicyList(params).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.valueList = resp.data.data.records;
        }
      });
    },
    // 通过活动
    onPass() {
      passValueReport(this.strategyId, this.reason).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.$message.success("已通过");
          this.onFetchDetail();
          this.loadLogDetail();
          this.$store.state.hasChange = "1";
        }
      });
    },
    onRefuse() {
      if (!this.reason) {
        this.$message.warning("请输入原因");
        return;
      }
      unpassValueReport(this.strategyId, this.reason).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.$message.success("已不通过");
          this.onFetchDetail();
          this.loadLogDetail();
          this.$store.state.hasChange = "1";
        }
      });
    },
    // 获取列表信息
    onloadList() {
      fetchStrategyApplyList(this.queryInfo).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.totalList = resp.data.data.records;
        }
      });
    },
    async onNext() {
      if (this.totalList.length > 0) {
        const dealerIndex = this.totalList.findIndex(
          item => item.strategyId === this.strategyId
        );
        if (dealerIndex === this.totalList.length - 1) {
          this.queryInfo.pageNum += 1;
          sessionStorage.setItem("valueList", this.queryInfo);
          await this.onloadList();
          if (this.totalList.length !== 0) {
            this.infoDetail = this.totalList[0];
            this.strategyId = this.totalList[0].strategyId;
            this.reason = "";
            this.onFetchDetail();
            this.loadLogDetail();
          } else {
            this.$message.error("已经是最后一条啦");
            this.nextDisabled = true;
            this.reason = "";
            this.onFetchDetail();
          }
        } else {
          this.infoDetail = this.totalList[dealerIndex + 1];
          this.strategyId = this.totalList[dealerIndex + 1].strategyId;
          this.reason = "";
          this.onFetchDetail();
          this.loadLogDetail();
        }
      } else {
        this.$message.error("已经是最后一条啦");
        this.nextDisabled = true;
        this.reason = "";
        this.onFetchDetail();
      }
    }
  }
};
</script>

<style scoped>
.card-container {
  background-color: white;
  margin: 10px 20px;
  padding: 25px;
}
.card-item {
  display: flex;
  margin-bottom: 20px;
}
.card-item-title {
  font-weight: 600;
  margin-right: 10px;
}
.card-item-title-content {
  margin-right: 10px;
  width: 130px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.examine-container {
  background-color: white;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 10px;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
}
.button-container {
  display: flex;
}
/deep/.ant-btn {
  border-radius: 5px;
}
.button-item {
  margin-right: 15px;
}
.card-item-content img {
  width: 100%;
  object-fit: contain;
}
.goods {
  display: flex;
  align-items: center;
}
.car__detail {
  width: 50%;
  display: flex;
  align-items: center;
  height: 90px;
}
.car__img {
  width: 30%;
}
.car__info {
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 20px;
}
.info__word {
  color: rgb(113, 113, 113);
}
.info__title {
  font-size: 16px;
  color: rgb(16, 16, 16);
}
.car__img img {
  width: 100%;
  height: 90px;
}
.yellow {
  color: rgb(238, 132, 37);
}
.green {
  color: rgb(100, 198, 114);
}
.red {
  color: rgb(252, 88, 92);
}
.gray {
  color: rgb(151, 151, 151);
}
.card-item-img {
  flex: 1;
  text-align: right;
  color: rgb(58, 110, 248) !important;
  cursor: pointer;
}
/deep/.ant-modal-body {
  padding: 36px;
}
.images img {
  display: none;
}
.card-item-title-contents {
  margin-right: 10px;
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.card-item-title-contents:hover {
  margin-right: 10px;
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 99;
}
</style>

import { apiClient } from "./HttpService";

// 档案库列表
export function fetchActivityList(params) {
  return apiClient.get(
    `/sales-activities/pageList?activityType=${params.activityType}&pageNum=${params.pageNum}&pageSize=${params.pageSize}
    &activityStatus=${params.activityStatus}&submitStartTime=${params.submitStartTime}&submitEndTime=${params.submitEndTime}&
    hostProvinceCode=${params.hostProvinceCode}&hostCityCode=${params.hostCityCode}&hostCountyCode=${params.hostCountyCode}&
    activityTitle=${params.activityTitle}&corporationName=${params.corporationName}&distributorType=${params.distributorType}`
  );
}

// 取试骑列表详情
export function fetchExamineRideDetail(activityId) {
  return apiClient.get(`/sales-activities/${activityId}`);
}

// 通过试骑
export function passRideActivity(activityId, reason, allowCashFlag) {
  return apiClient.patch(
    `/sales-activities/pass/${activityId}?reason=${reason}${
      allowCashFlag === undefined ? "" : `&allowCashFlag=${allowCashFlag}`
    }`
  );
}

// 不通过试骑
export function unpassRideActivity(activityId, reason, allowCashFlag) {
  return apiClient.patch(
    `/sales-activities/refuse/${activityId}?reason=${reason}${
      allowCashFlag === undefined ? "" : `&allowCashFlag=${allowCashFlag}`
    }`
  );
}

// 查看活动日志
export function fetchExamineRideLog(activityId) {
  return apiClient.get(`/sales-activities/logs/${activityId}`);
}

// 试骑订单列表查询
export function fetchTrialRiderList(params) {
  return apiClient.get(
    `/cycleOrder/pageList?areaCode=${
      params.areaCode ? params.areaCode : ""
    }&pageNum=${params.pageNum}&pageSize=${params.pageSize}&consumerName=${
      params.consumerName ? params.consumerName : ""
    }&distributorId=${
      params.distributorId ? params.distributorId : ""
    }&endTime=${params.endTime ? params.endTime : ""}&goodsSn=${
      params.goodsSn ? params.goodsSn : ""
    }&orderStatus=${params.orderStatus ? params.orderStatus : ""}&skuId=${
      params.skuId ? params.skuId : ""
    }&startTime=${params.startTime ? params.startTime : ""}`
  );
}

// 查看试骑订单详情
export function fetchCycleOrder(orderNo) {
  return apiClient.get(`/cycleOrder/${orderNo}`);
}

// 查看试骑订单详情
export function fetchLeaseOrder(orderNo) {
  return apiClient.get(`/rent/order/${orderNo}`);
}

// 获取机构信息列表
export function fetchCorporationList(distributorCode) {
  return apiClient.get(`/corporation/organizationInfoList/${distributorCode}`);
}

// 租赁订单列表查询
export function fetchTrialLeaseList(params) {
  return apiClient.get(
    `/rent/order?areaCode=${params.areaCode ? params.areaCode : ""}&pageNum=${
      params.pageNum
    }&pageSize=${params.pageSize}&consumerName=${
      params.consumerName ? params.consumerName : ""
    }&distributorId=${
      params.distributorId ? params.distributorId : ""
    }&endTime=${params.endTime ? params.endTime : ""}&goodsSn=${
      params.goodsSn ? params.goodsSn : ""
    }&orderStatus=${params.orderStatus ? params.orderStatus : ""}&skuId=${
      params.skuId ? params.skuId : ""
    }&startTime=${params.startTime ? params.startTime : ""}`
  );
}

// 客户列表
export function getWebConsumerList(params) {
  return apiClient.get(
    `/web-customer?pageSize=${params.pageSize}&pageNum=${params.pageNum}&keyword=${params.keyword}&areaCode=${params.areaCode}&corporationId=${params.corporationId}`
  );
}

// 用户详情
export function getWebConsumerDetail(consumerId) {
  return apiClient.get(`/web-customer/${consumerId}`);
}

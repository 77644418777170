<template>
  <a-layout-content>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>审核管理</a-breadcrumb-item>
        <a-breadcrumb-item style="cursor: pointer">
          <span @click="onList">
            已审核列表
          </span>
        </a-breadcrumb-item>
        <a-breadcrumb-item>详情</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="card-container">
      <a-row>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title">
              申请经销商类型:
            </div>
            <div class="card-item-content">
              {{ infoDetail.dealerType }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title">
              创建时间:
            </div>
            <div class="card-item-content">
              {{ infoDetail.createTime }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title">
              审核状态:
            </div>
            <div class="card-item-content">
              {{ infoDetail.auditStatusName }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title">
              审核结果:
            </div>
            <div class="card-item-content">
              {{ infoDetail.auditResult ? infoDetail.auditResult : "--" }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title">
              审核时间:
            </div>
            <div class="card-item-content">
              {{ infoDetail.auditTime ? infoDetail.auditTime : "--" }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title">
              审核员:
            </div>
            <div class="card-item-content">
              {{ infoDetail.auditName }}
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="card-container">
      <a-row>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title">基本信息</div>
          </div>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              机构名称：
            </div>
            <div class="card-item-content">
              {{ infoDetail.dealerName }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              统一社会信用代码：
            </div>
            <div class="card-item-content">
              {{ infoDetail.orgCode }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              经销商地址：
            </div>
            <div class="card-item-content">
              {{ dealerAddress }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              联系人姓名：
            </div>
            <div class="card-item-content">
              {{ infoDetail.personName }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              联系电话：
            </div>
            <div class="card-item-content">
              {{ infoDetail.personTel }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              联系地址：
            </div>
            <div class="card-item-content">
              {{ perAddress }}
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="card-container">
      <a-row>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title">认证信息</div>
          </div>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              法人姓名：
            </div>
            <div class="card-item-content">
              {{ infoDetail.legalPerName }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              法人性别：
            </div>
            <div class="card-item-content">
              {{ infoDetail.legalPerGender }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              法人身份证号：
            </div>
            <div class="card-item-content">
              {{ infoDetail.legalPerIdCard }}
            </div>
          </div>
        </a-col>
        <a-col :span="16">
          <div class="card-item">
            <div class="card-item-title-content">
              营业执照：
            </div>
            <div class="card-item-content">
              <img
                v-if="imageUrl !== ''"
                :src="imageBaseUrl + imageUrl"
                alt="营业执照图片"
              />
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="examine-container">
      <div class="button-container">
        <a-button
          type="white"
          class="button-item"
          :disabled="nextDisabled"
          @click="onNext"
        >
          下一个
        </a-button>
      </div>
    </div>
  </a-layout-content>
</template>

<script>
import moment from "moment";
import {
  fetchExamineDetail,
  fetchExamineList,
  judgeExamine
} from "@/services/ExamineService";
import { imageBaseUrl } from "@/services/HttpService";

export default {
  data() {
    return {
      time: moment().format("YYYY-MM-DD"),
      dealerId: this.$route.params.dealerId,
      infoDetail: {},
      imageBaseUrl,
      imageUrl: "",
      totalList: [],
      queryInfo: JSON.parse(sessionStorage.getItem("corporationQueryList")),
      nextDisabled: false
    };
  },
  mounted() {
    this.onFetchDetail();
    this.onloadList();
    console.log(this.queryInfo);
  },
  methods: {
    onloadList() {
      fetchExamineList(this.queryInfo).then(resp => {
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          this.totalList = data.records;
        } else {
          this.$message.error(resp.data.errorMsg);
        }
      });
    },
    onNext() {
      if (this.totalList.length > 0) {
        const dealerIndex = this.totalList.findIndex(
          item => item.dealerId === this.dealerId
        );
        if (dealerIndex === this.totalList.length - 1) {
          this.queryInfo.pageNum += 1;
          sessionStorage.setItem("corporationQueryList", this.queryInfo);
          new Promise(() => {
            this.onloadList();
          }).then(() => {
            if (this.totalList.length !== 0) {
              this.infoDetail = this.totalList[0];
              this.dealerId = this.totalList[0].dealerId;
              this.onFetchDetail();
            } else {
              this.$message.error("已经是最后一条啦");
              this.nextDisabled = true;
            }
          });
        } else {
          this.infoDetail = this.totalList[dealerIndex + 1];
          this.dealerId = this.totalList[dealerIndex + 1].dealerId;
          this.onFetchDetail();
        }
      } else {
        this.$message.error("已经是最后一条啦");
        this.nextDisabled = true;
      }
    },
    onFetchDetail() {
      fetchExamineDetail(this.dealerId).then(resp => {
        if (resp.data.code === "SUCCESS") {
          console.log(resp);
          this.infoDetail = resp.data.data;
          if (this.infoDetail.orgProvinceName === this.infoDetail.orgCityName) {
            this.dealerAddress = `${this.infoDetail.orgProvinceName} ${this.infoDetail.orgCountyName} ${this.infoDetail.orgDetailAddress}`;
          } else {
            this.dealerAddress = `${this.infoDetail.orgProvinceName} ${this.infoDetail.orgCityName} ${this.infoDetail.orgCountyName} ${this.infoDetail.orgDetailAddress}`;
          }
          if (this.infoDetail.perProvinceName === this.infoDetail.perCityName) {
            this.perAddress = `${this.infoDetail.perProvinceName} ${this.infoDetail.perCountyName} ${this.infoDetail.perDetailAddress}`;
          } else {
            this.perAddress = `${this.infoDetail.perProvinceName} ${this.infoDetail.perCityName} ${this.infoDetail.perCountyName} ${this.infoDetail.perDetailAddress}`;
          }
          const businessLicenseImages = this.infoDetail.businessLicenseImages;
          if (businessLicenseImages.length > 0) {
            this.imageUrl = businessLicenseImages[0].fileUrl;
          }
          this.infoDetail.auditTime = moment(this.infoDetail.auditTime).format(
            "YYYY-MM-DD"
          );
          this.infoDetail.createTime = moment(
            this.infoDetail.createTime
          ).format("YYYY-MM-DD");
        }
      });
    },
    onList() {
      this.$router.push({ name: "ExaminedList" });
    },
    onJudge(result) {
      judgeExamine(this.dealerId, result).then(resp => {
        if (resp.data.code === "SUCCESS") {
          console.log(resp.data.data);
        }
      });
    }
  }
};
</script>

<style scoped>
.card-container {
  background-color: white;
  margin: 10px 20px;
  padding: 25px 25px 0;
}
.card-item {
  display: flex;
  margin-bottom: 20px;
}
.card-item-title {
  font-weight: 600;
  margin-right: 10px;
}
.card-item-title-content {
  margin-right: 10px;
  min-width: 100px;
}
.examine-container {
  background-color: white;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 10px;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
}
.button-container {
  display: flex;
}
/deep/.ant-btn {
  border-radius: 5px;
}
.button-item {
  margin-right: 15px;
}
.card-item-content img {
  width: 100%;
  object-fit: contain;
}
</style>

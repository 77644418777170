<template>
  <a-layout-content>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>审核管理</a-breadcrumb-item>
        <a-breadcrumb-item>已审核列表</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="content-container">
      <a-form :form="form" class="advanced-search-form">
        <div class="search-form">
          <a-row class="row">
            <a-col :span="21">
              <a-row>
                <a-col :span="8">
                  <a-form-item label="机构名称">
                    <a-input v-decorator="['dealerName']" allow-clear></a-input>
                  </a-form-item>
                </a-col>
                <a-col :span="8">
                  <a-form-item label="统一社会信用代码">
                    <a-input
                      v-decorator="['orgCode']"
                      allow-clear
                      @change="onOrgCodeChange"
                    ></a-input>
                  </a-form-item>
                </a-col>
                <a-col :span="8">
                  <a-form-item label="法人姓名">
                    <a-input
                      v-decorator="['legalPerName']"
                      allow-clear
                    ></a-input>
                  </a-form-item>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="8">
                  <a-form-item label="联系人姓名">
                    <a-input v-decorator="['personName']" allow-clear></a-input>
                  </a-form-item>
                </a-col>
                <a-col :span="8">
                  <a-form-item label="联系人电话">
                    <a-input v-decorator="['personTel']" allow-clear></a-input>
                  </a-form-item>
                </a-col>
                <a-col :span="8">
                  <a-form-item label="申请类型">
                    <a-select
                      v-decorator="['dealerType']"
                      :options="typeOptions"
                      allow-clear
                    ></a-select>
                  </a-form-item>
                </a-col>
              </a-row>
            </a-col>
            <a-col :span="3" class="col">
              <a-button type="primary" icon="search" @click="onSearch">
                搜索
              </a-button>
              <a-button icon="undo" class="btn-reset" @click="onReset">
                重置
              </a-button>
            </a-col>
          </a-row>
        </div>
      </a-form>
      <a-table
        :data-source="tblData"
        :columns="tblColumns"
        :pagination="pagination"
        :loading="loading"
        @change="onPage"
      >
        <span slot="action" slot-scope="text, record">
          <a @click="onDetail(record)">查看详情</a>
        </span>
      </a-table>
    </div>
  </a-layout-content>
</template>

<script>
import { fetchExamineList } from "@/services/ExamineService";

const tblColumns = [
  {
    title: "申请类型",
    dataIndex: "dealerType",
    width: "15%"
  },
  {
    title: "机构名称",
    dataIndex: "dealerName",
    width: "10%"
  },
  {
    title: "统一社会信用代码",
    dataIndex: "orgCode",
    width: "20%"
  },
  {
    title: "法人姓名",
    dataIndex: "legalPerName",
    width: "10%"
  },
  {
    title: "联系人姓名",
    dataIndex: "personName",
    width: "10%"
  },
  {
    title: "联系人电话",
    dataIndex: "personTel",
    width: "15%"
  },
  {
    title: "审核结果",
    dataIndex: "auditResult",
    width: "10%"
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
    width: "10%"
  }
];
export default {
  data() {
    return {
      form: this.$form.createForm(this),
      tblData: [],
      tblColumns,
      pagination: {
        hideOnSinglePage: true
      },
      loading: false,
      typeOptions: [
        { value: "门店及陈列库", label: "门店及陈列库" },
        { value: "终端库", label: "终端库" },
        { value: "服务中心", label: "服务中心" },
        { value: "运营中心", label: "运营中心" }
      ]
    };
  },
  created() {
    this.onloadList();
  },
  mounted() {
    console.log(this.form.getFieldsValue());
  },
  methods: {
    onOrgCodeChange(e) {
      setTimeout(() => {
        this.form.setFieldsValue({ orgCode: e.target.value.toUpperCase() });
      });
    },
    onDetail(record) {
      const dealerId = record.dealerId;
      let param = {
        toAudit: false,
        pageNum: this.pagination.current,
        pageSize: this.pagination.pageSize,
        ...this.form.getFieldsValue()
      };
      param = JSON.stringify(param);
      sessionStorage.setItem("corporationQueryList", param);
      this.$router.push({
        name: "ExaminedDetail",
        params: { dealerId: dealerId }
      });
    },
    onPage(pagination) {
      this.pagination.current = pagination.current;
      const params = {
        toAudit: false,
        pageNum: this.pagination.current,
        pageSize: this.pagination.pageSize,
        ...this.form.getFieldsValue()
      };
      this.fetchList(params);
    },
    onloadList() {
      const params = {
        toAudit: false,
        pageNum: this.pagination.current ? this.pagination.current : 1,
        pageSize: this.pagination.pageSize ? this.pagination.pageSize : 15,
        dealerName: "",
        dealerType: "",
        legalPerName: "",
        orgCode: "",
        personName: "",
        personTel: ""
      };
      this.fetchList(params);
    },
    onSearch() {
      this.pagination.current = 1;
      const params = {
        toAudit: false,
        pageNum: this.pagination.current,
        pageSize: this.pagination.pageSize,
        ...this.form.getFieldsValue()
      };
      console.log(this.form.getFieldsValue());
      this.fetchList(params);
    },
    fetchList(params) {
      this.loading = true;
      fetchExamineList(params)
        .then(resp => {
          this.loading = false;
          if (resp.data.code === "SUCCESS") {
            const data = resp.data.data;
            this.tblData = data.records;
            const pagination = { ...this.pagination };
            pagination.current = data.pageNum;
            pagination.total = data.total;
            pagination.pageSize = data.pageSize;
            pagination.showTotal = function(total, range) {
              return `当前显示 ${range[0]} - ${range[1]} 条, 共 ${total} 条`;
            };
            this.pagination = pagination;
          } else {
            this.$message.error(resp.data.errorMsg);
          }
        })
        .catch(error => {
          if (error) this.loading = false;
        });
    },
    onReset() {
      this.form.resetFields();
      this.onloadList();
    }
  }
};
</script>

<style scoped>
.content-container {
  min-height: 100vh;
  background-color: white;
  margin: 30px;
}
/deep/ .ant-btn-primary {
  transform: translateY(4px);
}
/deep/ .ant-form-item .ant-form-item-label {
  text-align: right;
  min-width: 130px;
}
.row {
  display: flex;
}
.col {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
}
.btn-reset {
  margin-bottom: 8px;
}
</style>

<template>
  <a-layout-content>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>审核管理</a-breadcrumb-item>
        <a-breadcrumb-item>活动开展审核</a-breadcrumb-item>
        <a-breadcrumb-item>租赁活动</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="content-container">
      <common-search :fields="fields" @search="onSearch"></common-search>
      <div class="main-content">
        <a-table
          :data-source="tblData"
          :columns="tblColumns"
          :pagination="pagination"
          :loading="loading"
          :row-key="
            (record, index) => {
              return index;
            }
          "
          @change="onPage"
        >
          <template slot="businessVenuName" slot-scope="text, record">
            <div>
              <div>{{ record.dealerName }}</div>
              <div class="dealer-type">
                {{ record.dealerType
                }}{{ record.storeType ? "(" + record.storeType + ")" : "" }}
              </div>
            </div>
          </template>
          <template slot="activityStatusValue" slot-scope="text">
            <span
              :class="{
                yellow: text === '待审核部审核' || text === '待稽查部审核'
              }"
            >
              {{ text }}
            </span>
          </template>
          <a-space slot="action" slot-scope="text, record">
            <a
              v-if="
                (record.activityStatus === 'UN_APPROVAL_APPROVAL_DEPT' &&
                  isApprovalDept) ||
                  (record.activityStatus === 'UN_APPROVAL_CHECK_DEPT' &&
                    isCheckDept)
              "
              @click="onModify(record.activityId)"
            >
              审核
            </a>
            <a v-else @click="onDetail(record.activityId)">
              查看
            </a>
          </a-space>
        </a-table>
      </div>
    </div>
  </a-layout-content>
</template>

<script>
import { dtFormat } from "@/components/DateUtils";
import { loadArea } from "@/services/DealerService";
import { deleteArchives } from "@/services/Archives";
import { fetchActivityList } from "@/services/ActivityService";
import { removeKeepAliveCache } from "@/components/keepAlive";

const tblColumns = [
  {
    title: "任务标题",
    dataIndex: "activityTitle",
    width: "20%"
  },
  {
    title: "机构名称",
    dataIndex: "corporationName"
  },
  {
    title: "提交人（渠道类型）",
    dataIndex: "distributorTypeValue"
  },
  {
    title: "活动地区",
    dataIndex: "hostAreaName"
  },
  {
    title: "审核状态",
    dataIndex: "activityStatusValue",
    scopedSlots: { customRender: "activityStatusValue" }
  },
  {
    title: "提交时间",
    dataIndex: "lastSubmitTime"
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" }
  }
];
export default {
  data() {
    return {
      fields: [
        {
          label: "任务标题",
          key: "activityTitle",
          component: "j-input"
        },
        {
          label: "审核状态",
          key: "activityStatus",
          component: "j-select",
          options: [
            {
              label: "全部",
              value: ""
            },
            {
              label: "待审核部审核",
              value: "UN_APPROVAL_APPROVAL_DEPT"
            },
            {
              label: "待稽查部审核",
              value: "UN_APPROVAL_CHECK_DEPT"
            },
            {
              label: "未通过",
              value: "UN_PASSED"
            },
            {
              label: "已通过",
              value: "PASSED"
            },
            {
              label: "已作废",
              value: "INVALIDED"
            }
          ]
        },
        {
          label: "创建时间",
          key: "submitStartTime,submitEndTime",
          component: "j-range-picker",
          format: "YYYY-MM-DD"
        },
        {
          label: "提交人",
          key: "distributorType",
          component: "j-select",
          options: [
            {
              label: "全部",
              value: ""
            },
            {
              label: "服务中心",
              value: "serviceCenter"
            },
            {
              label: "渠道终端",
              value: "endConsumerMarket"
            }
          ]
        },
        {
          label: "机构名称",
          key: "corporationName",
          component: "j-input"
        },
        {
          label: "地区",
          key: "hostProvinceCode,hostCityCode,hostCountyCode",
          component: "j-cascader",
          fieldNames: {
            label: "areaName",
            value: "areaCode",
            children: "subAreas"
          },
          areaData: [],
          getAreaData: () => {
            return this.loadAreaData();
          }
        }
      ],
      form: {},
      tblData: [],
      hasChange: false,
      tblColumns,
      roles: [],
      isApprovalDept: false,
      isCheckDept: false,
      areaCode: [], // 选择的省市区code
      repoetAreaCode: [], // 上传的省市区code
      areaTreeData: [], // 行政区数据
      pagination: {
        hideOnSinglePage: true
      },
      loading: false,
      startTime: "",
      endTime: ""
    };
  },
  mounted() {
    this.roles = JSON.parse(localStorage.getItem("roles"));
    this.roles.forEach(item => {
      if (item === "OperationHeadquartersApprovalDept") {
        this.isApprovalDept = true;
      }
      if (item === "OperationHeadquartersCheckDept") {
        this.isCheckDept = true;
      }
    });
    this.fetchList();
  },
  beforeRouteEnter(to, from, next) {
    if (from.name === "ExaminedLeaseActivity") {
      next(vm => {
        vm.hasChange = vm.$store.state.hasChange;
      });
    } else {
      next(vm => {
        vm.hasChange = false;
      });
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === "ExaminedLeaseActivity") {
      this.$route.meta.keepAlive = true;
    } else {
      removeKeepAliveCache.call(this);
      this.$route.meta.keepAlive = true;
    }
    next();
  },
  watch: {
    hasChange(newVal, oldVal) {
      if (newVal !== oldVal && newVal) {
        this.fetchList();
        this.hasChange = false;
        this.$store.state.hasChange = false;
      }
    }
  },
  methods: {
    // 加载行政区数据
    async loadAreaData() {
      const resp = await loadArea();
      let areaData = [];
      if (resp.data.code === "SUCCESS") {
        areaData = resp.data.data;
      } else {
        this.$message.error(resp.data.errorMsg);
      }
      return areaData;
    },
    // 获取列表信息
    fetchList() {
      const params = {
        activityStatus: this.form.activityStatus
          ? this.form.activityStatus
          : "",
        activityTitle: this.form.activityTitle ? this.form.activityTitle : "",
        corporationName: this.form.corporationName
          ? this.form.corporationName
          : "",
        distributorType: this.form.distributorType
          ? this.form.distributorType
          : "",
        hostProvinceCode: this.form.hostProvinceCode
          ? this.form.hostProvinceCode
          : "",
        hostCityCode: this.form.hostCityCode ? this.form.hostCityCode : "",
        hostCountyCode: this.form.hostCountyCode
          ? this.form.hostCountyCode
          : "",
        pageNum: this.pagination.current ? this.pagination.current : 1,
        pageSize: this.pagination.pageSize ? this.pagination.pageSize : 10,
        activityType: "LEASE_ACTIVITY",
        submitStartTime: this.form.submitStartTime
          ? this.form.submitStartTime + " 00:00:00"
          : "",
        submitEndTime: this.form.submitEndTime
          ? this.form.submitEndTime + " 23:59:59"
          : ""
      };
      fetchActivityList(params).then(resp => {
        if (resp.data.code === "SUCCESS") {
          const records = resp.data.data.records;
          const data = resp.data.data;
          const pagination = { ...this.pagination };
          pagination.current = data.pageNum;
          pagination.total = data.total;
          pagination.pageSize = data.pageSize;
          pagination.showTotal = function(total, range) {
            return `当前显示 ${range[0]} - ${range[1]} 条, 共 ${total} 条`;
          };
          this.pagination = pagination;
          if (records.length > 0) {
            records.forEach(item => {
              if (item.lastSubmitTime) {
                item.lastSubmitTime = dtFormat(item.lastSubmitTime);
              } else {
                item.lastSubmitTime = "--";
              }
            });
          }
          this.tblData = records;
        }
      });
    },
    // 搜索
    onSearch(form) {
      this.form = form;
      this.pagination.current = 1;
      this.fetchList();
    },
    // 分页
    onPage(pagination) {
      this.pagination.current = pagination.current;
      this.fetchList();
    },
    // 查看档案详情
    onDetail(id) {
      let param = {
        activityStatus: this.form.activityStatus
          ? this.form.activityStatus
          : "",
        pageNum: this.pagination.current,
        pageSize: this.pagination.pageSize,
        activityType: "LEASE_ACTIVITY",
        activityTitle: this.form.activityTitle ? this.form.activityTitle : "",
        corporationName: this.form.corporationName
          ? this.form.corporationName
          : "",
        distributorType: this.form.distributorType
          ? this.form.distributorType
          : "",
        hostProvinceCode: this.form.hostProvinceCode
          ? this.form.hostProvinceCode
          : "",
        hostCityCode: this.form.hostCityCode ? this.form.hostCityCode : "",
        hostCountyCode: this.form.hostCountyCode
          ? this.form.hostCountyCode
          : "",
        submitStartTime: this.form.submitStartTime
          ? this.form.submitStartTime + " 00:00:00"
          : "",
        submitEndTime: this.form.submitEndTime
          ? this.form.submitEndTime + " 23:59:59"
          : ""
      };
      param = JSON.stringify(param);
      sessionStorage.setItem("rideList", param);
      this.$router.push({
        name: "ExaminedLeaseActivity",
        params: {
          id: id,
          action: "查看"
        }
      });
    },
    // 审核试骑活动
    onModify(id) {
      let param = {
        activityStatus: this.form.activityStatus
          ? this.form.activityStatus
          : "",
        pageNum: this.pagination.current,
        pageSize: this.pagination.pageSize,
        activityType: "LEASE_ACTIVITY",
        activityTitle: this.form.activityTitle ? this.form.activityTitle : "",
        corporationName: this.form.corporationName
          ? this.form.corporationName
          : "",
        distributorType: this.form.distributorType
          ? this.form.distributorType
          : "",
        hostProvinceCode: this.form.hostProvinceCode
          ? this.form.hostProvinceCode
          : "",
        hostCityCode: this.form.hostCityCode ? this.form.hostCityCode : "",
        hostCountyCode: this.form.hostCountyCode
          ? this.form.hostCountyCode
          : "",
        submitStartTime: this.form.submitStartTime
          ? this.form.submitStartTime + " 00:00:00"
          : "",
        submitEndTime: this.form.submitEndTime
          ? this.form.submitEndTime + " 23:59:59"
          : ""
      };
      param = JSON.stringify(param);
      sessionStorage.setItem("rideList", param);
      this.$router.push({
        name: "ExaminedLeaseActivity",
        params: {
          id: id,
          action: "审核"
        }
      });
    },
    // 删除
    onDelete(id) {
      deleteArchives(id).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.$message.success("删除成功");
          this.fetchList();
        } else {
          this.$message.error(resp.data.errorMsg);
        }
      });
    }
  }
};
</script>

<style scoped>
.content-container {
  min-height: 1000px;
  background-color: white;
  margin: 30px;
}

/deep/ .advanced-search-form .ant-form-item {
  display: flex;
}

/deep/ .ant-form-item .ant-form-item-control-wrapper {
  flex: 1;
}

/deep/ .ant-form-item .ant-form-item-label {
  text-align: right;
  min-width: 100px;
}

.row {
  display: flex;
}

.col {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
}

.btn-reset {
  margin-bottom: 8px;
}

.dealer-type {
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
}

.search__btn {
  padding-left: 58px;
}

.search__btn button {
  margin-right: 8px;
}
.yellow {
  color: rgb(238, 132, 37);
}
.green {
  color: rgb(100, 198, 114);
}
.red {
  color: rgb(252, 88, 92);
}
.gray {
  color: rgb(151, 151, 151);
}
</style>

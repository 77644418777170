<template>
  <a-layout-content>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>审核管理</a-breadcrumb-item>
        <a-breadcrumb-item>价值包申报审核</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="content-container">
      <common-search :fields="fields" @search="onSearch"></common-search>
      <div class="main-content">
        <a-table
          :data-source="tblData"
          :columns="tblColumns"
          :pagination="pagination"
          :loading="loading"
          :row-key="
            (record, index) => {
              return index;
            }
          "
          @change="onPage"
        >
          <template slot="skuName" slot-scope="text">
            <div class="exile">
              {{ text }}
            </div>
          </template>
          <template slot="areaName" slot-scope="text">
            <div class="exile">
              {{ text }}
            </div>
          </template>
          <template slot="businessVenuName" slot-scope="text, record">
            <div>
              <div>{{ record.dealerName }}</div>
              <div class="dealer-type">
                {{ record.dealerType
                }}{{ record.storeType ? "(" + record.storeType + ")" : "" }}
              </div>
            </div>
          </template>
          <template slot="status" slot-scope="text">
            <span
              :class="{
                yellow: text === '待审核'
              }"
            >
              {{ text }}
            </span>
          </template>
          <a-space slot="action" slot-scope="text, record">
            <a
              v-if="record.status !== '待审核'"
              @click="onDetail(record.strategyId)"
            >
              查看
            </a>
            <a v-else @click="onModify(record.strategyId)">审核</a>
          </a-space>
        </a-table>
      </div>
    </div>
  </a-layout-content>
</template>

<script>
import { dtFormat } from "@/components/DateUtils";
import { loadArea } from "@/services/DealerService";
import { deleteArchives, fetchAllOperationCenter } from "@/services/Archives";
import { fetchStrategyApplyList } from "@/services/ValueBag";
import { removeKeepAliveCache } from "@/components/keepAlive";

const tblColumns = [
  {
    title: "任务标题",
    dataIndex: "strategyName",
    width: "15%"
  },
  {
    title: "产品/商品",
    dataIndex: "skuName",
    scopedSlots: { customRender: "skuName" },
    width: "25%"
  },
  {
    title: "运营中心名称",
    dataIndex: "corporationName",
    width: "5%"
  },
  {
    title: "作用地区",
    dataIndex: "areaName",
    scopedSlots: { customRender: "areaName" },
    width: "25%"
  },
  {
    title: "生效时间",
    dataIndex: "time",
    width: "15%"
  },
  {
    title: "审核状态",
    dataIndex: "status",
    scopedSlots: { customRender: "status" },
    width: "10%"
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
    width: "5%"
  }
];
export default {
  data() {
    return {
      fields: [
        {
          label: "任务标题",
          key: "strategyName",
          component: "j-input"
        },
        {
          label: "运营中心",
          key: "corporationId",
          component: "j-select",
          getOptions: () => {
            return this.onLoadOperationCenter();
          }
        },
        {
          label: "生效时间",
          key: "startTime,endTime",
          component: "j-range-picker",
          format: "YYYY-MM-DD"
        },
        {
          label: "审核状态",
          key: "status",
          defaultValue: "",
          component: "j-select",
          options: [
            {
              label: "全部",
              value: ""
            },
            {
              label: "待审核",
              value: "UN_APPROVAL"
            },
            {
              label: "未通过",
              value: "UN_PASSED"
            },
            {
              label: "已通过",
              value: "PASSED"
            },
            {
              label: "已作废",
              value: "INVALIDED"
            },
            {
              label: "已失效",
              value: "FAILURE"
            }
          ]
        },
        {
          label: "地区",
          key: "provinceCode,cityCode,areaCode",
          component: "j-cascader",
          fieldNames: {
            label: "areaName",
            value: "areaCode",
            children: "subAreas"
          },
          areaData: [],
          getAreaData: () => {
            return this.loadAreaData();
          }
        }
      ],
      form: this.$form.createForm(this),
      tblData: [],
      tblColumns,
      hasChange: false,
      strategyName: "",
      formData: {},
      operationCenterData: [],
      areaCode: [], // 选择的省市区code
      repoetAreaCode: [], // 上传的省市区code
      areaTreeData: [], // 行政区数据
      pagination: {
        hideOnSinglePage: true
      },
      loading: false,
      startTime: "",
      endTime: ""
    };
  },
  beforeRouteEnter(to, from, next) {
    if (from.name === "ValueExaminedDetail") {
      next(vm => {
        vm.hasChange = vm.$store.state.hasChange;
      });
    } else {
      next(vm => {
        vm.hasChange = false;
      });
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === "ValueExaminedDetail") {
      this.$route.meta.keepAlive = true;
    } else {
      removeKeepAliveCache.call(this);
      this.$route.meta.keepAlive = true;
    }
    next();
  },
  watch: {
    hasChange(newVal, oldVal) {
      if (newVal !== oldVal && newVal) {
        this.fetchList();
        this.hasChange = false;
        this.$store.state.hasChange = false;
      }
    }
  },
  mounted() {
    this.fetchList();
    this.form.strategyName = 1;
  },
  methods: {
    // 加载行政区数据
    async loadAreaData() {
      const resp = await loadArea();
      if (resp.data.code === "SUCCESS") {
        this.areaData = resp.data.data;
      } else {
        this.$message.error(resp.data.errorMsg);
      }
      return this.areaData;
    },
    // 获取运营中心列表
    async onLoadOperationCenter() {
      this.operationCenterData = [];
      const resp = await fetchAllOperationCenter();
      if (resp.data.code === "SUCCESS") {
        resp.data.data.forEach(item => {
          this.operationCenterData.push({
            label: item.corporationName,
            value: item.corporationId
          });
        });
      } else {
        this.$message.error(resp.data.errorMsg);
      }
      return this.operationCenterData;
    },
    // 获取列表信息
    fetchList() {
      const params = {
        strategyName: this.form.strategyName ? this.form.strategyName : "",
        status: this.form.status ? this.form.status : "",
        startTime: this.form.startTime ? this.form.startTime + " 00:00:00" : "",
        endTime: this.form.endTime ? this.form.endTime + " 23:59:59" : "",
        corporationId: this.form.corporationId ? this.form.corporationId : "",
        pageNum: this.pagination.current ? this.pagination.current : 1,
        pageSize: this.pagination.pageSize ? this.pagination.pageSize : 10,
        apply: true,
        areaCode: this.form.areaCode
          ? this.form.areaCode
          : this.form.cityCode
          ? this.form.cityCode
          : this.form.provinceCode
          ? this.form.provinceCode
          : ""
      };
      fetchStrategyApplyList(params).then(resp => {
        if (resp.data.code === "SUCCESS") {
          const records = resp.data.data.records;
          const data = resp.data.data;
          records.forEach(item => {
            const areaList = [];
            if (item.areaList && item.areaList.length > 0) {
              item.areaList.forEach(ite => {
                areaList.push(ite.areaName);
              });
              item.areaName = areaList.join(",");
            }
            if (item.startTime && item.endTime) {
              item.time =
                dtFormat(item.startTime) + "至" + dtFormat(item.endTime);
            } else {
              item.time = "--";
            }
            if (item.endTime) {
              item.endTime = dtFormat(item.endTime);
            } else {
              item.endTime = "暂无数据";
            }
            if (item.status === "NORMAL") {
              item.status = "启用";
            }
            if (item.status === "DISABLE") {
              item.status = "禁用";
            }
            if (item.status === "UN_APPROVAL") {
              item.status = "待审核";
            }
            if (item.status === "PASSED") {
              item.status = "已通过";
            }
            if (item.status === "UN_PASSED") {
              item.status = "未通过";
            }
            if (item.status === "INVALIDED") {
              item.status = "已作废";
            }
            if (item.status === "FAILURE") {
              item.status = "已失效";
            }
          });
          const pagination = { ...this.pagination };
          pagination.current = data.pageNum;
          pagination.total = data.total;
          pagination.pageSize = data.pageSize;
          pagination.showTotal = function(total, range) {
            return `当前显示 ${range[0]} - ${range[1]} 条, 共 ${total} 条`;
          };
          this.pagination = pagination;
          if (records.length > 0) {
            records.forEach(item => {
              item.createTime = dtFormat(item.createTime);
            });
          }
          this.tblData = records;
          this.tblData.forEach(item => {
            item.skuName = [];
            item.midList.forEach(ite => {
              const name = `${ite.productName}/${ite.skuName}`.toString();
              item.skuName.push(name);
            });
            item.skuName = item.skuName.join(",");
          });
        }
      });
    },
    // 搜索
    onSearch(form) {
      this.form = form;
      this.pagination.current = 1;
      this.fetchList();
    },
    // 分页
    onPage(pagination) {
      this.pagination.current = pagination.current;
      this.fetchList();
    },
    // 查看档案详情
    onDetail(id) {
      let params = {
        strategyName: this.form.strategyName,
        status: this.form.status ? this.form.status : "",
        startTime: this.form.startTime ? this.form.startTime + " 00:00:00" : "",
        endTime: this.form.endTime ? this.form.endTime + " 23:59:59" : "",
        corporationId: "",
        pageNum: this.pagination.current ? this.pagination.current : 1,
        pageSize: this.pagination.pageSize ? this.pagination.pageSize : 10,
        apply: true
      };
      if (this.areaCode) {
        if (this.areaCode.length === 0) {
          params.areaCode = "";
        }
        if (this.areaCode.length >= 1) {
          params.areaCode = this.areaCode[0];
        }
        if (this.areaCode.length >= 2) {
          params.areaCode = this.areaCode[1];
        }
        if (this.areaCode.length >= 3) {
          params.areaCode = this.areaCode[2];
        }
      } else {
        params.areaCode = "";
      }
      params = JSON.stringify(params);
      sessionStorage.setItem("valueList", params);
      this.$router.push({
        name: "ValueExaminedDetail",
        params: {
          id: id,
          action: "查看"
        }
      });
    },
    // 审核试骑活动
    onModify(id) {
      let params = {
        strategyName: this.form.strategyName,
        status: this.form.status ? this.form.status : "",
        startTime: this.form.startTime ? this.form.startTime + " 00:00:00" : "",
        endTime: this.form.endTime ? this.form.endTime + " 23:59:59" : "",
        corporationId: "",
        pageNum: this.pagination.current ? this.pagination.current : 1,
        pageSize: this.pagination.pageSize ? this.pagination.pageSize : 10,
        apply: true
      };
      if (this.areaCode) {
        if (this.areaCode.length === 0) {
          params.areaCode = "";
        }
        if (this.areaCode.length >= 1) {
          params.areaCode = this.areaCode[0];
        }
        if (this.areaCode.length >= 2) {
          params.areaCode = this.areaCode[1];
        }
        if (this.areaCode.length >= 3) {
          params.areaCode = this.areaCode[2];
        }
      } else {
        params.areaCode = "";
      }
      params = JSON.stringify(params);
      sessionStorage.setItem("valueList", params);
      this.$router.push({
        name: "ValueExaminedDetail",
        params: {
          id: id,
          action: "审核"
        }
      });
    },
    // 删除
    onDelete(id) {
      deleteArchives(id).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.$message.success("删除成功");
          this.fetchList();
        } else {
          this.$message.error(resp.data.errorMsg);
        }
      });
    }
  }
};
</script>

<style scoped>
.content-container {
  min-height: 1000px;
  background-color: white;
  margin: 30px;
}

/deep/ .advanced-search-form .ant-form-item {
  display: flex;
}

/deep/ .ant-form-item .ant-form-item-control-wrapper {
  flex: 1;
}

/deep/ .ant-form-item .ant-form-item-label {
  text-align: right;
  min-width: 100px;
}

.row {
  display: flex;
}

.col {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
}

.btn-reset {
  margin-bottom: 8px;
}

.dealer-type {
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
}

.search__btn {
  padding-left: 31px;
}

.search__btn button {
  margin-right: 8px;
}

.exile {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.yellow {
  color: rgb(238, 132, 37);
}
.green {
  color: rgb(100, 198, 114);
}
.red {
  color: rgb(252, 88, 92);
}
.gray {
  color: rgb(151, 151, 151);
}
</style>

<template>
  <a-layout-content>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>产品管理</a-breadcrumb-item>
        <a-breadcrumb-item>商品序列号管理</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="content-container">
      <common-search :fields="fields" @search="onSearch"></common-search>
      <div class="main-content">
        <div class="action" v-if="!isHeadquarters">
          <a-upload
            name="file"
            accept=".xlsx,xls"
            :multiple="false"
            :action="`${baseURL}/product/serial/import`"
            :headers="headers"
            @change="onFileStatusChange"
            :showUploadList="false"
            style="display: none"
          >
            <a-button type="primary" class="btn-output">
              <a-icon />
              导入
            </a-button>
          </a-upload>
          <a-button type="white" v-if="hasAllModify" @click="onExport">
            导出
          </a-button>
          <a-button type="white" @click="onExportAll">
            全部导出
          </a-button>
          <a-button
            type="white"
            class="float"
            v-if="hasAllDelete"
            @click="onDeleteAll"
          >
            批量删除
          </a-button>
          <a-button
            type="link"
            icon="download"
            :href="downloadTemplate"
            download="商品序列号导入模板.xlsx"
            style="display: none"
          >
            下载导入模板
          </a-button>
        </div>
        <div class="action" v-else>
          <a-button v-if="hasAllModify" type="primary" @click="onExport">
            导出
          </a-button>
          <a-button type="white" @click="onExportAll">
            全部导出
          </a-button>
        </div>
        <a-table
          :data-source="tblData"
          :columns="isHeadquarters ? columns : tblColumns"
          :pagination="pagination"
          :row-selection="rowSelection"
          @change="onPage"
          :row-key="
            (record, index) => {
              return index;
            }
          "
        >
          <div
            slot="productSerialNo"
            slot-scope="text, record"
            class="modify__No"
          >
            <span v-if="!record.isCheck">{{ text }}</span>
            <span v-if="record.isCheck && record.goodsStatusValue === '未出库'">
              <a-input type="text" v-model="record.productSerialNo" />
            </span>
            <span
              @click="
                record.isCheck = true;
                tblData = [...tblData];
              "
              v-if="!record.isCheck && record.goodsStatusValue === '未出库'"
            >
              <a-icon type="edit" />
            </span>
            <span
              v-if="record.isCheck && record.goodsStatusValue === '未出库'"
              @click="onModifySerialNo(record)"
            >
              <a-icon type="check" />
            </span>
          </div>
          <div
            slot="skuImg"
            slot-scope="text, record"
            v-viewer
            class="product-img-container"
          >
            <div v-if="record.file" class="product-img-container">
              <img
                v-if="record.file.fileUrl"
                :src="imageBaseUrl + record.file.fileUrl"
                :data-source="imageBaseUrl + record.file.fileUrl"
                alt="商品图片"
              />
            </div>
          </div>
          <a-space slot="action" slot-scope="text, record">
            <a-popconfirm
              title="是否确认删除?"
              ok-text="确认"
              cancel-text="取消"
              @confirm="onDelete(record.goodsId)"
            >
              <a v-if="record.goodsStatusValue === '未出库'">
                删除
              </a>
            </a-popconfirm>
          </a-space>
        </a-table>
      </div>
    </div>
    <a-modal title="修改商品信息" :visible="false" @cancel="visible = false">
      <a-form
        :form="editForm"
        class="advanced-search-form advanced-search-forms"
      >
        <a-row>
          <a-col :span="10">
            <a-form-item label="产品类型">
              <a-select v-decorator="['productType']" allow-clear>
                <a-select-option value="">
                  全部
                </a-select-option>
                <a-select-option value="整车">
                  整车
                </a-select-option>
                <a-select-option value="配件">
                  配件
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="10">
            <a-form-item label="商品名称" class="form-item">
              <a-input
                placeholder="请输入商品名称"
                v-decorator="['productName']"
                allow-clear
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="10">
            <a-form-item label="商品编码" class="form-item">
              <a-input
                placeholder="请输入商品编码"
                v-decorator="['productCode']"
                allow-clear
              />
            </a-form-item>
          </a-col>
          <a-col :span="10">
            <a-form-item label="商品序列号" class="form-item">
              <a-input
                placeholder="请输入商品序列号"
                v-decorator="['productSerialNo']"
                allow-clear
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="10">
            <a-form-item label="入库时间" class="form-item">
              <a-input
                placeholder="请输入用户名"
                v-decorator="[
                  'userName',
                  {
                    rules: [{ required: true, message: '请输入项目名称' }]
                  }
                ]"
                allow-clear
              />
            </a-form-item>
          </a-col>
          <a-col :span="10">
            <a-form-item label="商品状态" class="form-item">
              <a-input
                placeholder="请输入操作系统"
                v-decorator="[
                  'operatingSystem',
                  {
                    rules: [{ required: true, message: '请输入操作系统' }]
                  }
                ]"
                allow-clear
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="10">
            <a-form-item label="出库时间" class="form-item">
              <a-input
                placeholder="请输入用户名"
                v-decorator="[
                  'userName',
                  {
                    rules: [{ required: true, message: '请输入项目名称' }]
                  }
                ]"
                allow-clear
              />
            </a-form-item>
          </a-col>
          <a-col :span="10">
            <a-form-item label="最新修改" class="form-item">
              <a-input
                placeholder="请输入操作系统"
                v-decorator="[
                  'operatingSystem',
                  {
                    rules: [{ required: true, message: '请输入操作系统' }]
                  }
                ]"
                allow-clear
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </a-layout-content>
</template>

<script>
import { tsFormat } from "@/components/DateUtils";
import { loadArea } from "@/services/DealerService";
import {
  deleteMoreSkuSerial,
  deleteOnceSkuSerial,
  exportHeadquartersSerial,
  exportHeadquartersSerialAll,
  exportSerial,
  exportSerialAll,
  fetchOperationsSerialList,
  fetchProductSerialList,
  modifySkuSerial
} from "@/services/ProductService";
import { baseURL, imageBaseUrl } from "@/services/HttpService";
import { buildHeaders } from "@/components/utils";
import { fetchCorporationList } from "@/services/ActivityService";

const tblColumns = [
  {
    title: "商品图",
    dataIndex: "skuImg",
    scopedSlots: { customRender: "skuImg" },
    width: "10%"
  },
  {
    title: "商品名称",
    dataIndex: "skuName"
  },
  {
    title: "商品编码",
    dataIndex: "skuCode"
  },
  {
    title: "序列号",
    dataIndex: "productSerialNo",
    scopedSlots: { customRender: "productSerialNo" },
    width: 200
  },
  {
    title: "导入时间",
    dataIndex: "importTime"
  },
  {
    title: "商品状态",
    dataIndex: "goodsStatusValue"
  },
  {
    title: "出库时间",
    dataIndex: "deliveryTime"
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" }
  }
];
const columns = [
  {
    title: "商品图",
    dataIndex: "skuImg",
    scopedSlots: { customRender: "skuImg" },
    width: "10%"
  },
  {
    title: "商品名称",
    dataIndex: "skuName"
  },
  {
    title: "商品编码",
    dataIndex: "skuCode"
  },
  {
    title: "序列号",
    dataIndex: "productSerialNo"
  },
  {
    title: "所在机构",
    dataIndex: "organizationName"
  },
  {
    title: "原厂导入时间",
    dataIndex: "importTime"
  },
  {
    title: "原厂出库时间",
    dataIndex: "deliveryTime"
  },
  {
    title: "销售状态",
    dataIndex: "goodsStatusValue"
  }
];
export default {
  data() {
    return {
      fields: [
        {
          label: "产品类型",
          key: "productType",
          component: "j-select",
          options: [
            { value: "", label: "全部" },
            { value: "整车", label: "整车" },
            { value: "配件", label: "配件" }
          ]
        },
        {
          label: "产品名称",
          key: "productName",
          component: "j-input"
        },
        {
          label: "商品编码",
          key: "productCode",
          component: "j-input"
        },
        {
          label: "商品序列号",
          key: "productSerialNo",
          component: "j-input"
        },
        {
          label: "销售状态",
          key: "productStatus",
          component: "j-select",
          options: [
            { value: "", label: "全部" },
            { value: "true", label: "已销售" },
            { value: "false", label: "未销售" }
          ]
        }
      ],
      form: {},
      editForm: this.$form.createForm(this),
      tblData: [],
      imageBaseUrl,
      tblColumns,
      organizationList: [],
      columns,
      visible: false,
      areaCode: [], // 选择的省市区code
      reportAreaCode: [], // 上传的省市区code
      areaTreeData: [], // 行政区数据
      selectedRows: [],
      isHeadquarters: false,
      selectedRowKeys: [],
      buildHeaders,
      downloadTemplate: `${process.env.BASE_URL}skuSerial.xlsx`,
      baseURL,
      headers: buildHeaders(),
      hasAllDelete: false,
      hasAllModify: false,
      pagination: {
        hideOnSinglePage: true
      },
      loading: false,
      startTime: "",
      endTime: ""
    };
  },
  computed: {
    rowSelection() {
      const { selectedRows } = this;
      const { selectedRowKeys } = this;
      return {
        selectedRows,
        selectedRowKeys,
        onChange: this.onSelectChange,
        getCheckboxProps: record => ({
          props: {
            disabled: record.goodsStatusValue === "已出库"
          }
        })
      };
    }
  },
  created() {
    //FactoryAdmin
    //OperationHeadquartersAdmin
    const role = JSON.parse(localStorage.getItem("roles"));
    if (
      role &&
      role.length > 0 &&
      role.findIndex(item => item === "OperationHeadquartersAdmin") > -1
    ) {
      this.isHeadquarters = true;
    }
    if (this.isHeadquarters) {
      this.fields.push({
        label: "所在机构",
        key: "distributorId",
        component: "j-select-search",
        options: [],
        getOptions: () => {
          return this.onLoadCorporation();
        }
      });
    } else {
      this.fields.push({
        label: "出库时间",
        key: "startTime,endTime",
        component: "j-range-picker",
        format: "YYYY-MM-DD"
      });
    }
  },
  mounted() {
    this.fetchList();
    this.onLoadArea();
  },
  methods: {
    //加载机构信息
    async onLoadCorporation() {
      const resp = await fetchCorporationList("serialNo");
      if (resp.data.code === "SUCCESS") {
        resp.data.data.forEach(item => {
          this.organizationList.push({
            label: `${item.corporationName}(${item.distributorTypeValue})`,
            value: item.distributorId
          });
        });
      }
      return this.organizationList;
    },
    onModifySerialNo(record) {
      modifySkuSerial(record.goodsId, record.productSerialNo).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.$message.success("修改成功");
          this.fetchList();
        }
      });
    },
    // 获取列表信息
    fetchList() {
      const params = {
        ...this.form,
        pageNum: this.pagination.current ? this.pagination.current : 1,
        pageSize: this.pagination.pageSize ? this.pagination.pageSize : 10,
        startTime: this.form.startTime ? this.form.startTime + " 00:00:00" : "",
        endTime: this.form.endTime ? this.form.endTime + " 23:59:59" : ""
      };
      if (this.isHeadquarters) {
        fetchOperationsSerialList(params).then(resp => {
          if (resp.data.code === "SUCCESS") {
            const records = resp.data.data.records;
            const data = resp.data.data;
            const pagination = { ...this.pagination };
            pagination.current = data.pageNum;
            pagination.total = data.total;
            pagination.pageSize = data.pageSize;
            pagination.showTotal = function(total, range) {
              return `当前显示 ${range[0]} - ${range[1]} 条, 共 ${total} 条`;
            };
            this.pagination = pagination;
            if (records.length > 0) {
              records.forEach(item => {
                if (item.importTime) {
                  item.importTime = tsFormat(item.importTime);
                } else {
                  item.importTime = "--";
                }
                if (!item.productSerialNo) {
                  item.productSerialNo = "--";
                }
                if (item.deliveryTime) {
                  item.deliveryTime = tsFormat(item.deliveryTime);
                } else {
                  item.deliveryTime = "--";
                }
              });
            }
            this.tblData = records;
          }
        });
      } else {
        fetchProductSerialList(params).then(resp => {
          if (resp.data.code === "SUCCESS") {
            const records = resp.data.data.records;
            const data = resp.data.data;
            const pagination = { ...this.pagination };
            pagination.current = data.pageNum;
            pagination.total = data.total;
            pagination.pageSize = data.pageSize;
            pagination.showTotal = function(total, range) {
              return `当前显示 ${range[0]} - ${range[1]} 条, 共 ${total} 条`;
            };
            this.pagination = pagination;
            if (records.length > 0) {
              records.forEach(item => {
                if (item.importTime) {
                  item.importTime = tsFormat(item.importTime);
                } else {
                  item.importTime = "--";
                }
                if (!item.productSerialNo) {
                  item.productSerialNo = "--";
                }
                if (item.deliveryTime) {
                  item.deliveryTime = tsFormat(item.deliveryTime);
                } else {
                  item.deliveryTime = "--";
                }
              });
            }
            this.tblData = records;
            this.tblData.forEach(item => {
              item.isCheck = false;
            });
          }
        });
      }
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
      if (selectedRows.length > 1) {
        this.hasAllDelete = true;
      } else {
        this.hasAllDelete = false;
      }
      if (selectedRows.length > 0) {
        this.hasAllModify = true;
      } else {
        this.hasAllModify = false;
      }
    },
    onFileStatusChange(info) {
      if (info.file.status === "done") {
        if (info.file.response.code === "SUCCESS") {
          this.$message.success("导入成功");
          this.onSearch();
        } else {
          this.impErrData = info.file.response.errorMsg;
          this.errModalVisible = true;

          this.$message.error("导入失败");
        }
      } else if (info.file.status === "error") {
        this.$message.error("导入失败");
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // 全部导出
    onExportAll() {
      const params = {
        ...this.form,
        startTime: this.startTime,
        endTime: this.endTime
      };
      if (this.isHeadquarters) {
        exportHeadquartersSerialAll(params).then(resp => {
          if (resp.status === 200) {
            this.$message.success("导出成功");
            var fileURL = window.URL.createObjectURL(new Blob([resp.data]));
            var fURL = document.createElement("a");
            fURL.href = fileURL;
            fURL.setAttribute("download", "商品序列号数据统计.xlsx");
            document.body.appendChild(fURL);

            fURL.click();
            this.selectedRowKeys = [];
            this.selectedRows = [];
            this.hasAllDelete = false;
            this.hasAllModify = false;
          } else {
            this.$message.error(resp.data.errorMsg);
          }
        });
      } else {
        exportSerialAll(params).then(resp => {
          if (resp.status === 200) {
            this.$message.success("导出成功");
            var fileURL = window.URL.createObjectURL(new Blob([resp.data]));
            var fURL = document.createElement("a");
            fURL.href = fileURL;
            fURL.setAttribute("download", "商品序列号数据统计.xlsx");
            document.body.appendChild(fURL);

            fURL.click();
            this.selectedRowKeys = [];
            this.selectedRows = [];
            this.hasAllDelete = false;
            this.hasAllModify = false;
          } else {
            this.$message.error(resp.data.errorMsg);
          }
        });
      }
    },
    // 批量导出
    onExport() {
      let goodsIdList = [];

      this.selectedRows.forEach(item => {
        goodsIdList.push(item.goodsId);
      });
      if (this.isHeadquarters) {
        exportHeadquartersSerial(goodsIdList).then(resp => {
          if (resp.status === 200) {
            this.$message.success("导出成功");
            var fileURL = window.URL.createObjectURL(new Blob([resp.data]));
            var fURL = document.createElement("a");
            fURL.href = fileURL;
            fURL.setAttribute("download", "商品序列号数据统计.xlsx");
            document.body.appendChild(fURL);

            fURL.click();
            this.selectedRowKeys = [];
            this.selectedRows = [];
            this.hasAllDelete = false;
            this.hasAllModify = false;
          } else {
            this.$message.error(resp.data.errorMsg);
          }
        });
      } else {
        exportSerial(goodsIdList).then(resp => {
          if (resp.status === 200) {
            this.$message.success("导出成功");
            var fileURL = window.URL.createObjectURL(new Blob([resp.data]));
            var fURL = document.createElement("a");
            fURL.href = fileURL;
            fURL.setAttribute("download", "商品序列号数据统计.xlsx");
            document.body.appendChild(fURL);

            fURL.click();
            this.selectedRowKeys = [];
            this.selectedRows = [];
            this.hasAllDelete = false;
            this.hasAllModify = false;
          } else {
            this.$message.error(resp.data.errorMsg);
          }
        });
      }
    },
    //批量删除
    onDeleteAll() {
      let goodsIdList = [];

      this.selectedRows.forEach(item => {
        goodsIdList.push(item.goodsId);
      });
      let params = {
        data: goodsIdList
      };
      deleteMoreSkuSerial(params).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.$message.success("删除成功");
          this.selectedRowKeys = [];
          this.selectedRows = [];
          this.hasAllDelete = false;
          this.hasAllModify = false;
          this.fetchList();
        } else {
          this.$message.error(resp.data.errorMsg);
        }
      });
    },
    // 加载行政区数据
    onLoadArea() {
      this.areaTreeData = [];
      loadArea().then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.areaTreeData = resp.data.data;
        }
      });
    },
    // 收货行政区域选择触发
    onChangePer(value, selectOptions) {
      this.areaCode = value;
      this.reportAreaCode = value[2];
      this.areaValue = "";
      if (selectOptions && selectOptions.length > 0) {
        selectOptions.forEach(item => {
          this.areaValue = `${this.areaValue} ${item.areaName}`;
        });
        const arrValue = this.areaValue.split(" ");
        this.areaValues = arrValue;
        if (arrValue[2] === arrValue[1]) {
          arrValue.splice(1, 1);
          this.areaValue = arrValue.join(" ");
        }
      }
    },
    // 搜索
    onSearch(form) {
      this.form = form;
      this.selectedRowKeys = [];
      this.selectedRows = [];
      this.pagination.current = 1;
      this.fetchList();
    },
    // 分页
    onPage(pagination) {
      this.pagination.current = pagination.current;
      this.fetchList();
    },
    // 删除
    onDelete(id) {
      deleteOnceSkuSerial(id).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.$message.success("删除成功");
          this.selectedRowKeys = [];
          this.selectedRows = [];
          this.hasAllDelete = false;
          this.hasAllModify = false;
          this.fetchList();
        } else {
          this.$message.error(resp.data.errorMsg);
        }
      });
    }
  }
};
</script>

<style scoped>
.content-container {
  min-height: 1000px;
  background-color: white;
  margin: 30px;
}

/deep/ .advanced-search-form .ant-form-item {
  display: flex;
}

/deep/ .ant-form-item .ant-form-item-control-wrapper {
  flex: 1;
}

/deep/ .ant-form-item .ant-form-item-label {
  text-align: right;
  min-width: 100px;
}

.row {
  display: flex;
}

.col {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
}

.btn-reset {
  margin-bottom: 8px;
}

.dealer-type {
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
}

.search__btn {
  padding-left: 58px;
}

.search__btn button {
  margin-right: 8px;
}
.action {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.action button {
  margin-right: 8px;
}
.action .float {
  border-color: rgb(252, 88, 92);
  color: rgb(252, 88, 92);
}
.product-img-container {
  width: 100px;
  height: 100px;
}
.product-img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.btn-operation {
  cursor: pointer;
}
.col {
  flex: 1;
  display: flex;
  align-items: center;
}
/deep/.ant-space-item {
  white-space: nowrap;
}
/deep/.ant-table-thead > tr > th {
  white-space: nowrap;
}
/deep/.ant-modal {
  width: 720px !important;
}
.advanced-search-forms/deep/ .ant-row {
  display: flex;
  justify-content: space-between;
}
.advanced-search-forms/deep/ .ant-row .ant-col-10 {
  flex: 1;
}
.advanced-search-forms/deep/ .ant-row .ant-col-10:first-child {
  margin-right: 30px;
}
.advanced-search-forms/deep/
  .ant-row
  .ant-col
  .ant-form-item
  .ant-form-item-label {
  min-width: 81px;
}
.modify__No {
  display: flex;
  align-items: center;
}
.modify__No span {
  margin-right: 8px;
}
</style>

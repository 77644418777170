<template>
  <a-layout-content>
    <div class="nav">
      <Breadcrumb />
    </div>
    <div class="content-container">
      <common-search :fields="fields" @search="search"></common-search>
      <a-table
        :data-source="tblData"
        :columns="tblColumns"
        :pagination="pagination"
        :loading="loading"
        row-key="applicationId"
        @change="onPage"
      >
        <template slot="auditServiceCenterName" slot-scope="text">
          {{ text ? text : "-" }}
        </template>
        <template slot="auditOperationCenterName" slot-scope="text">
          {{ text ? text : "-" }}
        </template>
        <template slot="processStatus" slot-scope="text">
          {{ text === "待运营总部审核" ? "待审核" : text }}
        </template>
        <template slot="contractStatus" slot-scope="text">
          {{ text ? text : "-" }}
        </template>
        <template slot="applicationTime" slot-scope="text">
          {{ text ? dtFormat(text) : "-" }}
        </template>
        <span slot="action" slot-scope="text, record">
          <a-space>
            <a
              v-if="
                record.processStatus === '未通过' ||
                  record.processStatus === '已取消' ||
                  (record.processStatus === '已通过' &&
                    (record.contractStatus === '待签署' ||
                      record.contractStatus === '已签署')) ||
                  record.processStatus === '待运营中心审核' ||
                  record.processStatus === '待服务中心审核'
              "
              @click="onDetail(record)"
            >
              查看详情
            </a>
            <a
              v-if="
                record.processStatus === '已通过' &&
                  record.contractStatus === '待签署'
              "
              @click="onCancel(record)"
            >
              协议取消
            </a>
            <a
              v-if="record.processStatus === '待运营总部审核'"
              @click="onDetail(record)"
            >
              准入审核
            </a>
            <a
              v-if="
                record.contractStatus === '已取消' ||
                  record.contractStatus === '已到期' ||
                  record.contractStatus === '已截止签署'
              "
              @click="onInitiateAgreement(record)"
            >
              协议发起
            </a>
          </a-space>
        </span>
      </a-table>
    </div>
  </a-layout-content>
</template>

<script>
import { corporationApplyList } from "@/services/ExamineService";
import {
  fetchSpecifyTypeCorporation,
  loadArea
} from "@/services/DealerService";
import Breadcrumb from "@/components/Breadcrumb";
import { dtFormat } from "@/components/DateUtils";
import { pageDetailMixins } from "@/components/pageDetailMixin";
import { cancelAgreement } from "@/services/AgreementService";

const tblColumns = [
  {
    title: "机构名称",
    dataIndex: "corporationName",
    width: 120
  },
  {
    title: "渠道类型",
    dataIndex: "distributorTypeName",
    width: 100
  },
  {
    title: "所在服务中心",
    dataIndex: "auditServiceCenterName",
    scopedSlots: { customRender: "auditServiceCenterName" },
    width: 120
  },
  {
    title: "所在运营中心",
    dataIndex: "auditOperationCenterName",
    scopedSlots: { customRender: "auditOperationCenterName" },
    width: 120
  },
  {
    title: "申请时间",
    dataIndex: "applicationTime",
    scopedSlots: { customRender: "applicationTime" },
    width: 100
  },
  {
    title: "准入审核",
    dataIndex: "processStatus",
    scopedSlots: { customRender: "processStatus" },
    width: 100
  },
  {
    title: "合同状态",
    dataIndex: "contractStatus",
    scopedSlots: { customRender: "contractStatus" },
    width: 100
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
    width: 100
  }
];
export default {
  components: { Breadcrumb },
  mixins: [pageDetailMixins],
  data() {
    return {
      fields: [
        {
          label: "机构名称",
          key: "corporationName",
          component: "j-input"
        },
        {
          label: "渠道类型",
          key: "distributorType",
          component: "j-select",
          options: [
            {
              label: "全部",
              value: ""
            },
            {
              label: "渠道终端",
              value: "endConsumerMarket"
            },
            {
              label: "服务中心",
              value: "serviceCenter"
            },
            {
              label: "运营中心",
              value: "operationCenter"
            },
            {
              label: "货栈",
              value: "warehouse"
            }
          ]
        },
        {
          label: "所在地区",
          key: "provinceCode,cityCode,areaCode",
          component: "j-cascader",
          fieldNames: {
            label: "areaName",
            value: "areaCode",
            children: "subAreas"
          },
          areaData: [],
          getAreaData: () => {
            return this.loadAreaData();
          }
        },
        {
          label: "所在服务中心",
          key: "auditServiceCenterId",
          component: "j-select",
          getOptions: () => {
            return this.loadServiceCenterList();
          }
        },
        {
          label: "所在运营中心",
          key: "auditOperationCenterId",
          component: "j-select",
          getOptions: () => {
            return this.loadOperationCenterList();
          }
        },
        {
          label: "申请时间",
          key: "applicationStartTime,applicationEndTime",
          component: "j-range-picker",
          format: "YYYY-MM-DD"
        },
        {
          label: "准入审核",
          key: "processStatus",
          defaultValue: "to_approval",
          component: "j-select",
          options: [
            {
              label: "全部",
              value: ""
            },
            {
              label: "待审核",
              value: "to_approval"
            },
            {
              label: "已通过",
              value: "passed"
            },
            {
              label: "未通过",
              value: "not_passed"
            },
            {
              label: "已取消",
              value: "cancel"
            }
          ]
        },
        {
          label: "合同状态",
          key: "contractStatus",
          component: "j-select",
          options: [
            {
              label: "全部",
              value: ""
            },
            {
              label: "待签署",
              value: "待签署"
            },
            {
              label: "已签署",
              value: "已签署"
            },
            {
              label: "已取消",
              value: "已取消"
            },
            {
              label: "已截止签署",
              value: "已截止签署"
            },
            {
              label: "已到期",
              value: "已到期"
            }
          ]
        }
      ],
      form: {
        processStatus: "to_approval"
      },
      tblData: [],
      tblColumns,
      pagination: {
        hideOnSinglePage: true
      },
      loading: false,
      areaData: [],
      dtFormat
    };
  },
  activated() {
    this.loadList();
  },
  mounted() {
    if (sessionStorage.getItem("searchForm")) {
      const searchForm = JSON.parse(sessionStorage.getItem("searchForm"));
      // this.form.setFieldsValue({ ...searchForm.form });
      this.pagination = searchForm.pagination;
    }
    this.loadList();
  },
  methods: {
    // 加载行政区数据
    async loadAreaData() {
      const resp = await loadArea();
      if (resp.data.code === "SUCCESS") {
        this.areaData = resp.data.data;
      } else {
        this.$message.error(resp.data.errorMsg);
      }
      return this.areaData;
    },
    // 获取服务中心
    async loadServiceCenterList() {
      const resp = await fetchSpecifyTypeCorporation("serviceCenter");
      let serviceCenterList = [];
      if (resp.data.code === "SUCCESS") {
        resp.data.data.forEach(item => {
          serviceCenterList.push({
            label: item.corporationName,
            value: item.corporationId
          });
        });
      } else {
        this.$message.error(resp.data.errorMsg);
      }
      return serviceCenterList;
    },
    // 获取运营中心
    async loadOperationCenterList() {
      const resp = await fetchSpecifyTypeCorporation("operationCenter");
      let operationCenterList = [];
      if (resp.data.code === "SUCCESS") {
        resp.data.data.forEach(item => {
          operationCenterList.push({
            label: item.corporationName,
            value: item.corporationId
          });
        });
      } else {
        this.$message.error(resp.data.errorMsg);
      }
      return operationCenterList;
    },
    // 加载列表
    loadList() {
      const params = {
        pageNum: this.pagination.current ? this.pagination.current : 1,
        pageSize: this.pagination.pageSize ? this.pagination.pageSize : 10,
        areaCode: this.form.areaCode
          ? this.form.areaCode
          : this.form.cityCode
          ? this.form.cityCode
          : this.form.provinceCode
          ? this.form.provinceCode
          : "",
        auditOperationCenterId: this.form.auditOperationCenterId
          ? this.form.auditOperationCenterId
          : "",
        auditServiceCenterId: this.form.auditServiceCenterId
          ? this.form.auditServiceCenterId
          : "",
        corporationName: this.form.corporationName
          ? this.form.corporationName
          : "",
        distributorType: this.form.distributorType
          ? this.form.distributorType
          : "",
        contractStatus: this.form.contractStatus
          ? this.form.contractStatus
          : "",
        applicationStartTime: this.form.applicationStartTime
          ? this.form.applicationStartTime + " 00:00:00"
          : "",
        applicationEndTime: this.form.applicationEndTime
          ? this.form.applicationEndTime + " 23:59:59"
          : "",
        processStatus: this.form.processStatus ? this.form.processStatus : ""
      };
      this.loading = true;
      corporationApplyList(params)
        .then(resp => {
          this.loading = false;
          if (resp.data.code === "SUCCESS") {
            const data = resp.data.data;
            this.tblData = data.records;
            const pagination = { ...this.pagination };
            pagination.current = data.pageNum;
            pagination.total = data.total;
            pagination.pageSize = data.pageSize;
            pagination.showTotal = function(total, range) {
              return `当前显示 ${range[0]} - ${range[1]} 条, 共 ${total} 条`;
            };
            this.pagination = pagination;
          } else {
            this.$message.error(resp.data.errorMsg);
          }
        })
        .catch(error => {
          if (error) this.loading = false;
        });
    },
    // 分页
    onPage(pagination) {
      this.pagination.current = pagination.current;
      this.loadList();
    },
    // 搜索
    search(form) {
      this.form = form;
      this.pagination.current = 1;
      this.loadList();
    },
    // 查看详情
    onDetail(record) {
      const applicationId = record.applicationId;
      this.$router.push({
        name: "ExamineDetail",
        params: { applicationId: applicationId, type: "detail" }
      });
    },
    // 协议取消
    onCancel(record) {
      var that = this;
      this.$confirm({
        title: "协议取消确认",
        content: "该协议还未签署生效，您确认取消合同吗？",
        okText: "确定",
        okType: "info",
        cancelText: "取消",
        onOk() {
          cancelAgreement(record.applicationId).then(resp => {
            if (resp.data.code === "SUCCESS") {
              that.$message.success("取消成功");
              that.loadList();
            }
          });
        }
      });
    },
    // 协议发起
    onInitiateAgreement(record) {
      const applicationId = record.applicationId;
      this.$router.push({
        name: "ModifyAgreement",
        params: { applicationId: applicationId, type: "modify" }
      });
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === "ExamineDetail") {
      const obj = {
        form: { ...this.form },
        pagination: this.pagination
      };
      sessionStorage.setItem("searchForm", JSON.stringify(obj));
    } else {
      sessionStorage.removeItem("searchForm");
    }
    next();
  }
};
</script>

<style scoped>
.content-container {
  min-height: 100vh;
  background-color: white;
  margin: 30px;
}

.icon__tip:hover {
  color: #1890ff;
}
</style>
